import PropTypes from 'prop-types';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Box,
  Heading,
  Text,
  Center,
  Flex,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';

function ResultModal({
  isOpen,
  isError,
  title,
  message,
  errorObject,
  onSubmit,
}) {
  let defaultTitle = isError ? 'Error' : 'Success';
  if (title) defaultTitle = title;
  let defaultMessage = isError
    ? 'We apologize for the inconvenience. It appears that something went wrong with your request. Kindly try again later. If the issue persists, please contact our support team for assistance. Thank you for your understanding.'
    : 'The operation was completed successfully.';
  if (message) defaultMessage = message;

  let parsedErrors = [];

  if (errorObject)
    Object.keys(errorObject).forEach((key) => {
      if (Array.isArray(errorObject[key])) {
        parsedErrors = parsedErrors.concat(errorObject[key]);
      }
    });

  return (
    <Modal size="md" isOpen={isOpen} onClose={() => onSubmit()} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box py={10} px={6}>
            {!isError ? (
              <Center>
                <CheckCircleIcon boxSize="50px" color="green.500" />
              </Center>
            ) : (
              <Center>
                <Flex
                  flexDirection="column"
                  justify="center"
                  alignItems="center"
                  bg="red.500"
                  rounded="50px"
                  w="55px"
                  h="55px"
                  textAlign="center"
                >
                  <CloseIcon boxSize="20px" color="white" />
                </Flex>
              </Center>
            )}

            <Center>
              <Heading as="h2" size="xl" mt={6} mb={2}>
                {defaultTitle}
              </Heading>
            </Center>

            <Center>
              <Text color="gray.500">{defaultMessage}</Text>
            </Center>

            {parsedErrors.length > 0 && isError && (
              <UnorderedList my={4}>
                {parsedErrors.map((error) => (
                  <ListItem key={error}>{error}</ListItem>
                ))}
              </UnorderedList>
            )}
          </Box>

          <Center>
            <Button
              colorScheme={isError ? 'red' : 'green'}
              size="lg"
              px={10}
              onClick={() => onSubmit()}
            >
              Procceed
            </Button>
          </Center>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}

ResultModal.defaultProps = {
  errorObject: null,
  title: null,
  message: null,
};

ResultModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorObject: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default ResultModal;

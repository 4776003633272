import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useToast, Box, Skeleton, Stack } from '@chakra-ui/react';

import { DndContext, closestCorners } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { ReducerStatus, CompanionMode } from 'utils/constants';
import {
  getSubQuestsByQuestId,
  getSubQuestById,
} from '../../store/admin-subquest-action';

import SubQuest from './components/SubQuest';
import useQuestOrderChange from './hooks/useQuestOrderChange';

import { adminSubQuestActions } from '../../store/admin-subquest-slice';

import { companionActions } from '../../../../../companion/store/companion-slice';

function GetAdminSubQuestsByQuestId({ questId }) {
  const dispatch = useDispatch();
  const toast = useToast();
  const { handleChangeOrderNo } = useQuestOrderChange(questId);

  const subQuests = useSelector(
    (state) =>
      state.adminSubQuest.questSubQuests.find((item) => item.id === questId)
        ?.subQuests
  );

  const status = useSelector((state) => state.adminSubQuest.getSubQuest.status);
  const getSubQuest = useSelector((state) => state.adminSubQuest.getSubQuest);
  const subQuest = useSelector((state) => state.adminSubQuest.subQuest);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await getSubQuestsByQuestId(questId);
      if (response) {
        dispatch(
          adminSubQuestActions.addSubQuest({
            id: questId,
            subQuests: response.map((item, index) => ({
              id: index + 1,
              ...item,
            })),
          })
        );
      }
      setIsLoading(false);
    };

    fetchData();
  }, [questId, dispatch]);

  useEffect(() => {
    if (getSubQuest.status === ReducerStatus.FAILED) {
      toast({
        title: getSubQuest.error.data.title || 'Internal Server Error',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }, [getSubQuest, toast]);

  useEffect(() => {
    if (getSubQuest.status === ReducerStatus.SUCCEEDED && subQuest) {
      const { id } = subQuest.type;
      if (id === 1) dispatch(adminSubQuestActions.toggleVideoUpdateModal(true));
      if (id === 4) dispatch(adminSubQuestActions.toggleQuizUpdateModal(true));
      if (id === 10)
        dispatch(adminSubQuestActions.toggleInteractiveVideoUpdateModal(true));
    }
  }, [getSubQuest, subQuest, dispatch]);

  const editSubQuest = (targetSubQuest) => {
    dispatch(adminSubQuestActions.setQuestId(questId));
    dispatch(getSubQuestById({ questId, subQuestId: targetSubQuest.id }));
  };

  const removeSubQuest = (id) => {
    dispatch(adminSubQuestActions.setQuestId(questId));
    dispatch(adminSubQuestActions.setSubQuestIdForRemoval(id));
    dispatch(adminSubQuestActions.toggleRemoveModal(true));
  };

  const generateQuiz = (targetSubQuest) => {
    dispatch(companionActions.setMode(CompanionMode.QUIZ_GENERATOR));
    dispatch(
      companionActions.setContext(targetSubQuest.subQuestVideo.transcript)
    );
    dispatch(companionActions.setConversations([]));
    dispatch(companionActions.setModal(true));
    dispatch(companionActions.setInitialPrompt(true));
  };
  return (
    <Box>
      {subQuests === 'undefined' || isLoading ? (
        <Stack>
          <Skeleton mb={6} height="72px" borderRadius="md" />
          <Skeleton mb={6} height="72px" borderRadius="md" />
        </Stack>
      ) : (
        <DndContext
          onDragEnd={handleChangeOrderNo}
          collisionDetection={closestCorners}
        >
          {subQuests && (
            <SortableContext
              items={subQuests}
              strategy={verticalListSortingStrategy}
            >
              {subQuests?.map((item) => (
                <SubQuest
                  id={item.id}
                  key={item.orderNo}
                  isEditable={item.isEditable}
                  orderNo={item.orderNo}
                  subQuest={item.subQuest}
                  status={status}
                  editSubQuest={() => editSubQuest(item.subQuest)}
                  removeSubQuest={() => removeSubQuest(item.subQuest.id)}
                  generateQuiz={() => generateQuiz(item.subQuest)}
                />
              ))}
            </SortableContext>
          )}
        </DndContext>
      )}
    </Box>
  );
}

GetAdminSubQuestsByQuestId.propTypes = {
  questId: PropTypes.number.isRequired,
};

export default GetAdminSubQuestsByQuestId;

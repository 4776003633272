import { arrayMove } from '@dnd-kit/sortable';

export const useLearningPlanOrderChange = (
  selectedLearningPlans,
  handleUpdateLearningPlanOrder
) => {
  const handleOnDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = selectedLearningPlans.findIndex(
        (plan) => plan.id === active.id
      );
      const newIndex = selectedLearningPlans.findIndex(
        (plan) => plan.id === over.id
      );

      const newOrder = arrayMove(selectedLearningPlans, oldIndex, newIndex);
      handleUpdateLearningPlanOrder(newOrder);
    }
  };

  return { handleOnDragEnd };
};

export default useLearningPlanOrderChange;

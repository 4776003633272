import * as Yup from 'yup';

import { useState } from 'react';

const useHandleForm = () => {
  const [groups, setGroups] = useState([]);

  const [inputTagsGroups, setInputTagsGroups] = useState({
    newGroup: '',
    isGroupError: false,
    errorMessage: '',
  });

  const learningplanFormValidation = Yup.object({
    name: Yup.string().trim().max(155).required('Required'),
    description: Yup.string().trim().max(1000).required('Required'),
  });
  const handleTagsGroupsChange = (identifier, value) => {
    setInputTagsGroups((prevValues) => ({
      ...prevValues,
      [identifier]: value,
    }));
  };

  const handleGroupRemove = (groupToRemove) => {
    setGroups((prevGroups) =>
      prevGroups.filter((group) => group !== groupToRemove)
    );
  };

  const handleGroupAdd = () => {
    const allowedDomains = ['trendmicro.com', 'dl.trendmicro.com'];
    const domainRegex = /@([a-zA-Z0-9.-]+)$/;
    const match = inputTagsGroups.newGroup.match(domainRegex);

    if (match && match[1] && allowedDomains.includes(match[1])) {
      setGroups((prevGroups) => [...prevGroups, inputTagsGroups.newGroup]);
      handleTagsGroupsChange('isGroupError', false);
      handleTagsGroupsChange('newGroup', '');
    } else {
      handleTagsGroupsChange('isGroupError', true);
      handleTagsGroupsChange(
        'errorMessage',
        'Email is not from an allowed domain. It must be one of the following: trendmicro.com, dl.trendmicro.com.'
      );
    }
  };

  const handleGroupKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleGroupAdd();
    } else {
      handleTagsGroupsChange('isGroupError', false);
    }
  };

  return {
    groups,
    inputTagsGroups,
    learningplanFormValidation,
    setGroups,
    handleTagsGroupsChange,
    handleGroupRemove,
    handleGroupAdd,
    handleGroupKeyDown,
  };
};

export default useHandleForm;

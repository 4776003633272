import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Text,
  Flex,
  Avatar,
  useColorModeValue,
  HStack,
  Button,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import { useMsal } from '@azure/msal-react';
import { CompanionMode } from 'utils/constants';
import { companionActions } from 'companion/store/companion-slice';
import { COURSE_SUGGESTION } from 'utils/constants/companionMode';

import ChatContent from './ChatContent';
import './ChatItem.css';

function ChatItem({ isFromUser, message, mode, timeSent }) {
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const { name } = accounts[0];

  const [parsedMessage, setParsedMessage] = useState('');
  const [hasAction, setHasAction] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setParsedMessage(message);

    if (!isFromUser && mode === CompanionMode.COURSE_OUTLINE) {
      const regex = /(\[.*\])/s;
      const match = message.match(regex);
      if (match) {
        try {
          const jsonValue = match[0];
          const jsonObject = JSON.parse(jsonValue);

          const startIndex = message.indexOf(jsonValue);
          const normalString = message.substring(0, startIndex).trim();

          // Remove the last two lines from the normal string
          const lines = normalString.split('\n');
          const updatedNormalString = lines.slice(0, -2).join('\n');

          setParsedMessage(updatedNormalString);
          setHasAction(true);
          setData(jsonObject);
        } catch {
          setHasAction(false);
        }
      }
    } else if (!isFromUser && mode === CompanionMode.QUIZ_GENERATOR) {
      const regex = /Confirm if you want to generate quiz./;
      const match = message.match(regex);
      if (match) {
        try {
          setHasAction(true);
        } catch {
          setHasAction(false);
        }
      }
    }
  }, [isFromUser, mode, parsedMessage, message]);

  const onClickInputOutline = () => {
    dispatch(companionActions.setOutlines(data));
    dispatch(companionActions.setModal(false));
  };

  const onClickGenerateQuiz = () => {
    dispatch(companionActions.setConvertQuizToJson(true));
  };

  const textColorIfUser = useColorModeValue('black', 'white');
  return (
    <Box>
      <ChatContent isFromUser={isFromUser}>
        {isFromUser ? (
          <Text
            mb={4}
            color={isFromUser ? textColorIfUser : 'white'}
            fontSize="sm"
          >
            {message}
          </Text>
        ) : (
          <Box className="response" color="white">
            <Markdown>{parsedMessage}</Markdown>

            {/* Action button for Course Outline */}
            {hasAction && mode === CompanionMode.COURSE_OUTLINE && (
              <Box>
                <Text>
                  Do you want me to assist you in terms of inputting that
                  outline to this course?
                </Text>
                <HStack p={4}>
                  <Button
                    w="100%"
                    colorScheme="white"
                    bg="white"
                    color="black"
                    onClick={() => onClickInputOutline()}
                  >
                    YES
                  </Button>
                  <Button w="100%" colorScheme="white" variant="outline">
                    NO
                  </Button>
                </HStack>
              </Box>
            )}
            {hasAction && mode === CompanionMode.QUIZ_GENERATOR && (
              <Box>
                <HStack p={4}>
                  <Button
                    w="100%"
                    colorScheme="white"
                    bg="white"
                    color="black"
                    onClick={() => onClickGenerateQuiz()}
                  >
                    Generate Quiz
                  </Button>
                </HStack>
              </Box>
            )}
          </Box>
        )}
      </ChatContent>

      <Flex
        align="start"
        mt={5}
        gap={2}
        direction={isFromUser ? 'row-reverse' : 'row'}
      >
        <Avatar
          p={2}
          ml={2}
          mb={2}
          size="md"
          name={isFromUser ? name.replace(/\s*\([^)]*\)\s*$/, '').trim() : ''}
          bg={isFromUser ? '#dee2e6' : '#d92e48'}
          src={isFromUser ? '' : require('assets/companion.png')}
        />
        <Text fontSize="xs">{timeSent}</Text>
      </Flex>
    </Box>
  );
}

ChatItem.defaultProps = {
  mode: COURSE_SUGGESTION,
  timeSent: '',
};

ChatItem.propTypes = {
  isFromUser: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  mode: PropTypes.string,
  timeSent: PropTypes.string,
};

export default ChatItem;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAdminGrowthStages = createAsyncThunk(
  'adminGrowthStages/fetchGrowthStages',
  async (params) => {
    const { query, currentPage, pageSize } = params;
    let apiRoute = `/admin/growth-stages?CurrentPage=${currentPage}&PageSize=${pageSize}`;
    if (query) apiRoute += `&Query=${query}`;

    try {
      const response = await axios.get(apiRoute);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const fetchAdminGrowthStageById = createAsyncThunk(
  'adminGrowthStages/fetchGrowthStageById',
  async (id) => {
    const response = await axios.get(`/admin/growth-stages/${id}`);
    return response.data;
  }
);

export const createAdminGrowthStage = createAsyncThunk(
  'adminGrowthStages/createGrowthStage',
  async (growthStageData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/admin/growth-stages',
        growthStageData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateAdminGrowthStageById = createAsyncThunk(
  'adminGrowthStages/updateGrowthStageById',
  async ({ id, updatedGrowthStageData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/admin/growth-stages/${id}`,
        updatedGrowthStageData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const deleteAdminGrowthStageById = createAsyncThunk(
  'adminGrowthStages/deleteGrowthStageById',
  async (id) => {
    const response = await axios.delete(`/admin/growth-stages/${id}`);
    return response.data;
  }
);

import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';
import { deleteAdminGrowthStageById } from '../../store/admin-growthstage-action';
import { adminGrowthStageActions } from '../../store/admin-growthstage-slice';

function DeleteAdminGrowthStageById() {
  const dispatch = useDispatch();

  const growthStageToDelete = useSelector(
    (state) => state.adminGrowthStage.growthStage
  );
  const deleteGrowthStage = useSelector(
    (state) => state.adminGrowthStage.deleteGrowthStage
  );

  const handleToggleDeleteModal = (isOpen) => {
    dispatch(adminGrowthStageActions.toggleDeleteModal(isOpen));
  };

  const handleDeleteGrowthStage = () => {
    dispatch(deleteAdminGrowthStageById(growthStageToDelete.id));
  };

  const handleResultButton = () => {
    dispatch(adminGrowthStageActions.resetDeleteStatus());
    if (deleteGrowthStage.status === ReducerStatus.SUCCEEDED) {
      handleToggleDeleteModal(false);
      dispatch(adminGrowthStageActions.resetTableRefresher());
    }
  };

  return (
    <Box>
      {deleteGrowthStage.status === ReducerStatus.SUCCEEDED ||
      deleteGrowthStage.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={deleteGrowthStage.modal}
          onSubmit={handleResultButton}
          isError={deleteGrowthStage.status === ReducerStatus.FAILED}
          title={
            deleteGrowthStage.status === ReducerStatus.SUCCEEDED
              ? 'Deleted'
              : 'Server Error'
          }
          message={
            deleteGrowthStage.status === ReducerStatus.SUCCEEDED
              ? 'The selected growth stage has been deleted successfully. If you believe this was done in error, please contact the administrator.'
              : null
          }
        />
      ) : (
        <Modal
          isOpen={deleteGrowthStage.modal}
          onClose={() => handleToggleDeleteModal(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation Required</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                Are you certain you want to delete this growth stage?
                <Text as="b">{growthStageToDelete?.name}</Text> Please note that
                all related learning plan and course materials will also be
                deleted.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="solid"
                colorScheme="blue"
                mr={3}
                onClick={() => handleToggleDeleteModal(false)}
              >
                Close
              </Button>
              <Button
                variant="outline"
                colorScheme="red"
                isLoading={deleteGrowthStage.status === ReducerStatus.LOADING}
                onClick={() => handleDeleteGrowthStage()}
              >
                Proceed
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default DeleteAdminGrowthStageById;

import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Flex,
  Button,
  Text,
  Image,
  Center,
} from '@chakra-ui/react';

import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons';

function ImageUploadInput({
  label,
  helperText,
  currentImage,
  file,
  fileURL,
  fileError,
  fileInputRef,
  handleRemoveSelectedFile,
  handleAttachFile,
  handleFileInputChange,
}) {
  const baseFileUrl = process.env.REACT_APP_FILE_URL;

  return (
    <FormControl isInvalid={fileError !== ''}>
      <FormLabel optionalIndicator>{label}</FormLabel>

      {!file.name && currentImage && (
        <Box my={4}>
          <Center>
            <Image
              borderRadius="10"
              width="80%"
              height="280px"
              objectFit="fit"
              fallbackSrc={require('assets/default-course.jpg')}
              src={file?.name ? fileURL : `${baseFileUrl}${currentImage}`}
              alt="Course current image"
            />
          </Center>

          <Center mt={2}>
            <Text fontSize="xs" fontWeight={800}>
              {file?.name ? 'NEW' : 'CURRENT'} {label.toUpperCase()}
            </Text>
          </Center>
        </Box>
      )}

      {file?.name && (
        <Box my={4}>
          <Center>
            <Image
              borderRadius="10"
              width="80%"
              height="280px"
              objectFit="fit"
              fallbackSrc={require('assets/default-course.jpg')}
              src={fileURL}
              alt="Selected File"
            />
          </Center>

          <Flex gap={2} my={2} justify="center" align="center">
            <Text flex={1}>Selected File: {file?.name}</Text>
            <Button
              type="button"
              colorScheme="teal"
              variant="ghost"
              onClick={handleRemoveSelectedFile}
            >
              <CloseIcon />
            </Button>
          </Flex>
        </Box>
      )}
      <Box
        as="button"
        type="button"
        borderRadius="md"
        bg="teal"
        w="100%"
        px={4}
        h={10}
        color="white"
        onClick={handleAttachFile}
      >
        <input
          type="file"
          hidden
          accept={'image/*'}
          ref={fileInputRef}
          onChange={handleFileInputChange}
        />
        <AttachmentIcon mx={2} />
        Attach file
      </Box>
      {fileError !== '' && <FormErrorMessage>{fileError}</FormErrorMessage>}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

ImageUploadInput.defaultProps = {
  currentImage: null,
  fileURL: null,
};

ImageUploadInput.propTypes = {
  currentImage: PropTypes.string,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  fileURL: PropTypes.string,
  fileError: PropTypes.string.isRequired,
  fileInputRef: PropTypes.object.isRequired,
  handleRemoveSelectedFile: PropTypes.func.isRequired,
  handleAttachFile: PropTypes.func.isRequired,
  handleFileInputChange: PropTypes.func.isRequired,
};

export default ImageUploadInput;

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';
import { deleteAdminLearningPlanById } from '../../store/admin-learningplan-action';
import { adminLearningPlanActions } from '../../store/admin-learningplan-slice';

function DeleteAdminLearningPlanById() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const learningPlanToDelete = useSelector(
    (state) => state.adminLearningPlan.learningplan
  );
  const learningPlanId = useSelector(
    (state) => state.adminLearningPlan.learningplan?.id
  );
  const deleteStatus = useSelector(
    (state) => state.adminLearningPlan.deleteLearningPlanStatus
  );
  const deleteModal = useSelector(
    (state) => state.adminLearningPlan.deleteModal
  );

  const handleToggleDeleteModal = (isOpen) => {
    dispatch(adminLearningPlanActions.toggleDeleteModal(isOpen));
  };

  const handleDeleteLearningPlan = () => {
    dispatch(deleteAdminLearningPlanById(learningPlanId));
  };

  const handleResultButton = () => {
    dispatch(adminLearningPlanActions.resetDeleteStatus());
    if (deleteStatus === ReducerStatus.SUCCEEDED) {
      handleToggleDeleteModal(false);
      dispatch(adminLearningPlanActions.resetDeleteRefresher());
      navigate('admin/learning-plans');
    }
  };

  return (
    <Box>
      {deleteStatus === ReducerStatus.SUCCEEDED ||
      deleteStatus === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={deleteModal}
          onSubmit={handleResultButton}
          isError={deleteStatus === ReducerStatus.FAILED}
          title={
            deleteStatus === ReducerStatus.SUCCEEDED
              ? 'Deleted'
              : 'Server Error'
          }
          message={
            deleteStatus === ReducerStatus.SUCCEEDED
              ? 'The selected learningplan has been deleted successfully. If you believe this was done in error, please contact the administrator.'
              : null
          }
        />
      ) : (
        <Modal
          isOpen={deleteModal}
          onClose={() => handleToggleDeleteModal(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation Required</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you certain you want to delete this learning plan? (
              {learningPlanToDelete?.name}) Please note that all related course
              materials will also be deleted.
            </ModalBody>

            <ModalFooter>
              <Button
                variant="solid"
                colorScheme="blue"
                mr={3}
                onClick={() => handleToggleDeleteModal(false)}
              >
                Close
              </Button>
              <Button
                variant="outline"
                colorScheme="red"
                isLoading={deleteStatus === ReducerStatus.LOADING}
                onClick={() => handleDeleteLearningPlan()}
              >
                Proceed
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default DeleteAdminLearningPlanById;

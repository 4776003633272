import PropTypes from 'prop-types';
import { Center, Box, Image } from '@chakra-ui/react';

function CourseImage({ name, image, category, ...rest }) {
  const baseFileUrl = process.env.REACT_APP_FILE_URL;

  let stockImage = null;

  if (category)
    switch (category.toLowerCase()) {
      case 'programming':
        stockImage = require('assets/course-thumbnails/skillquest-thumbs_ic-programming.png');
        break;
      case 'threats':
        stockImage = require('assets/course-thumbnails/skillquest-thumbs_ic-threats.png');
        break;
      case 'platform':
        stockImage = require('assets/course-thumbnails/skillquest-thumbs_ic-platform.png');
        break;
      case 'networking':
        stockImage = require('assets/course-thumbnails/skillquest-thumbs_ic-networking.png');
        break;
      case 'product':
        stockImage = require('assets/course-thumbnails/skillquest-thumbs_ic-product.png');
        break;
      case 'soft skills':
        stockImage = require('assets/course-thumbnails/skillquest-thumbs_ic-softskills.png');
        break;
      case 'essential':
        stockImage = require('assets/course-thumbnails/skillquest-thumbs_ic-essentials-07.png');
        break;
      default:
        stockImage = require('assets/course-thumbnails/skillquest-thumbs_ic-essentials-08.png');
        break;
    }

  return (
    <Image
      {...rest}
      fallback={
        <Box
          {...rest}
          pos="relative"
          w="100%"
          h="100%"
          bg="black"
          _before={{
            content: '""',
            bgImage: require('assets/default-course.jpg'),
            bgSize: 'cover',
            pos: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            opacity: 0.2,
          }}
        >
          <Center>
            <Image
              src={stockImage}
              boxSize="190px"
              alt={name}
              objectFit="cover"
            />
          </Center>
        </Box>
      }
      src={`${baseFileUrl}${image}`}
      alt={name}
      objectFit="cover"
    />
  );
}

CourseImage.defaultProps = {
  name: null,
  image: null,
  category: null,
};

CourseImage.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  category: PropTypes.string,
};

export default CourseImage;

import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';
import { removeAdminSubQuest } from '../../store/admin-subquest-action';
import { adminSubQuestActions } from '../../store/admin-subquest-slice';

function DeleteAdminSubQuestById() {
  const dispatch = useDispatch();

  const removeSubQuest = useSelector(
    (state) => state.adminSubQuest.removeSubQuest
  );

  const questId = useSelector((state) => state.adminSubQuest.questId);

  const handleToggleRemoveModal = (isOpen) => {
    dispatch(adminSubQuestActions.toggleRemoveModal(isOpen));
  };

  const handleRemoveSubQuest = () => {
    dispatch(
      removeAdminSubQuest({
        questId,
        subQuestId: removeSubQuest.subQuestId,
      })
    );
  };

  const handleResultButton = () => {
    dispatch(adminSubQuestActions.resetRemoveStatus());
    if (removeSubQuest.status === ReducerStatus.SUCCEEDED) {
      handleToggleRemoveModal(false);
    }
  };

  return (
    <Box>
      {removeSubQuest.status === ReducerStatus.SUCCEEDED ||
      removeSubQuest.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={removeSubQuest.modal}
          onSubmit={handleResultButton}
          isError={removeSubQuest.status === ReducerStatus.FAILED}
          title={
            removeSubQuest.status === ReducerStatus.SUCCEEDED
              ? 'Removed'
              : 'Server Error'
          }
          message={
            removeSubQuest.status === ReducerStatus.SUCCEEDED
              ? 'The selected sub-quest has been deleted successfully.'
              : null
          }
        />
      ) : (
        <Modal
          isOpen={removeSubQuest.modal}
          onClose={() => handleToggleRemoveModal(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation Required</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete this sub-quest? Please note that
              all related sub-quest materials, including those used by other
              quests, will also be deleted.
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => handleToggleRemoveModal(false)}
              >
                Close
              </Button>
              <Button
                variant="outline"
                colorScheme="red"
                isLoading={removeSubQuest.status === ReducerStatus.LOADING}
                onClick={() => handleRemoveSubQuest()}
              >
                Proceed
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default DeleteAdminSubQuestById;

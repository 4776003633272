import PropTypes from 'prop-types';
import { Text, Flex, IconButton } from '@chakra-ui/react';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

function TableNavigation({ metaData, onNext, onPrevious }) {
  return (
    <Flex justify="end" align="center">
      <Text mx={2} fontSize="xs">
        {metaData ? (
          <>
            {metaData.page}-{metaData.totalPages} of {metaData.totalItems}
          </>
        ) : (
          <>0</>
        )}
      </Text>
      {metaData?.hasPrevious && (
        <IconButton
          isRound
          mx={2}
          variant="solid"
          colorScheme="gray"
          aria-label="Previous"
          fontSize="20px"
          onClick={onPrevious}
          icon={<ChevronLeftIcon />}
        />
      )}
      {metaData?.hasNext && (
        <IconButton
          isRound
          mx={2}
          variant="solid"
          colorScheme="gray"
          aria-label="Next"
          fontSize="20px"
          onClick={onNext}
          icon={<ChevronRightIcon />}
        />
      )}
    </Flex>
  );
}

TableNavigation.defaultProps = {
  metaData: null,
};

TableNavigation.propTypes = {
  metaData: PropTypes.object,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};

export default TableNavigation;

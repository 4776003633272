import {
  Box,
  Center,
  Flex,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

import axios from 'axios';
import Breadcrumbs from 'components/common/Breadcrumbs';
import QuestItem from 'components/quest/QuestItem';
import VideoSubQuest from 'components/subQuest/VideoSubQuest';

import 'components/common/styles/Scrollbar.css';

import { useDispatch, useSelector } from 'react-redux';

import { fetchProgressionByCourseIdAction } from 'store/modules/progression/progression-actions';
import { ReducerStatus } from 'utils/constants';
import InteractiveVideoSubQuest from 'components/subQuest/InteractiveVideoSubQuest';

function SubQuestPage() {
  const { id, questId, subQuestId } = useParams();
  const toast = useToast();
  const toastIdRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [quests, setQuests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState(null);
  const [subQuest, setSubQuest] = useState(null);
  const [code, setCode] = useState(null);

  const courseOutlines = useSelector(
    (state) => state.progression.courseOutlines
  );

  const postProgressionStatus = useSelector(
    (state) => state.progression.postProgressionStatus
  );

  useEffect(() => {
    setIsLoading(true);
    const fetchVideoContent = async () => {
      try {
        const getCourse = await axios.get(`/courses/${id}`);
        setCourse(getCourse.data);

        const getQuests = await axios.get(`/courses/${id}/quests`);
        setQuests(getQuests.data);

        const getSubQuest = await axios.get(
          `/courses/${id}/quests/${questId}/sub-quests/${subQuestId}`
        );

        const getCode = await axios.post(`/progressions/`, {
          courseId: id,
          questId,
          subQuestId,
        });

        setCode(getCode.data);

        setSubQuest(getSubQuest.data);

        if (courseOutlines.length === 0)
          dispatch(fetchProgressionByCourseIdAction(id));
      } catch {
        navigate('/404');
      }

      setIsLoading(false);
    };

    fetchVideoContent();
  }, [id, questId, subQuestId, courseOutlines, dispatch, navigate]);

  useEffect(() => {
    if (postProgressionStatus === ReducerStatus.LOADING) {
      toastIdRef.current = toast({
        title: 'Progress Saving',
        description: 'Your progress is saving, please wait',
        status: 'loading',
        position: 'top-right',
      });
    } else if (postProgressionStatus === ReducerStatus.SUCCEEDED) {
      toast.update(toastIdRef.current, {
        title: 'Video Completed.',
        description:
          'You have completed this sub-quest activity. You may now proceed to take the next sub-quest, if applicable.',
        status: 'success',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    } else if (postProgressionStatus === ReducerStatus.FAILED) {
      toast.update(toastIdRef.current, {
        title: 'Error.',
        description: 'Something went wrong with the request.',
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [postProgressionStatus, toast]);

  return (
    <Flex minH="100vh" ml={{ base: 0, md: 20 }}>
      <Box flex="1" ml={2} p="5">
        <SkeletonText mb={4} width="450px" noOfLines={1} isLoaded={!isLoading}>
          <Breadcrumbs
            mb={4}
            links={[
              { name: 'Home', path: '/' },
              { name: course?.name, path: `/courses/${id}` },
              {
                name: subQuest?.name,
                path: `/courses/${id}/quests/${questId}/sub-quests/${subQuestId}`,
              },
            ]}
          />
        </SkeletonText>

        <Flex direction={{ base: 'column', lg: 'row' }} gap={3}>
          <Box flex="1" h="100%">
            <Skeleton w="100%" h="100%" isLoaded={!isLoading}>
              {subQuest?.type?.id === 1 && (
                <VideoSubQuest
                  h="100%"
                  course={course}
                  quest={quests.find(
                    (quest) => quest.id === parseInt(questId, 10)
                  )}
                  subQuest={subQuest}
                  code={code}
                />
              )}

              {subQuest && subQuest?.type?.id === 10 && (
                <InteractiveVideoSubQuest
                  h="100%"
                  course={course}
                  quest={quests.find(
                    (quest) => quest.id === parseInt(questId, 10)
                  )}
                  subQuest={subQuest}
                  code={code}
                />
              )}
            </Skeleton>

            <Box display={{ base: 'block', lg: 'none' }}>
              <SkeletonText noOfLines={2} isLoaded={!isLoading}>
                <Text mt={4} fontSize="lg" fontWeight="bold">
                  {subQuest?.name}
                </Text>
                <Text mb={8}>{subQuest?.objective}</Text>
              </SkeletonText>
            </Box>
          </Box>

          <Box w={{ base: '100%', lg: '350px' }}>
            <Box
              className={useColorModeValue('scrollbar-light', 'scrollbar-dark')}
              overflowY="scroll"
              h="100%"
              px={4}
              borderColor={useColorModeValue('grey.800', 'teal')}
              borderWidth="1px"
              borderRadius="md"
            >
              <Center>
                <Text my={4} fontSize="xs" fontWeight="extrabold">
                  COURSE DIRECTORY
                </Text>
              </Center>

              {isLoading ? (
                <Stack>
                  <Skeleton mb={2} height="72px" borderRadius="md" />
                  <Skeleton mb={2} height="72px" borderRadius="md" />
                  <Skeleton mb={2} height="72px" borderRadius="md" />
                  <Skeleton mb={2} height="72px" borderRadius="md" />
                </Stack>
              ) : (
                <Box h="250px">
                  {quests.map((quest) => (
                    <QuestItem
                      key={quest.id}
                      courseId={parseInt(id, 10)}
                      quest={quest}
                      isMinimalMode
                      activeQuestId={parseInt(questId, 10)}
                      activeSubQuestId={parseInt(subQuestId, 10)}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Flex>

        <Box hideBelow="md">
          <SkeletonText noOfLines={2} isLoaded={!isLoading}>
            <Text mt={4} fontSize="lg" fontWeight="bold">
              {subQuest?.name}
            </Text>
            <Text>{subQuest?.objective}</Text>
          </SkeletonText>
        </Box>
      </Box>
    </Flex>
  );
}

export default SubQuestPage;

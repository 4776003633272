import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Input,
  Textarea,
} from '@chakra-ui/react';

import { updateCategoryAction } from 'store/modules/category/category-actions';
import { categoryActions } from 'store/modules/category/category-slice';

import { ReducerStatus } from 'utils/constants';

import ResultModal from 'components/common/modals/ResultModal';
import FormikFormControl from 'components/common/forms/FormikFormControl';

function UpdateCategoryMd() {
  const dispatch = useDispatch();

  const updateCategory = useSelector((state) => state.category.updateCategory);
  const selectedCategory = useSelector((state) => state.category.category);

  const categoryFormValidation = Yup.object().shape({
    name: Yup.string().max(155).required('Required'),
    description: Yup.string().max(1000).required('Required'),
  });

  const [updateFormInputs, setUpdateFormInputs] = useState({
    name: '',
    description: '',
  });

  const resetForm = () => {
    setUpdateFormInputs({
      name: '',
      description: '',
    });
  };

  const handleToggleUpdateModal = (isOpen) => {
    dispatch(categoryActions.toggleUpdateModal(isOpen));
  };

  const handleResultButton = () => {
    dispatch(categoryActions.resetUpdateStatus());
    if (updateCategory.status === ReducerStatus.SUCCEEDED) {
      resetForm();
      handleToggleUpdateModal(false);
    }
  };

  const handleUpdateCategory = (formikObject) => {
    dispatch(
      updateCategoryAction({
        id: selectedCategory.id,
        formData: formikObject,
      })
    );
  };

  useEffect(() => {
    if (updateCategory.modal) {
      setUpdateFormInputs({
        name: selectedCategory?.name || '',
        description: selectedCategory?.description || '',
      });
    } else {
      dispatch(categoryActions.resetUpdateStatus());
    }
  }, [updateCategory.modal, selectedCategory, dispatch]);

  return (
    <Box>
      {updateCategory.status === ReducerStatus.SUCCEEDED ||
      updateCategory.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={updateCategory.modal}
          isError={updateCategory.status === ReducerStatus.FAILED}
          onSubmit={handleResultButton}
          title={
            updateCategory.status === ReducerStatus.SUCCEEDED
              ? 'Category Updated'
              : null
          }
          message={
            updateCategory.status === ReducerStatus.SUCCEEDED
              ? 'The category has been updated successfully.'
              : updateCategory.error?.data?.title || 'Server Error'
          }
          errorObject={updateCategory.error?.data?.errors}
        />
      ) : (
        <Modal
          size="lg"
          isOpen={updateCategory.modal}
          onClose={() => handleToggleUpdateModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Category</ModalHeader>
            <ModalCloseButton />
            <Formik
              enableReinitialize
              initialValues={updateFormInputs}
              onSubmit={(values) => {
                handleUpdateCategory(values);
              }}
              validationSchema={categoryFormValidation}
            >
              {() => (
                <Form>
                  <ModalBody my={3}>
                    <VStack spacing={6} align="stretch">
                      <Field flex={1} name="name">
                        {({ field, form }) => (
                          <FormikFormControl
                            label="Name"
                            helperText="Enter the name of the category. Make it concise and descriptive."
                            errors={form.errors.name}
                            form={form}
                            touched={form.touched.name}
                          >
                            <Input {...field} />
                          </FormikFormControl>
                        )}
                      </Field>

                      <Field name="description">
                        {({ field, form }) => (
                          <FormikFormControl
                            label="Description"
                            helperText="Provide a detailed description of the category. Mention the key objectives and what users can expect."
                            errors={form.errors.description}
                            touched={form.touched.description}
                          >
                            <Textarea {...field} />
                          </FormikFormControl>
                        )}
                      </Field>
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      variant="outline"
                      colorScheme="blue"
                      mr={3}
                      onClick={() => handleToggleUpdateModal(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="red"
                      px={10}
                      type="submit"
                      isLoading={
                        updateCategory.status === ReducerStatus.LOADING
                      }
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default UpdateCategoryMd;

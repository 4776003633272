import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useColorModeValue,
  Text,
  Box,
  IconButton,
  Flex,
  Collapse,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';

import {
  DragHandleIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CheckCircleIcon,
  EditIcon,
  DeleteIcon,
} from '@chakra-ui/icons';

import { RiDraftFill } from 'react-icons/ri';
import { HiDotsVertical } from 'react-icons/hi';
import { GrDetach } from 'react-icons/gr';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { questActions } from 'store/modules/quest/quest-slice';
import { fetchQuestByIdAction } from 'store/modules/quest/quest-actions';

import GetAdminSubQuestsByQuestId from 'modules/admin/subQuest/features/getAdminSubQuestsByQuestId';
import AddSubQuestMd from 'modules/admin/subQuest/components/AddSubQuestMd';
import { ReducerStatus } from 'utils/constants';

function QuestAdminItem({ courseId, id, courseQuest }) {
  const dispatch = useDispatch();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const [isToggled, setIsToggled] = useState(true);

  const getQuestStatus = useSelector((state) => state.quest.getQuest.status);

  const editQuest = (editId) => {
    dispatch(questActions.setQuestEditMode(true));
    dispatch(fetchQuestByIdAction(editId));
  };

  const deleteQuest = (deleteId) => {
    dispatch(questActions.setQuestIdForDeletion(deleteId));
    dispatch(questActions.toggleDeleteModal(true));
  };

  const removeQuest = (questId) => {
    dispatch(
      questActions.setQuestIdForRemoval({
        courseId,
        questId,
      })
    );
    dispatch(questActions.toggleRemoveModal(true));
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Box mb={6} borderWidth="1px" borderRadius="md">
        <Box w="100%" p={4} bg={useColorModeValue('#f7f7f7', 'gray.800')}>
          <Flex align="center">
            <IconButton
              {...attributes}
              {...listeners}
              variant="plain"
              color="teal"
              aria-label="Re-order quest"
              icon={<DragHandleIcon />}
            />

            {isToggled ? (
              <IconButton
                onClick={() => setIsToggled(false)}
                variant="plain"
                aria-label="Untoggle quest"
                icon={<ArrowUpIcon />}
              />
            ) : (
              <IconButton
                onClick={() => setIsToggled(true)}
                variant="plain"
                aria-label="Toggle quest"
                icon={<ArrowDownIcon />}
              />
            )}

            <Text fontWeight={500} mx={2} fontSize="md" flex={1}>
              {courseQuest.quest.name}
            </Text>

            {courseQuest.quest.isPublished ? (
              <CheckCircleIcon color="green" />
            ) : (
              <RiDraftFill color="purple" />
            )}

            <AddSubQuestMd
              isEditable={courseQuest.isEditable}
              quest={courseQuest.quest}
            />

            <Menu>
              <MenuButton
                isLoading={getQuestStatus === ReducerStatus.LOADING}
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                variant="text"
              />
              <MenuList>
                <MenuItem
                  display={courseQuest.isEditable ? 'block' : 'none'}
                  icon={<EditIcon />}
                  onClick={() => {
                    editQuest(courseQuest.quest.id);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  icon={<GrDetach />}
                  onClick={() => {
                    removeQuest(courseQuest.quest.id);
                  }}
                >
                  Detach
                </MenuItem>
                <MenuItem
                  display={courseQuest.isEditable ? 'block' : 'none'}
                  icon={<DeleteIcon />}
                  onClick={() => {
                    deleteQuest(courseQuest.quest.id);
                  }}
                >
                  Remove
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>

        <Collapse in={isToggled}>
          <Box p={4}>
            <Text mb={5}>{courseQuest.quest.description}</Text>

            <GetAdminSubQuestsByQuestId questId={courseQuest.quest.id} />
          </Box>
        </Collapse>
      </Box>
    </div>
  );
}

QuestAdminItem.propTypes = {
  courseId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  courseQuest: PropTypes.object.isRequired,
};

export default QuestAdminItem;

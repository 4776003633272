import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Field, Form, Formik } from 'formik';

import {
  Box,
  Button,
  VStack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';
import NameInput from './components/NameInput';
import DescriptionInput from './components/DescriptionInput';
import GroupsInput from './components/GroupsInput';
import PublishInput from './components/PublishInput';

import useHandleForm from './hooks/useHandleForm';

import { updateAdminLearningPlan } from '../../store/admin-learningplan-action';
import { adminLearningPlanActions } from '../../store/admin-learningplan-slice';

function UpdateAdminLearningPlanById() {
  const dispatch = useDispatch();

  const {
    groups,
    inputTagsGroups,
    learningplanFormValidation,
    setGroups,
    handleTagsGroupsChange,
    handleGroupRemove,
    handleGroupAdd,
    handleGroupKeyDown,
  } = useHandleForm();

  const [updateFormInputs, setUpdateFormInputs] = useState({
    name: '',
    description: '',
  });

  const [isPublished, setIsPublished] = useState('false');

  const updateLearningPlan = useSelector(
    (state) => state.adminLearningPlan.updateLearningPlan
  );
  const learningplan = useSelector(
    (state) => state.adminLearningPlan.learningplan
  );

  const handleToggleUpdateModal = (isOpen) => {
    dispatch(adminLearningPlanActions.toggleUpdateModal(isOpen));
  };

  const resetForm = () => {
    setUpdateFormInputs({
      name: '',
      description: '',
    });

    setGroups([]);
  };

  const handleResultButton = () => {
    dispatch(adminLearningPlanActions.resetUpdateStatus());
    if (updateLearningPlan.status === ReducerStatus.SUCCEEDED) {
      resetForm();
      handleToggleUpdateModal(false);
    }
  };

  const handlePublishStatusChange = (e) => {
    setIsPublished(e);
  };

  const handleUpdateLearningPlan = (formikObject) => {
    const updatedLearningPlanData = {
      ...formikObject,
      OwnerGroups: groups,
      IsPublished: isPublished === 'true',
    };

    dispatch(
      updateAdminLearningPlan({
        id: learningplan?.id,
        updateLearningPlanData: updatedLearningPlanData,
        resetForm,
      })
    );
  };

  useEffect(() => {
    const resetUpdateForm = () => {
      setUpdateFormInputs({
        name: '',
        description: '',
      });

      setGroups([]);
    };

    if (updateLearningPlan.modal && learningplan) {
      resetUpdateForm();

      setUpdateFormInputs({
        name: learningplan?.name,
        description: learningplan?.description,
      });

      setIsPublished(learningplan?.isPublished.toString());

      const parsedGroups = JSON.parse(learningplan?.ownerGroups);
      if (
        Array.isArray(parsedGroups) &&
        parsedGroups.every((group) => typeof group === 'string')
      ) {
        setGroups(parsedGroups);
      }
    }
  }, [updateLearningPlan.modal, learningplan, setGroups, dispatch]);

  return (
    <Box>
      {updateLearningPlan.status === ReducerStatus.SUCCEEDED ||
      updateLearningPlan.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={updateLearningPlan.modal}
          onSubmit={handleResultButton}
          isError={updateLearningPlan.status === ReducerStatus.FAILED}
          title={
            updateLearningPlan.status === ReducerStatus.SUCCEEDED
              ? 'Learning Plan Updated'
              : null
          }
          message={
            updateLearningPlan.status === ReducerStatus.SUCCEEDED
              ? 'The learningplan has been updated successfully.'
              : updateLearningPlan.error?.data?.title || 'Server Error'
          }
          errorObject={updateLearningPlan.error?.data?.errors}
        />
      ) : (
        <Modal
          size="2xl"
          isOpen={updateLearningPlan.modal}
          onClose={() => handleToggleUpdateModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Learning Plan</ModalHeader>
            <ModalCloseButton />
            <Formik
              enableReinitialize
              initialValues={updateFormInputs}
              onSubmit={(values) => {
                handleUpdateLearningPlan(values);
              }}
              validationSchema={learningplanFormValidation}
            >
              {() => (
                <Form>
                  <ModalBody my={3}>
                    <VStack spacing={6} align="stretch">
                      <Flex
                        gap={5}
                        direction={{
                          base: 'column',
                          md: 'row',
                        }}
                      >
                        <Field flex={1} name="name">
                          {({ field, form }) => (
                            <NameInput field={field} form={form} />
                          )}
                        </Field>
                      </Flex>

                      <Field name="description">
                        {({ field, form }) => (
                          <DescriptionInput field={field} form={form} />
                        )}
                      </Field>

                      <GroupsInput
                        newGroup={inputTagsGroups.newGroup}
                        groups={groups}
                        isGroupError={inputTagsGroups.isGroupError}
                        errorMessage={inputTagsGroups.errorMessage}
                        onGroupChange={handleTagsGroupsChange}
                        onGroupAdd={handleGroupAdd}
                        onGroupKeyDown={handleGroupKeyDown}
                        onGroupRemove={handleGroupRemove}
                      />

                      <PublishInput
                        isPublished={isPublished}
                        handleChange={handlePublishStatusChange}
                      />
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      variant="outline"
                      colorScheme="blue"
                      mr={3}
                      onClick={() => handleToggleUpdateModal(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="red"
                      px={10}
                      type="submit"
                      isLoading={
                        updateLearningPlan.status === ReducerStatus.LOADING
                      }
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default UpdateAdminLearningPlanById;

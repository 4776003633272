import { useDispatch, useSelector } from 'react-redux';
import { arrayMove } from '@dnd-kit/sortable';

import { updateAdminSubQuestOrderNo } from 'modules/admin/subQuest/store/admin-subquest-action';
import { adminSubQuestActions } from 'modules/admin/subQuest/store/admin-subquest-slice';
// id - SubQuest ID
const useQuestOrderChange = (questId) => {
  const dispatch = useDispatch();

  const subQuests = useSelector(
    (state) =>
      state.adminSubQuest.questSubQuests.find((item) => item.id === questId)
        ?.subQuests
  );

  const getCourseQuestById = (id) =>
    subQuests.findIndex((subQuest) => subQuest.id === id);

  const handleChangeOrderNo = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    const originalPos = getCourseQuestById(active.id);
    const newPos = getCourseQuestById(over.id);

    dispatch(
      updateAdminSubQuestOrderNo({
        questId,
        subQuestId: subQuests.find((subQuest) => subQuest.id === active.id)
          .subQuest.id,
        newOrderNo: newPos + 1,
      })
    );

    dispatch(
      adminSubQuestActions.arrangeOrderNo({
        questId,
        subQuests: arrayMove(subQuests, originalPos, newPos),
      })
    );
  };

  return { handleChangeOrderNo };
};

export default useQuestOrderChange;

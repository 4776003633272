import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import {
  useColorModeValue,
  Text,
  Box,
  IconButton,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { EditIcon, CloseIcon, DragHandleIcon } from '@chakra-ui/icons';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ReducerStatus } from 'utils/constants';

function GrowthStageLearningPlan({
  id,
  stageNum,
  growthStageLearningPlan,
  onEdit,
  onDelete,
}) {
  const learningPlanStatus = useSelector(
    (state) => state.adminLearningPlan.getLearningPlanStatus
  );
  const updateLearningPlan = useSelector(
    (state) => state.adminLearningPlan.updateLearningPlan
  );

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = { transition, transform: CSS.Transform.toString(transform) };

  const handleOpenUpdateLearningPlanModal = () => {
    onEdit(id);
  };

  const handleRemoveAttachedLearningPlan = () => {
    onDelete(id);
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Box mb={3} borderWidth="1px" borderRadius="md">
        <Box w="100%" p={2} bg={useColorModeValue('#f7f7f7', 'gray.800')}>
          <Flex align="center">
            <IconButton
              {...attributes}
              {...listeners}
              variant="plain"
              color="teal"
              aria-label="Re-order learning plans"
              icon={<DragHandleIcon />}
            />
            <Text>Stage {stageNum} </Text>
            <Text fontWeight={500} mx={2} fontSize="md" flex={1}>
              {growthStageLearningPlan?.name}
            </Text>
            <HStack spacing={2}>
              <IconButton
                variant="outline"
                colorScheme="teal"
                aria-label="Edit"
                fontSize="sm"
                icon={<EditIcon />}
                isLoading={
                  learningPlanStatus === ReducerStatus.LOADING &&
                  updateLearningPlan.modal === true
                }
                onClick={() => handleOpenUpdateLearningPlanModal()}
              />
              <IconButton
                variant="outline"
                colorScheme="red"
                aria-label="Remove"
                fontSize="sm"
                icon={<CloseIcon />}
                isLoading={
                  learningPlanStatus === ReducerStatus.LOADING &&
                  updateLearningPlan.modal === true
                }
                onClick={handleRemoveAttachedLearningPlan}
              />
            </HStack>
          </Flex>
        </Box>
      </Box>
    </div>
  );
}

GrowthStageLearningPlan.propTypes = {
  id: PropTypes.number.isRequired,
  stageNum: PropTypes.number.isRequired,
  growthStageLearningPlan: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default GrowthStageLearningPlan;

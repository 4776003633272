import PropTypes from 'prop-types';
import { IconButton, Flex, useColorModeValue } from '@chakra-ui/react';

import { FiMenu } from 'react-icons/fi';

import HeaderSearch from '../header/HeaderSearch';

function MobileNav({ onOpen, ...rest }) {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="55px"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        mr={3}
        icon={<FiMenu />}
      />

      <HeaderSearch />
    </Flex>
  );
}

MobileNav.propTypes = {
  onOpen: PropTypes.func.isRequired,
};

export default MobileNav;

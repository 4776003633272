import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';
import { removeAdminLearningPlanCourseById } from '../../store/admin-learningplan-course-action';
import { adminLearningPlanCourseActions } from '../../store/admin-learningplan-course-slice';

function RemoveAdminLearningPlanCourseById() {
  const dispatch = useDispatch();

  const removeCourse = useSelector(
    (state) => state.adminLearningPlanCourse.removeCourse
  );

  const handleToggleRemoveModal = (IsOpen) => {
    dispatch(adminLearningPlanCourseActions.toggleRemoveModal(IsOpen));
  };

  const handleRemoveCourse = () => {
    dispatch(
      removeAdminLearningPlanCourseById({
        id: removeCourse.learningPlanId,
        courseId: removeCourse.courseId,
      })
    );
  };

  const handleResultButton = () => {
    dispatch(adminLearningPlanCourseActions.resetRemoveStatus());
    if (removeCourse.status === ReducerStatus.SUCCEEDED) {
      handleToggleRemoveModal(false);
    }
  };

  return (
    <Box>
      {removeCourse.status === ReducerStatus.SUCCEEDED ||
      removeCourse.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={removeCourse.modal}
          onSubmit={handleResultButton}
          isError={removeCourse.status === ReducerStatus.FAILED}
          title={
            removeCourse.status === ReducerStatus.SUCCEEDED
              ? 'Removed'
              : 'Server Error'
          }
          message={
            removeCourse.status === ReducerStatus.SUCCEEDED
              ? 'The selected course has been removed from the learning plan successfully.'
              : null
          }
        />
      ) : (
        <Modal
          isOpen={removeCourse.modal}
          onClose={() => handleToggleRemoveModal(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation Required</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to remove this course from this learning
              plan? This action will detach the course from the learning plan
              but will not delete the course itself.
            </ModalBody>

            <ModalFooter>
              <Button
                variant="solid"
                colorScheme="blue"
                mr={3}
                onClick={() => handleToggleRemoveModal(false)}
              >
                Close
              </Button>
              <Button
                variant="outline"
                colorScheme="red"
                isLoading={removeCourse.status === ReducerStatus.LOADING}
                onClick={() => handleRemoveCourse()}
              >
                Proceed
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default RemoveAdminLearningPlanCourseById;

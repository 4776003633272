import { Stack, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function ChatContent({ children, isFromUser }) {
  return (
    <Stack
      bg={useColorModeValue(
        isFromUser ? '#c5ccd3' : '#820014',
        isFromUser ? 'gray.600' : 'gray.800'
      )}
      boxShadow="lg"
      minW="250px"
      ml={4}
      p={4}
      rounded="xl"
      pos="relative"
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: isFromUser ? 30 : 16,
        borderRight: 'solid transparent',
        borderRightWidth: isFromUser ? 16 : 30,
        borderTop: 'solid',
        borderTopWidth: 40,
        borderTopColor: useColorModeValue(
          isFromUser ? '#c5ccd3' : '#820014',
          isFromUser ? 'gray.600' : 'gray.800'
        ),
        pos: 'absolute',
        bottom: '-40px',
        left: isFromUser ? '90%' : '10%',
        transform: 'translatex(-50%)',
      }}
    >
      {children}
    </Stack>
  );
}

ChatContent.propTypes = {
  children: PropTypes.node.isRequired,
  isFromUser: PropTypes.bool.isRequired,
};

export default ChatContent;

import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  VStack,
} from '@chakra-ui/react';

import { deleteCategoryByIdAction } from 'store/modules/category/category-actions';
import { categoryActions } from 'store/modules/category/category-slice';

import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';

function DeleteCategoryMd() {
  const dispatch = useDispatch();

  const selectedCategory = useSelector((state) => state.category.category);

  const deleteCategory = useSelector((state) => state.category.deleteCategory);

  const handleToggleDeleteModal = (isOpen) => {
    dispatch(categoryActions.toggleDeleteModal(isOpen));
  };

  const handleResultButton = () => {
    if (deleteCategory.status === ReducerStatus.SUCCEEDED) {
      handleToggleDeleteModal(false);
    }
    dispatch(categoryActions.resetDeleteStatus());
  };

  const handleDeleteCategory = () => {
    dispatch(deleteCategoryByIdAction(selectedCategory.id));
  };

  return (
    <Box>
      {deleteCategory.status === ReducerStatus.SUCCEEDED ||
      deleteCategory.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={deleteCategory.modal}
          isError={deleteCategory.status === ReducerStatus.FAILED}
          title={
            deleteCategory.status === ReducerStatus.SUCCEEDED
              ? 'Category Deleted'
              : null
          }
          message={
            deleteCategory.status === ReducerStatus.SUCCEEDED
              ? 'The category has been deleted successfully.'
              : deleteCategory.error
          }
          errorMessage={deleteCategory.error}
          onSubmit={handleResultButton}
        />
      ) : (
        <Modal
          size="md"
          isOpen={deleteCategory.modal}
          onClose={() => handleToggleDeleteModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Delete Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody my={3}>
              <VStack spacing={6} align="stretch">
                <p style={{ fontWeight: 'bold' }}>
                  Are you certain you want to delete this category?
                </p>
                <p>
                  This will delete the category permanently. You cannot undo
                  this action.
                </p>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outline"
                colorScheme="blue"
                mr={3}
                onClick={() => handleToggleDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                px={10}
                onClick={handleDeleteCategory}
                isLoading={deleteCategory.status === ReducerStatus.LOADING}
              >
                Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default DeleteCategoryMd;

import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';

import {
  useDisclosure,
  Box,
  Flex,
  VStack,
  Divider,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Progress,
} from '@chakra-ui/react';

import { MdVideoLibrary } from 'react-icons/md';

import { ReducerStatus } from 'utils/constants';

import ResultModal from 'components/common/modals/ResultModal';
import { createAdminVideoSubQuest } from '../../store/admin-subquest-action';
import { adminSubQuestActions } from '../../store/admin-subquest-slice';

import NameInput from './components/NameInput';
import CodeInput from './components/CodeInput';
import ObjectiveInput from './components/ObjectiveInput';
import VideoFileInput from './components/VideoFileInput';
import VideoNameInput from './components/VideoNameInput';
import DescriptionInput from './components/DescriptionInput';

import useVideoFileInput from './hooks/useVideoFileInput';
import useHandleForm from './hooks/useHandleForm';
import TagsInput from './components/TagsInput';
import GroupsInput from './components/GroupsInput';
import PublishInput from './components/PublishInput';
import TranscriptInput from './components/TranscriptInput';

function CreateAdminVideoSubQuest({ quest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const toast = useToast();
  const toastIdRef = useRef();

  const {
    video,
    videoURL,
    videoError,
    videoInputRef,
    handleAttachVideo,
    handleRemoveSelectedVideo,
    handleVideoInputChange,
  } = useVideoFileInput();

  const {
    tags,
    groups,
    inputTagsGroups,
    courseFormValidation,
    setTags,
    setGroups,
    handleTagsGroupsChange,
    handleTagRemove,
    handleGroupRemove,
    handleGroupAdd,
    handleTagAdd,
    handleTagKeyDown,
    handleGroupKeyDown,
  } = useHandleForm();

  const [createFormInputs, setCreateFormInputs] = useState({
    name: '',
    videoName: '',
    description: '',
    transcript: '',
    objective: '',
    code: '',
  });

  const [isPublished, setIsPublished] = useState('false');

  const createVideo = useSelector((state) => state.adminSubQuest.createVideo);
  const uploadProgress = useSelector(
    (state) => state.adminSubQuest.uploadProgress
  );

  const handleResult = () => {
    if (createVideo.status === ReducerStatus.SUCCEEDED) {
      setCreateFormInputs({
        name: '',
        videoName: '',
        description: '',
        transcript: '',
        objective: '',
        code: '',
      });

      handleRemoveSelectedVideo();
      setTags([]);
      onClose();
    }
    dispatch(adminSubQuestActions.resetCreateVideo());
  };

  const handleCreateSubQuest = (formikObject) => {
    if (video === null) {
      toastIdRef.current = toast({
        title: 'Validation Error',
        description: 'Video is required',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });

      return;
    }

    const formData = new FormData();

    Object.entries(formikObject).forEach(([key, value]) => {
      setCreateFormInputs((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
      formData.append(key, value);
    });

    if (tags.length > 0) tags.forEach((tag) => formData.append('Tags', tag));
    if (groups.length > 0)
      groups.forEach((group) => formData.append('OwnerGroups', group));

    if (video && video.name) {
      formData.append('Video', video, video.name);
    }

    formData.append('IsPublished', isPublished);

    dispatch(createAdminVideoSubQuest({ id: quest.id, formData }));
  };

  const handlePublishStatusChange = (e) => {
    setIsPublished(e);
  };

  useEffect(() => {
    const parsedGroups = JSON.parse(quest.ownerGroups);
    if (
      Array.isArray(parsedGroups) &&
      parsedGroups.every((group) => typeof group === 'string')
    ) {
      setGroups(parsedGroups);
    }
  }, [quest.ownerGroups, setGroups]);

  return (
    <Box>
      {createVideo.status === ReducerStatus.SUCCEEDED ||
      createVideo.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={isOpen}
          onSubmit={handleResult}
          isError={createVideo.status === ReducerStatus.FAILED}
          title={
            createVideo.status === ReducerStatus.SUCCEEDED
              ? 'Video Sub-Quest Created'
              : null
          }
          message={
            createVideo.status === ReducerStatus.SUCCEEDED
              ? 'The video sub-quest has been created successfully.'
              : createVideo.error?.data?.title || 'Server Error'
          }
          errorObject={createVideo.error?.data?.errors}
        />
      ) : (
        <>
          <Button
            onClick={onOpen}
            leftIcon={<MdVideoLibrary />}
            w="100%"
            colorScheme="red"
            size="lg"
          >
            Video
          </Button>
          <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create Video Sub-Quest</ModalHeader>
              <ModalCloseButton />
              <Formik
                enableReinitialize={false}
                initialValues={createFormInputs}
                onSubmit={(values) => {
                  handleCreateSubQuest(values);
                }}
                validationSchema={courseFormValidation}
              >
                {() => (
                  <Form>
                    <ModalBody my={3}>
                      <Text fontSize="md" mb={5}>
                        You are currently adding a sub-quest to this
                        quest:&nbsp;
                        <Text as="span" fontWeight={550}>
                          {quest.name}
                        </Text>
                      </Text>
                      <VStack spacing={6} align="stretch">
                        <Flex
                          gap={5}
                          direction={{
                            base: 'column',
                            md: 'row',
                          }}
                        >
                          <Field flex={1} name="name">
                            {({ field, form }) => (
                              <NameInput field={field} form={form} />
                            )}
                          </Field>

                          <Box
                            w={{
                              base: '100%',
                              md: '380px',
                            }}
                          >
                            <Field name="code">
                              {({ field, form }) => (
                                <CodeInput field={field} form={form} />
                              )}
                            </Field>
                          </Box>
                        </Flex>

                        <Field name="objective">
                          {({ field, form }) => (
                            <ObjectiveInput field={field} form={form} />
                          )}
                        </Field>

                        <Divider />

                        <VideoFileInput
                          video={video}
                          videoURL={videoURL}
                          videoError={videoError}
                          videoInputRef={videoInputRef}
                          handleRemoveSelectedVideo={handleRemoveSelectedVideo}
                          handleAttachVideo={handleAttachVideo}
                          handleVideoInputChange={handleVideoInputChange}
                        />

                        <Field name="videoName">
                          {({ field, form }) => (
                            <VideoNameInput field={field} form={form} />
                          )}
                        </Field>

                        <Field name="description">
                          {({ field, form }) => (
                            <DescriptionInput field={field} form={form} />
                          )}
                        </Field>

                        <Field name="transcript">
                          {({ field, form }) => (
                            <TranscriptInput field={field} form={form} />
                          )}
                        </Field>

                        <Divider />

                        <TagsInput
                          newTag={inputTagsGroups.newTag}
                          tags={tags}
                          isTagError={inputTagsGroups.isTagError}
                          errorMessage={inputTagsGroups.errorMessage}
                          onTagChange={handleTagsGroupsChange}
                          onTagAdd={handleTagAdd}
                          onTagKeyDown={handleTagKeyDown}
                          onTagRemove={handleTagRemove}
                        />

                        <GroupsInput
                          newGroup={inputTagsGroups.newGroup}
                          groups={groups}
                          isGroupError={inputTagsGroups.isGroupError}
                          errorMessage={inputTagsGroups.errorMessage}
                          onGroupChange={handleTagsGroupsChange}
                          onGroupAdd={handleGroupAdd}
                          onGroupKeyDown={handleGroupKeyDown}
                          onGroupRemove={handleGroupRemove}
                        />

                        <PublishInput
                          isPublished={isPublished}
                          handleChange={handlePublishStatusChange}
                        />

                        {createVideo.status === ReducerStatus.LOADING && (
                          <Progress
                            hasStripe
                            isAnimated
                            value={uploadProgress}
                          />
                        )}
                      </VStack>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        variant="outline"
                        colorScheme="blue"
                        mr={3}
                        onClick={onClose}
                      >
                        Close
                      </Button>
                      <Button
                        isLoading={createVideo.status === ReducerStatus.LOADING}
                        colorScheme="red"
                        px={10}
                        type="submit"
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
}

CreateAdminVideoSubQuest.propTypes = {
  quest: PropTypes.object.isRequired,
};

export default CreateAdminVideoSubQuest;

import axios from 'axios';

export const LRSQL_URL = process.env.REACT_APP_LRSQL_URL;

export const generateActor = (accountId, courseId, questId, subQuestId) => {
  const actor = JSON.stringify({
    name: [accountId],
    mbox: [`${accountId}_${courseId}_${questId}_${subQuestId}@lrsql-tm.com`],
  });

  return encodeURIComponent(actor);
};

export const generateAuth = () => {
  const lrsqlApiKey = process.env.REACT_APP_LRSQL_API_KEY;
  const lrsqlSecretKey = process.env.REACT_APP_LRSQL_SECRET_KEY;
  return btoa(`${lrsqlApiKey}:${lrsqlSecretKey}`);
};

export const getProgress = async (accountId, courseId, questId, subQuestId) => {
  const verb = 'http://adlnet.gov/expapi/verbs/passed';
  const agent = {
    objectType: 'Agent',
    mbox: `mailto:${accountId}_${courseId}_${questId}_${subQuestId}@lrsql-tm.com`,
    name: accountId,
  };

  try {
    const request = await axios.get(
      `${LRSQL_URL}xapi/statements?verb=${verb}&agent=${encodeURIComponent(JSON.stringify(agent))}`,
      {
        headers: {
          Authorization: `Basic ${generateAuth()}`,
          'X-Experience-API-Version': '1.0.3',
        },
      }
    );

    return request.data.statements;
  } catch {
    return [];
  }
};

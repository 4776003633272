import PropTypes from 'prop-types';

import { useEffect, useRef } from 'react';

function OutsideClickHandler({ onOutsideClick, children }) {
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick]);

  return <div ref={wrapperRef}>{children}</div>;
}

OutsideClickHandler.defaultProps = {
  onOutsideClick: () => {},
};

OutsideClickHandler.propTypes = {
  onOutsideClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default OutsideClickHandler;

import { useState, useRef } from 'react';

const useFileInput = ({ maxFileSizeMb, fileType }) => {
  const [file, setFile] = useState({});
  const [fileURL, setFileURL] = useState('');
  const [fileError, setFileError] = useState('');
  const fileInputRef = useRef(null);

  const setErrorMessage = (error) => {
    setFileError(error);

    setTimeout(() => {
      setFileError('');
    }, 3000);

    setFile({});
  };

  const handleAttachFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveSelectedFile = () => {
    setFile({});
  };

  const handleFileInputChange = (event) => {
    const targetFile = event.target.files[0];

    if (targetFile) {
      const fileSizeInMB = targetFile.size / (1024 * 1024);

      if (fileSizeInMB > maxFileSizeMb) {
        setErrorMessage(
          `File size exceeds ${maxFileSizeMb} MB limit. Please select a smaller file.`
        );
      } else if (!targetFile.type.startsWith(fileType)) {
        setErrorMessage(`Please select an ${fileType} file.`);
      } else {
        const reader = new FileReader();

        reader.onload = (e) => {
          setFile(targetFile);
          setFileURL(e.target.result);
        };

        reader.readAsDataURL(targetFile);
        event.target.value = null;
      }
    }
  };

  return {
    file,
    fileURL,
    fileError,
    fileInputRef,
    setFile,
    setFileError,
    handleAttachFile,
    handleRemoveSelectedFile,
    handleFileInputChange,
  };
};

export default useFileInput;

import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';
import { deleteQuestByIdAction } from 'store/modules/quest/quest-actions';
import { questActions } from 'store/modules/quest/quest-slice';

function DeleteQuestMd() {
  const dispatch = useDispatch();

  const deleteQuest = useSelector((state) => state.quest.deleteQuest);

  const handleToggleDeleteModal = (isOpen) => {
    dispatch(questActions.toggleDeleteModal(isOpen));
  };

  const handleDeleteQuest = () => {
    dispatch(deleteQuestByIdAction(deleteQuest.questId));
  };

  const handleResultButton = () => {
    dispatch(questActions.resetDeleteStatus());
    if (deleteQuest.status === ReducerStatus.SUCCEEDED) {
      handleToggleDeleteModal(false);
    }
  };

  return (
    <Box>
      {deleteQuest.status === ReducerStatus.SUCCEEDED ||
      deleteQuest.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={deleteQuest.modal}
          onSubmit={handleResultButton}
          isError={deleteQuest.status === ReducerStatus.FAILED}
          title={
            deleteQuest.status === ReducerStatus.SUCCEEDED
              ? 'Deleted'
              : 'Server Error'
          }
          message={
            deleteQuest.status === ReducerStatus.SUCCEEDED
              ? 'The selected quest has been deleted successfully. If you believe this was done in error, please contact the administrator.'
              : null
          }
        />
      ) : (
        <Modal
          isOpen={deleteQuest.modal}
          onClose={() => handleToggleDeleteModal(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation Required</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete this quest? Please note that all
              related quest materials, including those used by other courses,
              will also be deleted.
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => handleToggleDeleteModal(false)}
              >
                Close
              </Button>
              <Button
                variant="outline"
                colorScheme="red"
                isLoading={deleteQuest.status === ReducerStatus.LOADING}
                onClick={() => handleDeleteQuest()}
              >
                Proceed
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default DeleteQuestMd;

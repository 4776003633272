import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAdminLearningPlanCourses = createAsyncThunk(
  'adminLearningPlanCourses/fetchLearningPlanCourses',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/learning-plans/${id}/courses`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const fetchAdminLearningPlanCourseById = createAsyncThunk(
  'adminLearningPlanCourses/fetchLearningPlanCourse',
  async (id, courseId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/admin/learning-plans/${id}/courses/${courseId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const addAdminLearningPlanCourses = createAsyncThunk(
  'adminLearningPlanCourses/addLearningPlanCourse',
  async (coursesData, { rejectWithValue }) => {
    try {
      const responses = [];
      /* eslint-disable no-restricted-syntax */
      /* eslint-disable no-await-in-loop */
      for (const { learningPlanId, courseId } of coursesData) {
        const response = await axios.post(
          `/admin/learning-plans/${learningPlanId}/courses`,
          courseId,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        responses.push({
          learningPlanId,
          courseId,
          data: response.data,
        });
      }

      /* eslint-enable no-restricted-syntax */
      /* eslint-enable no-await-in-loop */
      return responses;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateAdminLearningPlanCourseOrderNo = createAsyncThunk(
  'adminLearningPlanCourses/updateLearningPlanCourseOrderNo',
  async ({ learningPlanId, courseId, newOrderNo }, { rejectWithValue }) => {
    try {
      await axios.put(
        `admin/learning-plans/${learningPlanId}/courses/${courseId}`,
        newOrderNo,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return true;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const removeAdminLearningPlanCourseById = createAsyncThunk(
  'adminLearningPlanCourses/removeLearningPlanCourseById',
  async ({ id, courseId }, { rejectWithValue }) => {
    try {
      await axios.delete(`/admin/learning-plans/${id}/courses/${courseId}`);
      return courseId;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAdminLearningPlans = createAsyncThunk(
  'adminLearningPlans/fetchLearningPlans',
  async (params) => {
    const { query, currentPage, pageSize } = params;
    let apiRoute = `/admin/learning-plans?CurrentPage=${currentPage}&PageSize=${pageSize}`;
    if (query) apiRoute += `&Query=${query}`;

    try {
      const response = await axios.get(apiRoute);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const fetchAdminLearningPlanById = createAsyncThunk(
  'adminLearningPlans/fetchLearningPlanById',
  async (id) => {
    const response = await axios.get(`/admin/learning-plans/${id}`);
    return response.data;
  }
);

export const deleteAdminLearningPlanById = createAsyncThunk(
  'adminLearningPlans/deleteLearningPlanById',
  async (id) => {
    const response = await axios.delete(`/admin/learning-plans/${id}`);
    return response.data;
  }
);

export const createAdminLearningPlan = createAsyncThunk(
  'adminLearningPlans/createLearningPlan',
  async (learningPlanData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/admin/learning-plans',
        learningPlanData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateAdminLearningPlan = createAsyncThunk(
  'adminLearningPlans/updateLearningPlan',
  async ({ id, updateLearningPlanData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/admin/learning-plans/${id}`,
        updateLearningPlanData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

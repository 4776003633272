import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';
import { removeCourseQuestByIdAction } from 'store/modules/quest/quest-actions';
import { questActions } from 'store/modules/quest/quest-slice';

function RemoveQuestMd() {
  const dispatch = useDispatch();

  const removeQuest = useSelector((state) => state.quest.removeQuest);

  const handleToggleRemoveModal = (isOpen) => {
    dispatch(questActions.toggleRemoveModal(isOpen));
  };

  const handleRemoveQuest = () => {
    dispatch(
      removeCourseQuestByIdAction({
        courseId: removeQuest.courseId,
        questId: removeQuest.questId,
      })
    );
  };

  const handleResultButton = () => {
    dispatch(questActions.resetRemoveStatus());
    if (removeQuest.status === ReducerStatus.SUCCEEDED) {
      handleToggleRemoveModal(false);
    }
  };

  return (
    <Box>
      {removeQuest.status === ReducerStatus.SUCCEEDED ||
      removeQuest.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={removeQuest.modal}
          onSubmit={handleResultButton}
          isError={removeQuest.status === ReducerStatus.FAILED}
          title={
            removeQuest.status === ReducerStatus.SUCCEEDED
              ? 'Removed'
              : 'Server Error'
          }
          message={
            removeQuest.status === ReducerStatus.SUCCEEDED
              ? 'The selected quest has been removed successfully.'
              : null
          }
        />
      ) : (
        <Modal
          isOpen={removeQuest.modal}
          onClose={() => handleToggleRemoveModal(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation Required</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to remove this quest from this course? This
              action will detach the quest from the course but will not delete
              the quest itself.
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => handleToggleRemoveModal(false)}
              >
                Close
              </Button>
              <Button
                variant="outline"
                colorScheme="red"
                isLoading={removeQuest.status === ReducerStatus.LOADING}
                onClick={() => handleRemoveQuest()}
              >
                Proceed
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default RemoveQuestMd;

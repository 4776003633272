import { useState } from 'react';

const useTagsInput = () => {
  const [tags, setTags] = useState([]);

  const [inputTags, setInputTags] = useState({
    newTag: '',
    isTagError: false,
    errorMessage: '',
  });

  const handleTagsChange = (identifier, value) => {
    setInputTags((prevValues) => ({
      ...prevValues,
      [identifier]: value,
    }));
  };

  const handleTagRemove = (tagToRemove) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  };

  const handleTagAdd = () => {
    const trimmedTag = inputTags.newTag.trim().toUpperCase();
    if (
      trimmedTag === '' ||
      trimmedTag.length > 20 ||
      tags.includes(trimmedTag)
    ) {
      handleTagsChange('isTagError', true);
      handleTagsChange(
        'errorMessage',
        'Tag should not be null and must not exceed 20 characters.'
      );
    } else {
      handleTagsChange('isTagError', false);
      setTags((prevTags) => [...prevTags, trimmedTag]);
      handleTagsChange('newTag', '');
    }
  };

  const handleTagKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleTagAdd();
    } else {
      handleTagsChange('isTagError', false);
    }
  };

  return {
    tags,
    inputTags,
    setTags,
    handleTagsChange,
    handleTagRemove,
    handleTagAdd,
    handleTagKeyDown,
  };
};

export default useTagsInput;

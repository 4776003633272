import { SkeletonText, Skeleton, Box } from '@chakra-ui/react';

function CourseItemLoading() {
  return (
    <Box
      borderWidth="1px"
      borderRadius="sm"
      overflow="hidden"
      boxShadow="md"
      h="100%"
      position="relative"
    >
      <Skeleton w="100%" h="150px" />
      <SkeletonText
        p={4}
        minHeight="45px"
        noOfLines={1}
        spacing="4"
        skeletonHeight="4"
      />
    </Box>
  );
}

export default CourseItemLoading;

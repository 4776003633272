import { createSlice } from '@reduxjs/toolkit';
import { ReducerStatus } from 'utils/constants';
import {
  fetchCategoriesAction,
  deleteCategoryByIdAction,
  createCategoryAction,
  updateCategoryAction,
} from './category-actions';

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    errors: null,
    status: ReducerStatus.IDLE,
    getCategoryStatus: ReducerStatus.IDLE,
    createCategory: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateCategory: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    deleteCategory: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    error: null,
    categories: [],
    category: null,
    metaData: null,
  },
  reducers: {
    removeDeletedCategory(state, action) {
      const deletedCategoryId = action.payload;
      state.categories = state.categories.filter(
        (category) => category.id !== deletedCategoryId
      );
    },
    setSelectedCategory(state, action) {
      state.category = action.payload;
    },
    updatePageSize(state, action) {
      state.paginationParams.pageSize = action.payload;
    },
    updateCurrentPage(state, action) {
      state.paginationParams.currentPage = action.payload;
    },
    toggleDeleteModal(state, action) {
      state.deleteCategory.modal = action.payload;
    },
    toggleCreateModal(state, action) {
      state.createCategory.modal = action.payload;
    },
    toggleUpdateModal(state, action) {
      state.updateCategory.modal = action.payload;
    },
    resetDeleteStatus(state) {
      state.deleteCategory.status = ReducerStatus.IDLE;
    },
    resetCreateStatus(state) {
      state.createCategory.status = ReducerStatus.IDLE;
    },
    resetUpdateStatus(state) {
      state.updateCategory.status = ReducerStatus.IDLE;
    },
    resetCategoryDetailsStatus(state) {
      state.categoryDetails.status = ReducerStatus.IDLE;
    },
    resetCreateCategory(state) {
      state.createCategory = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
      state.category = null;
    },
    resetUpdateCategory(state) {
      state.updateCategory = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
      state.category = null;
    },
    resetCategoryDetails(state) {
      state.categoryDetails = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
      state.category = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCategoriesAction.pending, (state) => {
        state.status = ReducerStatus.LOADING;
      })
      .addCase(fetchCategoriesAction.fulfilled, (state, action) => {
        state.status = ReducerStatus.SUCCEEDED;
        const { metaData, data } = action.payload;
        state.categories = data;
        state.metaData = metaData;
      })
      .addCase(fetchCategoriesAction.rejected, (state) => {
        state.status = ReducerStatus.FAILED;
      })
      .addCase(deleteCategoryByIdAction.pending, (state) => {
        state.deleteCategory.status = ReducerStatus.LOADING;
      })
      .addCase(deleteCategoryByIdAction.fulfilled, (state) => {
        state.deleteCategory.status = ReducerStatus.SUCCEEDED;
        state.categories = state.categories.filter(
          (category) => category.id !== state.category.id
        );
        state.category = null;
      })
      .addCase(deleteCategoryByIdAction.rejected, (state, action) => {
        state.deleteCategory.status = ReducerStatus.FAILED;
        state.deleteCategory.error = action.payload;
      })
      .addCase(createCategoryAction.pending, (state) => {
        state.createCategory.status = ReducerStatus.LOADING;
      })
      .addCase(createCategoryAction.fulfilled, (state, action) => {
        state.createCategory.status = ReducerStatus.SUCCEEDED;
        state.categories = [...state.categories, action.payload];
        state.category = action.payload;
      })
      .addCase(createCategoryAction.rejected, (state, action) => {
        state.createCategory.status = ReducerStatus.FAILED;
        state.createCategory.error = action.payload;
      })
      .addCase(updateCategoryAction.pending, (state) => {
        state.updateCategory.status = ReducerStatus.LOADING;
      })
      .addCase(updateCategoryAction.fulfilled, (state, action) => {
        state.updateCategory.status = ReducerStatus.SUCCEEDED;
        state.categories = state.categories.map((category) =>
          category.id === action.payload.id
            ? { ...category, ...action.payload }
            : category
        );

        if (state.category && state.category.id === action.payload.id) {
          state.category = { ...state.category, ...action.payload };
        }
      })
      .addCase(updateCategoryAction.rejected, (state, action) => {
        state.updateCategory.status = ReducerStatus.FAILED;
        state.updateCategory.error = action.payload;
      });
  },
});

export const categoryActions = categorySlice.actions;

export default categorySlice;

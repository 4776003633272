import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Field, Form, Formik } from 'formik';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Flex,
} from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';
import NameInput from './components/NameInput';
import DescriptionInput from './components/DescriptionInput';
import GroupsInput from './components/GroupsInput';

import useHandleForm from './hooks/useHandleForm';

import { createAdminLearningPlan } from '../../store/admin-learningplan-action';

import { adminLearningPlanActions } from '../../store/admin-learningplan-slice';

function CreateAdminLearningPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    groups,
    inputTagsGroups,
    learningplanFormValidation,
    setGroups,
    handleTagsGroupsChange,
    handleGroupRemove,
    handleGroupAdd,
    handleGroupKeyDown,
  } = useHandleForm();

  const [createFormInputs, setCreateFormInputs] = useState({
    name: '',
    description: '',
  });

  const createLearningPlan = useSelector(
    (state) => state.adminLearningPlan.createLearningPlan
  );
  const learningPlanId = useSelector(
    (state) => state.adminLearningPlan.learningplan?.id
  );

  const handleToggleCreateModal = (isOpen) => {
    dispatch(adminLearningPlanActions.toggleCreateModal(isOpen));
  };

  const handleResultButton = () => {
    dispatch(adminLearningPlanActions.resetCreateStatus());
    if (createLearningPlan.status === ReducerStatus.SUCCEEDED) {
      setCreateFormInputs({
        name: '',
        description: '',
      });
      handleToggleCreateModal(false);
      setGroups([]);
      navigate(`admin/learning-plans/${learningPlanId}`);
    }
  };

  const handleCreateLearningPlan = (formikObject) => {
    const learningPlanData = {
      ...formikObject,
      OwnerGroups: groups,
    };
    dispatch(createAdminLearningPlan(learningPlanData));
  };

  return (
    <Box>
      {createLearningPlan.status === ReducerStatus.SUCCEEDED ||
      createLearningPlan.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={createLearningPlan.modal}
          onSubmit={handleResultButton}
          isError={createLearningPlan.status === ReducerStatus.FAILED}
          title={
            createLearningPlan.status === ReducerStatus.SUCCEEDED
              ? 'Learning Plan Created'
              : null
          }
          message={
            createLearningPlan.status === ReducerStatus.SUCCEEDED
              ? 'The learning plan has been created successfully.'
              : createLearningPlan.error?.data?.title || 'Server Error'
          }
          errorObject={createLearningPlan.error?.data?.errors}
        />
      ) : (
        <Modal
          size="2xl"
          isOpen={createLearningPlan.modal}
          onClose={() => handleToggleCreateModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create Learning Plan</ModalHeader>
            <ModalCloseButton />
            <Formik
              enableReinitialize={false}
              initialValues={createFormInputs}
              onSubmit={(values) => {
                handleCreateLearningPlan(values);
              }}
              validationSchema={learningplanFormValidation}
            >
              {() => (
                <Form>
                  <ModalBody my={3}>
                    <VStack spacing={6} align="stretch">
                      <Flex>
                        <Field flex={1} name="name">
                          {({ field, form }) => (
                            <NameInput field={field} form={form} />
                          )}
                        </Field>
                      </Flex>

                      <Field name="description">
                        {({ field, form }) => (
                          <DescriptionInput field={field} form={form} />
                        )}
                      </Field>

                      <GroupsInput
                        newGroup={inputTagsGroups.newGroup}
                        groups={groups}
                        isGroupError={inputTagsGroups.isGroupError}
                        errorMessage={inputTagsGroups.errorMessage}
                        onGroupChange={handleTagsGroupsChange}
                        onGroupAdd={handleGroupAdd}
                        onGroupKeyDown={handleGroupKeyDown}
                        onGroupRemove={handleGroupRemove}
                      />
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      variant="outline"
                      colorScheme="blue"
                      mr={3}
                      onClick={() => handleToggleCreateModal(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="red"
                      px={10}
                      type="submit"
                      isLoading={
                        createLearningPlan.status === ReducerStatus.LOADING
                      }
                    >
                      Create
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default CreateAdminLearningPlan;

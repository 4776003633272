import { createSlice } from '@reduxjs/toolkit';

import { ReducerStatus } from 'utils/constants';
import {
  fetchAdminGrowthStages,
  fetchAdminGrowthStageById,
  createAdminGrowthStage,
  updateAdminGrowthStageById,
  deleteAdminGrowthStageById,
} from './admin-growthstage-action';

const adminGrowthStageSlice = createSlice({
  name: 'adminGrowthStage',
  initialState: {
    growthStages: [],
    growthStage: null,
    growthStageLearningPlans: [],
    metaData: null,
    status: ReducerStatus.IDLE,
    getGrowthStageStatus: ReducerStatus.IDLE,
    createGrowthStage: {
      modal: false,
      status: null,
      error: null,
    },
    updateGrowthStage: {
      modal: false,
      status: null,
      error: null,
    },
    deleteGrowthStage: {
      modal: false,
      status: ReducerStatus.IDLE,
    },
    tableRefresher: false,
  },
  reducers: {
    toggleCreateModal(state, action) {
      state.createGrowthStage.modal = action.payload;
    },
    toggleUpdateModal(state, action) {
      state.updateGrowthStage.modal = action.payload;
    },
    toggleDeleteModal(state, action) {
      state.deleteGrowthStage.modal = action.payload;
    },
    resetCreateStatus(state) {
      state.createGrowthStage.status = ReducerStatus.IDLE;
    },
    resetUpdateStatus(state) {
      state.updateGrowthStage.status = ReducerStatus.IDLE;
    },
    resetDeleteStatus(state) {
      state.deleteGrowthStage.status = ReducerStatus.IDLE;
    },
    resetCreateGrowthStage(state) {
      state.createGrowthStage = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    resetUpdateGrowthStage(state) {
      state.updateGrowthStage = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    resetDeleteGrowthStage(state) {
      state.deleteGrowthStage = {
        modal: false,
        status: ReducerStatus.IDLE,
      };
    },
    resetTableRefresher(state) {
      state.tableRefresher = !state.tableRefresher;
    },
  },
  extraReducers(builder) {
    builder
      // Fetch Growth Stages
      .addCase(fetchAdminGrowthStages.pending, (state) => {
        state.status = ReducerStatus.LOADING;
      })
      .addCase(fetchAdminGrowthStages.fulfilled, (state, action) => {
        state.status = ReducerStatus.SUCCEEDED;
        const { metaData, data } = action.payload;
        state.growthStages = data;
        state.metaData = metaData;
      })
      // Fetch Growth Stage By ID
      .addCase(fetchAdminGrowthStageById.pending, (state) => {
        state.getGrowthStageStatus = ReducerStatus.LOADING;
      })
      .addCase(fetchAdminGrowthStageById.fulfilled, (state, action) => {
        state.getGrowthStageStatus = ReducerStatus.SUCCEEDED;
        state.growthStage = action.payload;
        state.growthStageLearningPlans = action.payload.learningPlans.map(
          (lp) => ({
            id: lp.learningPlan.id,
            name: lp.learningPlan.name,
          })
        );
      })
      .addCase(fetchAdminGrowthStageById.rejected, (state) => {
        state.getGrowthStageStatus = ReducerStatus.FAILED;
      })
      // Create Growth Stage
      .addCase(createAdminGrowthStage.pending, (state) => {
        state.createGrowthStage.status = ReducerStatus.LOADING;
      })
      .addCase(createAdminGrowthStage.fulfilled, (state, action) => {
        state.createGrowthStage.status = ReducerStatus.SUCCEEDED;
        state.growthStages = [...state.growthStages, action.payload];
        state.growthStage = action.payload;
      })
      .addCase(createAdminGrowthStage.rejected, (state, action) => {
        state.createGrowthStage.status = ReducerStatus.FAILED;
        state.createGrowthStage.error = action.payload;
      })
      // Update Growth Stage By ID
      .addCase(updateAdminGrowthStageById.pending, (state) => {
        state.updateGrowthStage.status = ReducerStatus.LOADING;
      })
      .addCase(updateAdminGrowthStageById.fulfilled, (state, action) => {
        state.updateGrowthStage.status = ReducerStatus.SUCCEEDED;
        state.growthStages = state.growthStages.map((growthStage) =>
          growthStage.id === action.payload.id
            ? { ...growthStage, ...action.payload }
            : growthStage
        );
        state.growthStage = action.payload;
      })
      .addCase(updateAdminGrowthStageById.rejected, (state, action) => {
        state.updateGrowthStage.status = ReducerStatus.FAILED;
        state.updateGrowthStage.error = action.payload;
      })
      // Delete Growth Stage By ID
      .addCase(deleteAdminGrowthStageById.pending, (state) => {
        state.deleteGrowthStage.status = ReducerStatus.LOADING;
      })
      .addCase(deleteAdminGrowthStageById.fulfilled, (state) => {
        state.deleteGrowthStage.status = ReducerStatus.SUCCEEDED;
      })
      .addCase(deleteAdminGrowthStageById.rejected, (state) => {
        state.deleteGrowthStage.status = ReducerStatus.FAILED;
      });
  },
});

export const adminGrowthStageActions = adminGrowthStageSlice.actions;

export default adminGrowthStageSlice;

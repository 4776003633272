import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@chakra-ui/react';
import { DndContext, closestCorners } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import GrowthStageLearningPlan from './GrowthStageLearningPlan';

function SelectedLearningPlansList({
  selectedLearningPlans,
  handleOnDragEnd,
  onEdit,
  onDelete,
}) {
  return (
    <DndContext onDragEnd={handleOnDragEnd} collisionDetection={closestCorners}>
      <SortableContext
        items={selectedLearningPlans}
        strategy={verticalListSortingStrategy}
      >
        <Box mt={3}>
          {selectedLearningPlans?.map((plan, index) => (
            <GrowthStageLearningPlan
              id={plan?.id}
              key={uuidv4()}
              stageNum={index}
              growthStageLearningPlan={plan}
              mb={2}
              borderWidth="1px"
              borderRadius="md"
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </Box>
      </SortableContext>
    </DndContext>
  );
}

SelectedLearningPlansList.propTypes = {
  selectedLearningPlans: PropTypes.array.isRequired,
  handleOnDragEnd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SelectedLearningPlansList;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Stepper,
  Step,
  StepIndicator,
  StepIcon,
  StepNumber,
  StepStatus,
  StepTitle,
  StepDescription,
  StepSeparator,
  useSteps,
  Box,
  ModalBody,
  VStack,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Divider,
  Button,
  Kbd,
  RadioGroup,
  Radio,
  FormHelperText,
  Grid,
  GridItem,
  FormErrorMessage,
  Switch,
  Text,
  NumberInputField,
  NumberInput,
  Select,
  IconButton,
  Collapse,
  Spacer,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';

import ResultModal from 'components/common/modals/ResultModal';

import { ReducerStatus } from 'utils/constants';
import { DragHandleIcon } from '@chakra-ui/icons';
import { IoMdClose } from 'react-icons/io';
import MultipleChoice from '../../../../../components/question/multipleChoice';
import TrueOrFalse from '../../../../../components/question/trueOrFalse';

import { adminSubQuestActions } from '../../store/admin-subquest-slice';

import {
  updateAdminQuizSubQuest,
  createSubQuestQuizQuestions,
} from '../../store/admin-subquest-action';

function UpdateAdminQuizSubQuestById() {
  const dispatch = useDispatch();

  const updateQuiz = useSelector((state) => state.adminSubQuest.updateQuiz);
  const questId = useSelector((state) => state.adminSubQuest.questId);
  const subQuest = useSelector((state) => state.adminSubQuest.subQuest);

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState({
    value: ' ',
    isTagError: false,
    errorMessage: '',
  });

  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState({
    value: ' ',
    isGroupError: false,
    errorMessage: '',
  });

  const [timeBeforeNextAttempt, setTimeBeforeNextAttempt] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const [quizDuration, setQuizDuration] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // const [notificationList, setNotificationList] = useState([]);
  // const [newNotificationList, setNewNotificationList] = useState({
  //   value: " ",
  //   isNotificationListError: false,
  //   errorMessage: "",
  // });

  const [questions, setQuestions] = useState([]);

  const [updateFormInputs, setUpdateFormInputs] = useState({
    name: '',
    objective: '',
    code: '',
    title: '',
    showResult: false,
    unlimitedAttempts: false,
    fetchFromQuestionBank: false,
    enableNotification: false,
    notifyUser: false,
    notifyManager: false,
    numberOfAttempts: 1,
    passingScore: 1,
    scoreType: 'Actual Score',
    totalMark: 1,
    isShuffled: true,
    isAllOrNothing: false,
    showAll: false,
    isPublished: false,
  });

  const resetForm = () => {
    setQuestions([]);
    setTags([]);
    setGroups([]);
  };

  const convertQuizDuration = (time) => {
    let remainingTime = time;
    const hours = Math.floor(remainingTime / 3600);
    remainingTime %= 3600;
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return {
      hours,
      minutes,
      seconds,
    };
  };

  function convertTimeBeforeNextAttempt(seconds) {
    let remainingSeconds = seconds;
    const days = Math.floor(remainingSeconds / (3600 * 24));
    remainingSeconds %= 3600 * 24;
    const hours = Math.floor(remainingSeconds / 3600);
    remainingSeconds %= 3600;
    const minutes = Math.floor(remainingSeconds / 60);

    return {
      days,
      hours,
      minutes,
    };
  }

  useEffect(() => {
    const resetMultiInputsForm = () => {
      setQuestions([]);
      setGroups([]);
      setTags([]);
    };

    if (updateQuiz.modal) {
      resetMultiInputsForm();

      setUpdateFormInputs({
        name: subQuest?.name,
        objective: subQuest?.objective,
        code: subQuest?.code,
        isPublished: subQuest?.isPublished,
        title: subQuest?.subQuestQuiz.title,
        isShuffled: subQuest?.subQuestQuiz.isShuffled,
        showResult: subQuest?.subQuestQuiz.showResult,
        unlimitedAttempts: subQuest?.subQuestQuiz.unlimitedAttempts,
        fetchFromQuestionBank: subQuest?.subQuestQuiz.fetchFromQuestionBank,
        enableNotification: subQuest?.subQuestQuiz.enableNotification,
        notifyUser: subQuest?.subQuestQuiz.notifyUser,
        notifyManager: subQuest?.subQuestQuiz.notifyManager,
        numberOfAttempts: subQuest?.subQuestQuiz.numberOfAttempts,
        passingScore: subQuest?.subQuestQuiz.passingScore,
        scoreType: subQuest?.subQuestQuiz.scoreType,
        totalMark: subQuest?.subQuestQuiz.totalMark,
        isAllOrNothing: subQuest?.subQuestQuiz.isAllOrNothing,
        showAll: subQuest?.subQuestQuiz.showAll,
      });

      const initQuizDuration = convertQuizDuration(
        subQuest?.subQuestQuiz?.time
      );
      setQuizDuration(initQuizDuration);

      const initTimeBeforeNextAttempt = convertTimeBeforeNextAttempt(
        subQuest?.subQuestQuiz?.timeBeforeNextAttempt
      );
      setTimeBeforeNextAttempt(initTimeBeforeNextAttempt);
      const currentTags = JSON.parse(subQuest?.tags);
      setTags(currentTags);
      const ownerGroups = JSON.parse(subQuest?.ownerGroups);
      setGroups(ownerGroups);

      const quizQuestions = subQuest?.subQuestQuiz?.questions
        ? Object.entries(subQuest.subQuestQuiz.questions).map(([value]) => {
            const question = { ...value };

            try {
              question.multipleChoiceChoices = JSON.parse(
                value.multipleChoiceChoicesJson
              );
            } catch (e) {
              question.multipleChoiceChoices = [];
            }

            try {
              question.multipleChoiceAnswers = JSON.parse(
                value.multipleChoiceAnswersJson
              );
            } catch (e) {
              question.multipleChoiceAnswers = [];
            }

            question.questionTypeId = value.questionType?.id;

            return question;
          })
        : [];
      setQuestions(quizQuestions);
    }
  }, [updateQuiz.modal, subQuest, dispatch]);

  const handleToggleUpdateModal = (isOpen) => {
    dispatch(adminSubQuestActions.toggleQuizUpdateModal(isOpen));
  };

  const handleResultButton = () => {
    dispatch(adminSubQuestActions.resetQuizUpdateStatus());
    if (updateQuiz.status === ReducerStatus.SUCCEEDED) {
      resetForm();
      handleToggleUpdateModal(false);
    }
  };

  const handleTagAdd = () => {
    const trimmedTag = newTag.value.trim().toUpperCase();
    if (
      trimmedTag === '' ||
      trimmedTag.length > 20 ||
      tags.includes(trimmedTag)
    ) {
      setNewTag({
        ...newTag,
        isTagError: true,
        errorMessage:
          'Tag should not be null and must not exceed 20 characters.',
      });
    } else {
      setTags((prevTags) => [...prevTags, trimmedTag]);
      setNewTag({ ...newTag, value: '', isTagError: false });
    }
  };

  const handleGroupAdd = () => {
    const allowedDomains = ['trendmicro.com', 'dl.trendmicro.com'];
    const domainRegex = /@([a-zA-Z0-9.-]+)$/;
    const match = newGroup.value.match(domainRegex);

    if (match && match[1] && allowedDomains.includes(match[1])) {
      setGroups((prevGroups) => [...prevGroups, newGroup.value]);
      setNewGroup({ ...newGroup, isGroupError: false, value: '' });
    } else {
      setNewGroup({
        ...newGroup,
        isGroupError: true,
        errorMessage:
          'Email is not from an allowed domain. It must be one of the following: trendmicro.com, dl.trendmicro.com.',
      });
    }
  };

  // const handleNotificationListAdd = () => {
  //   const allowedDomains = ["trendmicro.com", "dl.trendmicro.com"];
  //   const domainRegex = /@([a-zA-Z0-9.-]+)$/;
  //   const match = newNotificationList.value.match(domainRegex);

  //   if (match && match[1] && allowedDomains.includes(match[1])) {
  //     setNotificationList((prevNotificationList) => [
  //       ...prevNotificationList,
  //       newNotificationList.value,
  //     ]);
  //     setNewNotificationList({
  //       ...newNotificationList,
  //       isNotificationListError: false,
  //       value: "",
  //     });
  //   } else {
  //     setNewNotificationList({
  //       ...newNotificationList,
  //       isNotificationListError: true,
  //       errorMessage:
  //         "Email is not from an allowed domain. It must be one of the following: trendmicro.com, dl.trendmicro.com.",
  //     });
  //   }
  // };

  const handleTagRemove = (tagToRemove) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  };

  const handleGroupRemove = (groupToRemove) => {
    setGroups((prevGroups) =>
      prevGroups.filter((group) => group !== groupToRemove)
    );
  };

  // const handleNotificationListRemove = (groupToRemove) => {
  //   setNotificationList((prevGroups) =>
  //     prevGroups.filter((group) => group !== groupToRemove)
  //   );
  // };

  const steps = [
    { title: 'Step 1', description: 'Sub Quest' },
    { title: 'Step 2', description: 'Quiz' },
  ];

  const questionTypes = [
    { Name: 'Multiple Choice', Id: 1 },
    { Name: 'True or False', Id: 2 },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const handleTimeBeforeNextAttemptDaysChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setTimeBeforeNextAttempt({
      ...timeBeforeNextAttempt,
      days: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };
  const handleTimeBeforeNextAttemptHoursChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setTimeBeforeNextAttempt({
      ...timeBeforeNextAttempt,
      hours: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };
  const handleTimeBeforeNextAttemptMinutesChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setTimeBeforeNextAttempt({
      ...timeBeforeNextAttempt,
      minutes: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };

  const handleQuizDurationHoursChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setQuizDuration({
      ...quizDuration,
      hours: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };
  const handleQuizDurationMinutesChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setQuizDuration({
      ...quizDuration,
      minutes: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };

  const handleQuizDurationSecondsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setQuizDuration({
      ...quizDuration,
      seconds: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      questionHtml: '',
      questionTypeId: 1,
      multipleChoiceChoices: [
        { option: 'A', value: 'A' },
        { option: 'B', value: 'B' },
      ],
      multipleChoiceAnswers: [],
      trueOrFalseAnswer: true,
      explanation: '',
      mark: 0,
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleUpdateQuestion = (index, updatedQuestion) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = updatedQuestion;
    setQuestions(updatedQuestions);
  };

  const handleQuestionTypeChange = (index, question, newQuestionTypeId) => {
    const updatedQuestion = {
      ...question,
      questionTypeId: parseInt(newQuestionTypeId, 10),
    };
    handleUpdateQuestion(index, updatedQuestion);
  };

  const handleRemoveQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const convertQuizDurationToSeconds = ({ hours, minutes, seconds }) => {
    const hoursInSeconds = hours * 3600;
    const minutesInSeconds = minutes * 60;
    return hoursInSeconds + minutesInSeconds + seconds;
  };

  const convertTimeBeforeNextAttemptToSeconds = ({ days, hours, minutes }) => {
    const daysInSeconds = days * 24 * 60 * 60;
    const hoursInSeconds = hours * 60 * 60;
    const minutesInSeconds = minutes * 60;
    return daysInSeconds + hoursInSeconds + minutesInSeconds;
  };

  const handleUpdateSubQuest = async () => {
    const quizDurationTime = convertQuizDurationToSeconds(quizDuration);
    const timeBeforeNextAttemptTime = convertTimeBeforeNextAttemptToSeconds(
      timeBeforeNextAttempt
    );
    const quizSubQuest = updateFormInputs;
    quizSubQuest.time = quizDurationTime;
    quizSubQuest.timeBeforeNextAttempt = timeBeforeNextAttemptTime;
    quizSubQuest.tags = tags;
    quizSubQuest.ownerGroups = groups;
    // quizSubQuest["notificationList"] = notificationList;
    quizSubQuest.quizTitle = quizSubQuest.title;

    dispatch(
      updateAdminQuizSubQuest({
        subQuestId: subQuest?.id,
        questId,
        quizSubQuest,
      })
    );

    if (quizSubQuest.fetchFromQuestionBank === false) {
      const createdSubQuestQuizId = subQuest?.subQuestQuiz?.id;
      questions.forEach((question, index) => {
        const updatedQuestion = question;
        updatedQuestion.SubQuestQuizId = createdSubQuestQuizId;
        handleUpdateQuestion(index, updatedQuestion);
      });
      dispatch(createSubQuestQuizQuestions({ questions }));
    }
  };

  return (
    <Box>
      {updateQuiz.status === ReducerStatus.SUCCEEDED ||
      updateQuiz.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={updateQuiz.modal}
          onSubmit={handleResultButton}
          isError={updateQuiz.status === ReducerStatus.FAILED}
          title={
            updateQuiz.status === ReducerStatus.SUCCEEDED
              ? 'Sub-Quest Updated'
              : null
          }
          message={
            updateQuiz.status === ReducerStatus.SUCCEEDED
              ? 'The sub-quest has been updated successfully.'
              : updateQuiz.error?.data?.title || 'Server Error'
          }
          errorObject={updateQuiz.error?.data?.errors}
        />
      ) : (
        <Modal
          size="6xl"
          isOpen={updateQuiz.modal}
          onClose={() => handleToggleUpdateModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Quiz Sub-Quest</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Stepper size="lg" index={activeStep}>
                {steps.map((step, index) => (
                  <Step key={uuidv4()} onClick={() => setActiveStep(index)}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>
                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>
                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
              {activeStep === 0 ? (
                <VStack spacing={6} align="stretch">
                  <Flex
                    gap={5}
                    direction={{
                      base: 'column',
                      md: 'row',
                    }}
                  >
                    <FormControl>
                      <FormLabel>Name</FormLabel>
                      <Input
                        value={updateFormInputs.name}
                        onChange={(e) =>
                          setUpdateFormInputs({
                            ...updateFormInputs,
                            name: e.target.value,
                          })
                        }
                      />
                      <FormHelperText>
                        Enter the name of the sub-quest. Make it concise and
                        descriptive.
                      </FormHelperText>
                    </FormControl>
                    <Box w={{ base: '100%', md: '380px' }}>
                      <FormControl>
                        <FormLabel>Code</FormLabel>
                        <Input value={updateFormInputs.code} />
                        <FormHelperText>
                          This code will be used to distinguish the sub-quest
                          from others.
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Flex>
                  <FormControl>
                    <FormLabel>Objective</FormLabel>
                    <Textarea value={updateFormInputs.objective} />

                    <FormHelperText>
                      Provide a detailed objective of the sub-quest. Mention the
                      key objectives and what learners can expect to gain.
                    </FormHelperText>
                  </FormControl>
                  <Divider />

                  <FormControl isInvalid={newTag.isTagError}>
                    <FormLabel>Tags</FormLabel>
                    <Flex gap={2}>
                      <Input
                        type="text"
                        value={newTag.value}
                        onChange={(e) =>
                          setNewTag({
                            ...newTag,
                            value: e.target.value,
                          })
                        }
                      />
                      <Button
                        colorScheme="teal"
                        variant="outline"
                        size="md"
                        onClick={handleTagAdd}
                      >
                        <Kbd>enter</Kbd>
                      </Button>
                    </Flex>
                    <Box my={2}>
                      {tags.map((tag) => (
                        <Tag
                          key={uuidv4()}
                          size="md"
                          mr={2}
                          mb={1}
                          borderRadius="full"
                          variant="solid"
                          colorScheme="teal"
                        >
                          <TagLabel>{tag}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleTagRemove(tag)}
                          />
                        </Tag>
                      ))}
                    </Box>
                    <Box my={2}>
                      {newTag.isTagError ? (
                        <FormErrorMessage>
                          {newTag.errorMessage}
                        </FormErrorMessage>
                      ) : (
                        <FormHelperText>
                          Enter relevant tags and press Enter after each one to
                          add to the list. Tags help in categorizing and
                          searching for courses. Example: &ldquo;programming,
                          web development, beginner.&ldquo;
                        </FormHelperText>
                      )}
                    </Box>
                  </FormControl>

                  <FormControl isInvalid={newGroup.isGroupError}>
                    <FormLabel optionalIndicator>Owner Groups</FormLabel>
                    <Flex gap={2}>
                      <Input
                        type="text"
                        value={newGroup.value}
                        onChange={(e) =>
                          setNewGroup({
                            ...newGroup,
                            value: e.target.value,
                          })
                        }
                      />
                      <Button
                        colorScheme="teal"
                        variant="outline"
                        size="md"
                        onClick={handleGroupAdd}
                      >
                        <Kbd>enter</Kbd>
                      </Button>
                    </Flex>
                    <Box my={2}>
                      {groups.map((group) => (
                        <Tag
                          key={uuidv4()}
                          size="md"
                          mr={2}
                          mb={1}
                          borderRadius="full"
                          variant="solid"
                          colorScheme="teal"
                        >
                          <TagLabel>{group}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleGroupRemove(group)}
                          />
                        </Tag>
                      ))}
                    </Box>
                    {newGroup.isGroupError ? (
                      <FormErrorMessage>
                        {newGroup.errorMessage}
                      </FormErrorMessage>
                    ) : (
                      <FormHelperText>
                        Specify the distribution lists or groups that have the
                        authority to modify this video sub-quest. Press Enter
                        after each entry to add it to the list. This ensures
                        proper access control.
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl>
                    <FormLabel>Published Status</FormLabel>
                    <RadioGroup
                      value={updateFormInputs.isPublished}
                      onChange={(e) =>
                        setUpdateFormInputs({
                          ...updateFormInputs,
                          isPublished: e,
                        })
                      }
                    >
                      <Stack spacing={5} direction="row">
                        <Radio colorScheme="purple" value={false}>
                          Draft
                        </Radio>
                        <Radio colorScheme="green" value>
                          Published
                        </Radio>
                      </Stack>
                    </RadioGroup>
                    <FormHelperText>
                      Choose between &ldquo;Draft&ldquo; if the course is still
                      in progress and not ready for public access, or
                      &ldquo;Published&ldquo; if the sub-quest is ready for
                      learners.
                    </FormHelperText>
                  </FormControl>
                </VStack>
              ) : (
                <Stack>
                  <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                    <GridItem colSpan={2}>
                      <FormControl>
                        <FormLabel>Title</FormLabel>
                        <Input value={updateFormInputs.title} />

                        <FormErrorMessage />
                      </FormControl>
                      <Flex mt={2}>
                        <Box>
                          <FormControl>
                            <FormLabel>Shuffle Questions</FormLabel>
                            <Switch
                              isChecked={updateFormInputs.isShuffled}
                              onChange={() =>
                                setUpdateFormInputs({
                                  ...updateFormInputs,
                                  isShuffled: !updateFormInputs.isShuffled,
                                })
                              }
                            />
                          </FormControl>
                        </Box>
                        <Box>
                          <FormControl>
                            <FormLabel>Show Result</FormLabel>
                            <Switch
                              isChecked={updateFormInputs.showResult}
                              onChange={() =>
                                setUpdateFormInputs({
                                  ...updateFormInputs,
                                  showResult: !updateFormInputs.showResult,
                                })
                              }
                            />
                          </FormControl>
                        </Box>
                      </Flex>
                      <Flex align="end">
                        <Box mb={4}>
                          <FormControl>
                            <FormLabel>Unlimited Attempts</FormLabel>
                            <Switch
                              isChecked={updateFormInputs.unlimitedAttempts}
                              onChange={() =>
                                setUpdateFormInputs({
                                  ...updateFormInputs,
                                  unlimitedAttempts:
                                    !updateFormInputs.unlimitedAttempts,
                                })
                              }
                            />
                          </FormControl>
                        </Box>
                        {updateFormInputs.unlimitedAttempts === false && (
                          <Box mr={4}>
                            <FormControl>
                              <FormLabel>No. of Attempts</FormLabel>
                              <Input
                                value={updateFormInputs.numberOfAttempts}
                                type="number"
                                onChange={(e) =>
                                  setUpdateFormInputs({
                                    ...updateFormInputs,
                                    numberOfAttempts: parseInt(
                                      e.target.value,
                                      10
                                    ),
                                  })
                                }
                              />
                              <FormErrorMessage />
                            </FormControl>
                          </Box>
                        )}
                        <Box mt={2}>
                          <Flex>
                            <Text fontWeight="medium">
                              Time before next attempt
                            </Text>
                          </Flex>
                          <Flex>
                            <Box w="70px" mr={1}>
                              <FormControl>
                                <FormLabel>Days</FormLabel>
                                <NumberInput
                                  min={0}
                                  max={364}
                                  defaultValue={timeBeforeNextAttempt.days}
                                >
                                  <NumberInputField
                                    value={timeBeforeNextAttempt.days}
                                    onChange={
                                      handleTimeBeforeNextAttemptDaysChange
                                    }
                                  />
                                </NumberInput>
                              </FormControl>
                            </Box>
                            <Box w="70px" mr={1}>
                              <FormControl>
                                <FormLabel>Hours</FormLabel>
                                <NumberInput
                                  min={0}
                                  max={23}
                                  defaultValue={timeBeforeNextAttempt.hours}
                                >
                                  <NumberInputField
                                    value={timeBeforeNextAttempt.hours}
                                    onChange={
                                      handleTimeBeforeNextAttemptHoursChange
                                    }
                                  />
                                </NumberInput>
                              </FormControl>
                            </Box>
                            <Box w="70px" mr={1}>
                              <FormControl>
                                <FormLabel>Min</FormLabel>
                                <NumberInput
                                  min={0}
                                  max={59}
                                  defaultValue={timeBeforeNextAttempt.minutes}
                                >
                                  <NumberInputField
                                    value={timeBeforeNextAttempt.minutes}
                                    onChange={
                                      handleTimeBeforeNextAttemptMinutesChange
                                    }
                                  />
                                </NumberInput>
                              </FormControl>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                      <Flex mt={2}>
                        {/* <Box>
                          <FormControl>
                            <FormLabel>Question Bank</FormLabel>
                            <Switch
                              size="md"
                              isChecked={updateFormInputs.fetchFromQuestionBank}
                              onChange={(e) =>
                                setUpdateFormInputs({
                                  ...updateFormInputs,
                                  fetchFromQuestionBank:
                                    !updateFormInputs.fetchFromQuestionBank,
                                })
                              }
                            />
                          </FormControl>
                        </Box> */}
                        <Box>
                          <FormControl>
                            <FormLabel>Total Mark</FormLabel>
                            <Input
                              isDisabled
                              defaultValue={updateFormInputs.totalMark}
                              type="number"
                            />
                            <FormErrorMessage />
                          </FormControl>
                        </Box>
                      </Flex>
                    </GridItem>
                    <GridItem colSpan={2}>
                      <Flex>
                        <Box mr={4}>
                          <FormControl>
                            <FormLabel>Score Setting</FormLabel>
                            <Select
                              defaultValueValue={updateFormInputs.scoreType}
                              onChange={(e) =>
                                setUpdateFormInputs({
                                  ...updateFormInputs,
                                  scoreType: e.target.value,
                                })
                              }
                            >
                              <option value="Actual Score">Actual Score</option>
                              <option value="Percentage">Percentage</option>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box>
                          <FormControl>
                            <FormLabel>Passing Score</FormLabel>
                            <Input
                              value={updateFormInputs.passingScore}
                              type="number"
                              onChange={(e) =>
                                setUpdateFormInputs({
                                  ...updateFormInputs,
                                  passingScore: parseFloat(e.target.value),
                                })
                              }
                            />

                            <FormErrorMessage />
                          </FormControl>
                        </Box>
                      </Flex>
                      <Flex mt={2}>
                        <Box>
                          <Flex>
                            <Text fontWeight="medium">Quiz Duration</Text>
                          </Flex>
                          <Flex>
                            <Box w="70px" mr={1}>
                              <FormControl>
                                <FormLabel>Hours</FormLabel>
                                <NumberInput
                                  min={0}
                                  max={23}
                                  defaultValue={quizDuration.hours}
                                >
                                  <NumberInputField
                                    value={quizDuration.hours}
                                    onChange={handleQuizDurationHoursChange}
                                  />
                                </NumberInput>
                              </FormControl>
                            </Box>
                            <Box w="70px" mr={1}>
                              <FormControl>
                                <FormLabel>Min</FormLabel>
                                <NumberInput
                                  min={0}
                                  max={59}
                                  defaultValue={quizDuration.minutes}
                                >
                                  <NumberInputField
                                    value={quizDuration.minutes}
                                    onChange={handleQuizDurationMinutesChange}
                                  />
                                </NumberInput>
                              </FormControl>
                            </Box>
                            <Box w="70px" mr={1}>
                              <FormControl>
                                <FormLabel>Sec</FormLabel>
                                <NumberInput
                                  min={0}
                                  max={60}
                                  defaultValue={quizDuration.seconds}
                                >
                                  <NumberInputField
                                    value={quizDuration.seconds}
                                    onChange={handleQuizDurationSecondsChange}
                                  />
                                </NumberInput>
                              </FormControl>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                      {/* <Flex mt={2}>
                        <Box>
                          <FormControl>
                            <FormLabel>Enable Notification</FormLabel>
                            <Switch
                              size="md"
                              isChecked={updateFormInputs.enableNotification}
                              onChange={(e) =>
                                setUpdateFormInputs({
                                  ...updateFormInputs,
                                  enableNotification:
                                    !updateFormInputs.enableNotification,
                                })
                              }
                            />
                          </FormControl>
                        </Box>
                      </Flex>
                      <Flex mt={2} mb={2}>
                        <Box>
                          <FormControl>
                            <FormLabel>Notify User</FormLabel>
                            <Switch
                              size="md"
                              isChecked={updateFormInputs.notifyUser}
                              onChange={(e) =>
                                setUpdateFormInputs({
                                  ...updateFormInputs,
                                  notifyUser: !updateFormInputs.notifyUser,
                                })
                              }
                            />
                          </FormControl>
                        </Box>
                        <Box>
                          <FormControl>
                            <FormLabel>Notify Manager</FormLabel>
                            <Switch
                              size="md"
                              isChecked={updateFormInputs.notifyManager}
                              onChange={(e) =>
                                setUpdateFormInputs({
                                  ...updateFormInputs,
                                  notifyManager:
                                    !updateFormInputs.notifyManager,
                                })
                              }
                            />
                          </FormControl>
                        </Box>
                      </Flex>

                      <FormControl
                        isInvalid={newNotificationList.isNotificationListError}
                      >
                        <FormLabel optionalIndicator>
                          Owner Notification List
                        </FormLabel>
                        <Flex gap={2}>
                          <Input
                            type="text"
                            value={newNotificationList.value}
                            onChange={(e) =>
                              setNewNotificationList({
                                ...newNotificationList,
                                value: e.target.value,
                              })
                            }
                          />
                          <Button
                            colorScheme="teal"
                            variant="outline"
                            size="md"
                            onClick={handleNotificationListAdd}
                          >
                            <Kbd>enter</Kbd>
                          </Button>
                        </Flex>
                        <Box my={2}>
                          {notificationList.map((group, index) => (
                            <Tag
                              key={`${group}-${index}`}
                              size="md"
                              mr={2}
                              mb={1}
                              borderRadius="full"
                              variant="solid"
                              colorScheme="teal"
                            >
                              <TagLabel>{group}</TagLabel>
                              <TagCloseButton
                                onClick={() =>
                                  handleNotificationListRemove(group)
                                }
                              />
                            </Tag>
                          ))}
                        </Box>
                        {newNotificationList.isNotificationListError ? (
                          <FormErrorMessage>
                            {newNotificationList.errorMessage}
                          </FormErrorMessage>
                        ) : (
                          <FormHelperText>
                            Specify the distribution lists or groups that have
                            the authority to modify this video sub-quest. Press
                            Enter after each entry to add it to the list. This
                            ensures proper access control.
                          </FormHelperText>
                        )}
                      </FormControl> */}
                    </GridItem>
                  </Grid>
                  <Divider />
                  <Box borderWidth="1px" borderRadius="md">
                    <Box w="100%" p={4}>
                      <Flex align="center">
                        <IconButton
                          variant="plain"
                          color="teal"
                          aria-label="Re-order quest"
                          icon={<DragHandleIcon />}
                        />
                        <Text fontWeight={500} mx={2} fontSize="md" flex={1}>
                          Questions
                        </Text>
                        <Button
                          variant="outline"
                          colorScheme="blue"
                          mr={3}
                          onClick={handleAddQuestion}
                        >
                          Add Question
                        </Button>
                      </Flex>
                    </Box>
                    <Collapse in>
                      {questions.map((question, index) => (
                        <Box p={4} key={uuidv4()}>
                          <Box mb={1} borderWidth="1px" borderRadius="md">
                            <Box w="100%">
                              <Flex align="center">
                                <IconButton
                                  variant="plain"
                                  color="teal"
                                  aria-label="Re-order quest"
                                  icon={<DragHandleIcon />}
                                />

                                <Box w="40%" p={4}>
                                  <Select
                                    value={question.questionTypeId}
                                    onChange={(e) =>
                                      handleQuestionTypeChange(
                                        index,
                                        question,
                                        e.target.value
                                      )
                                    }
                                  >
                                    {questionTypes.map((type) => (
                                      <option value={type.Id} key={uuidv4()}>
                                        {type.Name}
                                      </option>
                                    ))}
                                  </Select>
                                </Box>
                                <Spacer />
                                <Box p={4}>
                                  <IconButton
                                    isRound
                                    size="sm"
                                    icon={<IoMdClose />}
                                    onClick={() => handleRemoveQuestion(index)}
                                  />
                                </Box>
                              </Flex>
                            </Box>
                            <Divider />
                            <Collapse in>
                              <Box p={4}>
                                {question.questionTypeId === 1 && (
                                  <MultipleChoice
                                    key={uuidv4()}
                                    question={question}
                                    onUpdate={(updatedQuestion) =>
                                      handleUpdateQuestion(
                                        index,
                                        updatedQuestion
                                      )
                                    }
                                  />
                                )}
                                {question.questionTypeId === 2 ? (
                                  <TrueOrFalse
                                    key={uuidv4()}
                                    question={question}
                                    onUpdate={(updatedQuestion) =>
                                      handleUpdateQuestion(
                                        index,
                                        updatedQuestion
                                      )
                                    }
                                  />
                                ) : (
                                  <div>Short Answer</div>
                                )}
                              </Box>
                            </Collapse>
                          </Box>
                        </Box>
                      ))}
                    </Collapse>
                  </Box>
                </Stack>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outline"
                colorScheme="blue"
                mr={3}
                onClick={() => handleToggleUpdateModal(false)}
              >
                Close
              </Button>
              <Button
                isLoading={updateQuiz.status === ReducerStatus.LOADING}
                colorScheme="red"
                px={10}
                type="submit"
                onClick={() => handleUpdateSubQuest()}
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default UpdateAdminQuizSubQuestById;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';

import {
  Box,
  Text,
  Flex,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Thead,
  Tfoot,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Button,
  Link as ChakraLink,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import {
  SearchIcon,
  RepeatIcon,
  AddIcon,
  EditIcon,
  DeleteIcon,
} from '@chakra-ui/icons';

import { fetchCategoriesAction } from 'store/modules/category/category-actions';

import RowsPerPage from 'components/common/tables/RowsPerPage';
import TableNavigation from 'components/common/tables/TableNavigation';
import TableItemLoader from 'components/common/tables/TableItemLoader';
import CreateCategoryMd from 'components/category/modals/CreateCategoryMd';
import { categoryActions } from 'store/modules/category/category-slice';
import UpdateCategoryMd from 'components/category/modals/UpdateCategoryMd';
import DeleteCategoryMd from 'components/category/modals/DeleteCategoryMd';

function CategoryListPage() {
  const { accounts } = useMsal();
  const userEmail = accounts[0]?.idTokenClaims?.preferred_username;

  const dispatch = useDispatch();

  const categories = useSelector((state) => state.category.categories);
  const status = useSelector((state) => state.category.status);
  const metaData = useSelector((state) => state.category.metaData);

  const [selectedCategory, setSelectedCategory] = useState();
  const [paginationParams, setPaginationParams] = useState({
    query: '',
    currentPage: 1,
    pageSize: 10,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(fetchCategoriesAction(paginationParams));
  }, [paginationParams, dispatch]);

  const handleOnRefresh = () => {
    dispatch(fetchCategoriesAction(paginationParams));
  };

  const limitDescription = (description, limit = 50) => {
    if (description.length <= limit) return description;
    return `${description.slice(0, limit)}...`;
  };

  const handleParamsChange = (identifier, event) => {
    if (identifier === 'incrementCurrentPage') {
      setPaginationParams((prevParams) => ({
        ...prevParams,
        currentPage: prevParams.currentPage + 1,
      }));
    } else if (identifier === 'decrementCurrentPage') {
      if (paginationParams.currentPage > 1) {
        setPaginationParams((prevParams) => ({
          ...prevParams,
          currentPage: prevParams.currentPage - 1,
        }));
      }
    } else {
      setPaginationParams((prevValues) => ({
        ...prevValues,
        [identifier]: event.target.value,
      }));
    }
  };

  const openCategoryModal = (category) => {
    setSelectedCategory(category);
    onOpen();
  };

  const handleOpenCreateCategoryModal = () => {
    dispatch(categoryActions.toggleCreateModal(true));
  };

  const handleOpenUpdateCategoryModal = (category) => {
    dispatch(categoryActions.setSelectedCategory(category));
    dispatch(categoryActions.toggleUpdateModal(true));
  };

  const handleOpenDeleteCategoryModal = (category) => {
    dispatch(categoryActions.setSelectedCategory(category));
    dispatch(categoryActions.toggleDeleteModal(true));
  };

  return (
    <Box ml={{ base: 0, md: 20 }} p="10">
      <CreateCategoryMd />
      <UpdateCategoryMd />
      <DeleteCategoryMd />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedCategory?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{selectedCategory?.description}</ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex my={4} align="center">
        <Text flex={1} fontSize="2xl">
          Categories
        </Text>
        <Button
          colorScheme="red"
          leftIcon={<AddIcon />}
          onClick={() => handleOpenCreateCategoryModal()}
        >
          Create
        </Button>
      </Flex>

      <Box w="100%" p={4} color="gray.500" borderWidth="1px" borderRadius="lg">
        <Flex m={2} justify={{ base: 'center', md: 'end' }}>
          <IconButton
            aria-label="Reload table"
            variant="link"
            onClick={handleOnRefresh}
            icon={<RepeatIcon />}
          />
          <InputGroup width={{ base: '100%', md: '350px' }}>
            <InputRightElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputRightElement>
            <Input
              name="query"
              placeholder="Search"
              value={paginationParams.query}
              onChange={(event) => handleParamsChange('query', event)}
            />
          </InputGroup>
        </Flex>

        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th isNumeric>ID</Th>
                <Th>Category</Th>
                <Th>Description</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>

            <Tbody>
              {status === 'loading' ? (
                <TableItemLoader numberOfRows={5} numberOfColumns={4} />
              ) : (
                categories?.map((category) => (
                  <Tr key={category.id}>
                    <Td isNumeric>{category.id}</Td>
                    <Td>
                      <ChakraLink
                        color="blue.500"
                        onClick={() => openCategoryModal(category)}
                      >
                        {category.name}
                      </ChakraLink>
                    </Td>
                    <Td>{limitDescription(category.description)}</Td>
                    <Td>
                      {category.owner && category.owner.email === userEmail && (
                        <HStack spacing={4}>
                          <IconButton
                            colorScheme="blue"
                            aria-label="Update category"
                            icon={<EditIcon />}
                            onClick={() =>
                              handleOpenUpdateCategoryModal(category)
                            }
                          />
                          <IconButton
                            colorScheme="red"
                            aria-label="Delete category"
                            icon={<DeleteIcon />}
                            onClick={() =>
                              handleOpenDeleteCategoryModal(category)
                            }
                          />
                        </HStack>
                      )}
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>

            <Tfoot>
              <Tr>
                <Th isNumeric>ID</Th>
                <Th>Category</Th>
                <Th>Description</Th>
                <Th>Actions</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>

        <Flex m={2} justify={{ base: 'center', md: 'end' }} align="center">
          <RowsPerPage
            pageSize={paginationParams.pageSize}
            onChange={(event) => handleParamsChange('pageSize', event)}
          />
          <TableNavigation
            metaData={metaData}
            onNext={(event) =>
              handleParamsChange('incrementCurrentPage', event)
            }
            onPrevious={(event) =>
              handleParamsChange('decrementCurrentPage', event)
            }
          />
        </Flex>
      </Box>
    </Box>
  );
}

export default CategoryListPage;

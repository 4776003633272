import { createSlice } from '@reduxjs/toolkit';
import { ReducerStatus } from 'utils/constants';
import {
  fetchCoursesAction,
  fetchCourseByIdAction,
  deleteCourseByIdAction,
  fetchCourseCategoriesAction,
  createCourseAction,
  updateCourseAction,
} from './course-actions';

const courseSlice = createSlice({
  name: 'course',
  initialState: {
    errors: null,
    status: ReducerStatus.IDLE,
    getCourseStatus: ReducerStatus.IDLE,
    getCategoriesStatus: ReducerStatus.IDLE,
    createCourse: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateCourse: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    deleteCourseStatus: ReducerStatus.IDLE,
    deleteModal: false,
    createModal: false,
    error: null,
    courses: [],
    categories: [],
    course: null,
    metaData: null,
  },
  reducers: {
    toggleDeleteModal(state, action) {
      state.deleteModal = action.payload;
    },
    toggleCreateModal(state, action) {
      state.createCourse.modal = action.payload;
    },
    toggleUpdateModal(state, action) {
      state.updateCourse.modal = action.payload;
    },
    resetDeleteStatus(state) {
      state.deleteCourseStatus = ReducerStatus.IDLE;
    },
    resetCreateStatus(state) {
      state.createCourse.status = ReducerStatus.IDLE;
    },
    resetUpdateStatus(state) {
      state.updateCourse.status = ReducerStatus.IDLE;
    },
    resetCreateCourse(state) {
      state.createCourse = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    resetUpdateCourse(state) {
      state.updateCourse = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      // Fetch Courses
      .addCase(fetchCoursesAction.pending, (state) => {
        state.status = ReducerStatus.LOADING;
      })
      .addCase(fetchCoursesAction.fulfilled, (state, action) => {
        state.status = ReducerStatus.SUCCEEDED;
        const { metaData, data } = action.payload;
        state.courses = data;
        state.metaData = metaData;
      })
      // Fetch Course By ID
      .addCase(fetchCourseByIdAction.pending, (state) => {
        state.getCourseStatus = ReducerStatus.LOADING;
      })
      .addCase(fetchCourseByIdAction.fulfilled, (state, action) => {
        state.getCourseStatus = ReducerStatus.SUCCEEDED;
        state.course = action.payload;
      })
      .addCase(fetchCourseByIdAction.rejected, (state) => {
        state.getCourseStatus = ReducerStatus.FAILED;
      })
      // Delete Course By ID
      .addCase(deleteCourseByIdAction.pending, (state) => {
        state.deleteCourseStatus = ReducerStatus.LOADING;
      })
      .addCase(deleteCourseByIdAction.fulfilled, (state) => {
        state.deleteCourseStatus = ReducerStatus.SUCCEEDED;
      })
      .addCase(deleteCourseByIdAction.rejected, (state) => {
        state.deleteCourseStatus = ReducerStatus.FAILED;
      })
      // Fetch Categories
      .addCase(fetchCourseCategoriesAction.pending, (state) => {
        state.getCategoriesStatus = ReducerStatus.LOADING;
      })
      .addCase(fetchCourseCategoriesAction.fulfilled, (state, action) => {
        state.getCategoriesStatus = ReducerStatus.SUCCEEDED;
        state.categories = action.payload;
      })
      .addCase(fetchCourseCategoriesAction.rejected, (state) => {
        state.getCategoriesStatus = ReducerStatus.FAILED;
      })
      // Create Course
      .addCase(createCourseAction.pending, (state) => {
        state.createCourse.status = ReducerStatus.LOADING;
      })
      .addCase(createCourseAction.fulfilled, (state, action) => {
        state.createCourse.status = ReducerStatus.SUCCEEDED;
        state.courses = [...state.courses, action.payload];
        state.course = action.payload;
      })
      .addCase(createCourseAction.rejected, (state, action) => {
        state.createCourse.status = ReducerStatus.FAILED;
        state.createCourse.error = action.payload;
      })
      // Update Course
      .addCase(updateCourseAction.pending, (state) => {
        state.updateCourse.status = ReducerStatus.LOADING;
      })
      .addCase(updateCourseAction.fulfilled, (state, action) => {
        state.updateCourse.status = ReducerStatus.SUCCEEDED;
        state.courses = state.courses.map((course) =>
          course.id === action.payload.id
            ? { ...course, ...action.payload }
            : course
        );

        state.course = action.payload;
      })
      .addCase(updateCourseAction.rejected, (state, action) => {
        state.updateCourse.status = ReducerStatus.FAILED;
        state.updateCourse.error = action.payload;
      });
  },
});

export const courseActions = courseSlice.actions;

export default courseSlice;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Flex,
  Box,
  Input,
  Textarea,
  Stack,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';

import { updateQuestByIdAction } from 'store/modules/quest/quest-actions';
import { questActions } from 'store/modules/quest/quest-slice';

import { ReducerStatus } from 'utils/constants';

import useEmailsInput from 'hooks/useEmailsInput';

import ResultModal from 'components/common/modals/ResultModal';
import FormikFormControl from 'components/common/forms/FormikFormControl';
import MultiWordInput from 'components/common/forms/MultiWordInput';

function UpdateQuestMd() {
  const dispatch = useDispatch();

  const updateQuest = useSelector((state) => state.quest.updateQuest);
  const quest = useSelector((state) => state.quest.quest);

  const [updateFormInputs, setUpdateFormInputs] = useState({
    name: '',
    description: '',
  });

  const [isPublished, setIsPublished] = useState('false');

  const questFormValidation = Yup.object({
    name: Yup.string().max(155).required('Required'),
    description: Yup.string().max(1000).required('Required'),
  });
  const {
    emails,
    inputEmails,
    setEmails,
    handleEmailsChange,
    handleEmailRemove,
    handleEmailAdd,
    handleEmailKeyDown,
  } = useEmailsInput();

  const handleToggleUpdateModal = (isOpen) => {
    dispatch(questActions.toggleUpdateModal(isOpen));
  };

  const resetForm = () => {
    setUpdateFormInputs({
      name: '',
      description: '',
    });

    setEmails([]);
    setIsPublished('false');
  };

  const handleResultButton = () => {
    dispatch(questActions.resetUpdateQuest());
    if (updateQuest.status === ReducerStatus.SUCCEEDED) {
      resetForm();
      handleToggleUpdateModal(false);
    }
  };

  const handlePublishStatusChange = (e) => {
    setIsPublished(e);
  };

  const handleUpdateQuest = (formikObject) => {
    Object.entries(formikObject).forEach(([key, value]) => {
      setUpdateFormInputs((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
    });

    dispatch(
      updateQuestByIdAction({
        id: quest.id,
        name: formikObject.name,
        description: formikObject.description,
        isPublished: isPublished === 'true',
        ownerGroups: emails,
      })
    );
  };

  useEffect(() => {
    const resetAfterUpdate = () => {
      setUpdateFormInputs({
        name: '',
        description: '',
      });
      setEmails([]);
    };

    if (updateQuest.modal) {
      resetAfterUpdate();

      setUpdateFormInputs({
        name: quest?.name,
        description: quest?.description,
      });

      setIsPublished(quest?.isPublished.toString());

      const parsedGroups = JSON.parse(quest?.ownerGroups);
      if (
        Array.isArray(parsedGroups) &&
        parsedGroups.every((group) => typeof group === 'string')
      ) {
        setEmails(parsedGroups);
      }
    }
  }, [updateQuest.modal, quest, setEmails, dispatch]);

  return (
    <Box>
      {updateQuest.status === ReducerStatus.SUCCEEDED ||
      updateQuest.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={updateQuest.modal}
          onSubmit={handleResultButton}
          isError={updateQuest.status === ReducerStatus.FAILED}
          title={
            updateQuest.status === ReducerStatus.SUCCEEDED
              ? 'Quest Updated'
              : null
          }
          message={
            updateQuest.status === ReducerStatus.SUCCEEDED
              ? 'The quest has been updated successfully.'
              : updateQuest.error?.data?.title || 'Server Error'
          }
          errorObject={updateQuest.error?.data?.errors}
        />
      ) : (
        <Modal
          size="2xl"
          isOpen={updateQuest.modal}
          onClose={() => handleToggleUpdateModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Quest</ModalHeader>
            <ModalCloseButton />
            <Formik
              enableReinitialize
              initialValues={updateFormInputs}
              onSubmit={(values) => {
                handleUpdateQuest(values);
              }}
              validationSchema={questFormValidation}
            >
              {() => (
                <Form>
                  <ModalBody my={3}>
                    <VStack spacing={6} align="stretch">
                      <Flex
                        gap={5}
                        direction={{
                          base: 'column',
                          md: 'row',
                        }}
                      >
                        <Field flex={1} name="name">
                          {({ field, form }) => (
                            <FormikFormControl
                              label="Name"
                              helperText="Enter the name of the quest. Make it concise and descriptive."
                              errors={form.errors.name}
                              form={form}
                              touched={form.touched.name}
                            >
                              <Input {...field} />
                            </FormikFormControl>
                          )}
                        </Field>
                      </Flex>

                      <Field name="description">
                        {({ field, form }) => (
                          <FormikFormControl
                            label="Description"
                            helperText="Provide a detailed description of the quest. Mention the key objectives and what learners can expect to gain."
                            errors={form.errors.description}
                            touched={form.touched.description}
                          >
                            <Textarea {...field} />
                          </FormikFormControl>
                        )}
                      </Field>

                      <MultiWordInput
                        label="Owner Groups"
                        helperText="Specify the distribution lists, groups, or emails that have the authority to modify this course. Press Enter after each entry to add it to the list. This ensures proper access control."
                        newWordKeyName="newEmail"
                        newWord={inputEmails.newEmail}
                        words={emails}
                        isWordError={inputEmails.isEmailError}
                        errorMessage={inputEmails.errorMessage}
                        onWordChange={handleEmailsChange}
                        onWordAdd={handleEmailAdd}
                        onWordKeyDown={handleEmailKeyDown}
                        onWordRemove={handleEmailRemove}
                      />

                      <FormikFormControl
                        label="Published Status"
                        helperText="Choose between 'Draft' if the quest is still in progress and not readyfor public access, or 'Published' if the quest is ready for learners."
                      >
                        <RadioGroup
                          value={isPublished}
                          onChange={handlePublishStatusChange}
                        >
                          <Stack spacing={5} direction="row">
                            <Radio colorScheme="purple" value="false">
                              Draft
                            </Radio>
                            <Radio colorScheme="green" value="true">
                              Published
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </FormikFormControl>
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      variant="outline"
                      colorScheme="blue"
                      mr={3}
                      onClick={() => handleToggleUpdateModal(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="red"
                      px={10}
                      type="submit"
                      isLoading={updateQuest.status === ReducerStatus.LOADING}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default UpdateQuestMd;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const handleAsyncError = (error) => {
  if (error.response) {
    return error.response.data;
  }
  return error.message;
};

export const fetchCategoriesAction = createAsyncThunk(
  'categories/fetchCategories',
  async (params) => {
    const { query, currentPage, pageSize } = params;
    let apiRoute = `/admin/categories?CurrentPage=${currentPage}&PageSize=${pageSize}`;
    if (query) apiRoute += `&Query=${query}`;

    try {
      const response = await axios.get(apiRoute);
      return response.data;
    } catch (error) {
      throw handleAsyncError(error);
    }
  }
);

export const deleteCategoryByIdAction = createAsyncThunk(
  'categories/deleteCategoryById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/admin/categories/${id}`);
      return {
        deletedCategoryId: id,
        currentPage: response.data.currentPage,
        pageSize: response.data.pageSize,
      };
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const createCategoryAction = createAsyncThunk(
  'categories/createCategory',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/admin/categories', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateCategoryAction = createAsyncThunk(
  'categories/updateCategory',
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/admin/categories/${id}`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

import { useState } from 'react';

const useEmailsInput = () => {
  const [emails, setEmails] = useState([]);

  const [inputEmails, setInputEmails] = useState({
    newEmail: '',
    isEmailError: false,
    errorMessage: '',
  });

  const handleEmailsChange = (identifier, value) => {
    setInputEmails((prevValues) => ({
      ...prevValues,
      [identifier]: value,
    }));
  };

  const handleEmailRemove = (emailToRemove) => {
    setEmails((prevEmails) =>
      prevEmails.filter((email) => email !== emailToRemove)
    );
  };

  const handleEmailAdd = () => {
    const allowedDomains = ['trendmicro.com', 'dl.trendmicro.com'];
    const domainRegex = /@([a-zA-Z0-9.-]+)$/;
    const match = inputEmails.newEmail.match(domainRegex);

    if (match && match[1] && allowedDomains.includes(match[1])) {
      setEmails((prevEmails) => [...prevEmails, inputEmails.newEmail]);
      handleEmailsChange('isEmailError', false);
      handleEmailsChange('newEmail', '');
    } else {
      handleEmailsChange('isEmailError', true);
      handleEmailsChange(
        'errorMessage',
        'Email is not from an allowed domain. It must be one of the following: trendmicro.com, dl.trendmicro.com.'
      );
    }
  };

  const handleEmailKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleEmailAdd();
    } else {
      handleEmailsChange('isEmailError', false);
    }
  };

  return {
    emails,
    inputEmails,
    setEmails,
    handleEmailsChange,
    handleEmailRemove,
    handleEmailAdd,
    handleEmailKeyDown,
  };
};

export default useEmailsInput;

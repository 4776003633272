import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReducerStatus } from 'utils/constants';
import {
  IconButton,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Flex,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Collapse,
  Divider,
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Grid,
  GridItem,
  Select,
  NumberInput,
  NumberInputField,
  Switch,
  Stack,
  Spacer,
} from '@chakra-ui/react';

import { v4 as uuidv4 } from 'uuid';

import { DragHandleIcon } from '@chakra-ui/icons';
import { IoMdClose } from 'react-icons/io';
import { MdQuiz } from 'react-icons/md';

import ResultModal from 'components/common/modals/ResultModal';

import { Field, Form, Formik } from 'formik';
import useHandleForm from './hooks/useHandleForm';

import NameInput from './components/NameInput';
import CodeInput from './components/CodeInput';
import ObjectiveInput from './components/ObjectiveInput';
import TagsInput from './components/TagsInput';
import GroupsInput from './components/GroupsInput';
import PublishInput from './components/PublishInput';
import NotificationListsInput from './components/NotificationListInput';

import MultipleChoice from '../../../../../components/question/multipleChoice';
import TrueOrFalse from '../../../../../components/question/trueOrFalse';

import {
  createAdminQuizSubQuest,
  createSubQuestQuizQuestions,
} from '../../store/admin-subquest-action';
import { adminSubQuestActions } from '../../store/admin-subquest-slice';

function CreateAdminQuizSubQuest({ quest }) {
  const dispatch = useDispatch();
  const {
    tags,
    groups,
    notificationList,
    inputTagsGroups,
    courseFormValidation,
    setTags,
    handleTagsGroupsChange,
    handleTagRemove,
    handleGroupRemove,
    handleNotificationListRemove,
    handleGroupAdd,
    handleTagAdd,
    handleNotificationListAdd,
    handleTagKeyDown,
    handleGroupKeyDown,
    handleNotificationListKeyDown,
  } = useHandleForm();

  const steps = [
    { title: 'Step 1', description: 'Sub Quest' },
    { title: 'Step 2', description: 'Quiz' },
  ];

  const questionTypes = [
    { Name: 'Multiple Choice', Id: 1 },
    { Name: 'True or False', Id: 2 },
  ];

  const [timeBeforeNextAttempt, setTimeBeforeNextAttempt] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const handleTimeBeforeNextAttemptDaysChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setTimeBeforeNextAttempt({
      ...timeBeforeNextAttempt,
      days: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };
  const handleTimeBeforeNextAttemptHoursChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setTimeBeforeNextAttempt({
      ...timeBeforeNextAttempt,
      hours: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };
  const handleTimeBeforeNextAttemptMinutesChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setTimeBeforeNextAttempt({
      ...timeBeforeNextAttempt,
      minutes: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };

  const [quizDuration, setQuizDuration] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const handleQuizDurationHoursChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setQuizDuration({
      ...quizDuration,
      hours: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };
  const handleQuizDurationMinutesChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setQuizDuration({
      ...quizDuration,
      minutes: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };

  const handleQuizDurationSecondsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setQuizDuration({
      ...quizDuration,
      seconds: Number.isNaN(value) ? 0 : value, // Set default value if NaN
    });
  };

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [createFormInputs, setCreateFormInputs] = useState({
    name: '',
    objective: '',
    code: '',
    title: '',
    shuffleQuestions: false,
    showResult: false,
    unlimitedAttempts: false,
    fetchFromQuestionBank: false,
    enableNotification: false,
    notifyUser: false,
    notifyManager: false,
    numberOfAttempts: 1,
    passingScore: 1,
    scoreType: 'Actual Score',
    totalMark: 1,
    isShuffled: true,
    isAllOrNothing: false,
    showAll: false,
  });
  const [questions, setQuestions] = useState([]);
  const handleAddQuestion = () => {
    const newQuestion = {
      questionHtml: '',
      questionTypeId: 1,
      multipleChoiceChoices: [
        { option: 'A', value: 'A' },
        { option: 'B', value: 'B' },
      ],
      multipleChoiceAnswers: [],
      trueOrFalseAnswer: true,
      explanation: '',
      mark: 0,
    };
    setQuestions([...questions, newQuestion]);
  };
  const handleUpdateQuestion = (index, updatedQuestion) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = updatedQuestion;
    setQuestions(updatedQuestions);
  };

  const handleQuestionTypeChange = (index, question, newQuestionTypeId) => {
    const updatedQuestion = {
      ...question,
      questionTypeId: parseInt(newQuestionTypeId, 10),
    };
    handleUpdateQuestion(index, updatedQuestion);
  };

  const handleRemoveQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const [isPublished, setIsPublished] = useState('false');

  const convertQuizDurationToSeconds = ({ hours, minutes, seconds }) => {
    const hoursInSeconds = hours * 3600;
    const minutesInSeconds = minutes * 60;
    return hoursInSeconds + minutesInSeconds + seconds;
  };

  const convertTimeBeforeNextAttemptToSeconds = ({ days, hours, minutes }) => {
    const daysInSeconds = days * 24 * 60 * 60;
    const hoursInSeconds = hours * 60 * 60;
    const minutesInSeconds = minutes * 60;
    return daysInSeconds + hoursInSeconds + minutesInSeconds;
  };

  const handleCreateSubQuest = async (formikObject) => {
    const quizDurationTime = convertQuizDurationToSeconds(quizDuration);
    const timeBeforeNextAttemptTime = convertTimeBeforeNextAttemptToSeconds(
      timeBeforeNextAttempt
    );

    const quizSubQuest = formikObject;

    quizSubQuest.tags = tags;
    quizSubQuest.ownerGroups = groups;
    quizSubQuest.notificationList = notificationList;
    quizSubQuest.isPublished = isPublished === 'true';
    quizSubQuest.time = quizDurationTime;
    quizSubQuest.timeBeforeNextAttempt = timeBeforeNextAttemptTime;
    quizSubQuest.quizTitle = formikObject.title;
    quizSubQuest.numberOfAttempts = parseFloat(formikObject.numberOfAttempts);
    quizSubQuest.passingScore = parseFloat(formikObject.passingScore);

    const newQuizSubQuest = await dispatch(
      createAdminQuizSubQuest({
        questId: quest.id,
        quizSubQuest,
      })
    );
    if (quizSubQuest.fetchFromQuestionBank === false) {
      const createdSubQuestQuizId =
        newQuizSubQuest.payload.subQuest.subQuest.subQuestQuiz.id;
      questions.forEach((question, index) => {
        const updatedQuestion = question;
        updatedQuestion.SubQuestQuizId = createdSubQuestQuizId;
        handleUpdateQuestion(index, updatedQuestion);
      });
      dispatch(createSubQuestQuizQuestions({ questions }));
    }
  };

  const handlePublishStatusChange = (e) => {
    setIsPublished(e);
  };

  const createQuiz = useSelector((state) => state.adminSubQuest.createQuiz);

  const handleResult = () => {
    if (createQuiz.status === ReducerStatus.SUCCEEDED) {
      setCreateFormInputs({
        name: '',
        objective: '',
        code: '',
      });
      onClose();
      setTags([]);
      onClose();
    }
    dispatch(adminSubQuestActions.resetCreateQuiz());
  };

  return (
    <Box>
      {createQuiz.status === ReducerStatus.SUCCEEDED ||
      createQuiz.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={isOpen}
          onSubmit={handleResult}
          isError={createQuiz.status === ReducerStatus.FAILED}
          title={
            createQuiz.status === ReducerStatus.SUCCEEDED
              ? 'Quiz Sub-Quest Created'
              : null
          }
          message={
            createQuiz.status === ReducerStatus.SUCCEEDED
              ? 'The Quiz sub-quest has been created successfully.'
              : createQuiz.error?.data?.title || 'Server Error'
          }
          errorObject={createQuiz.error?.data?.errors}
        />
      ) : (
        <>
          <Button
            onClick={onOpen}
            w="100%"
            colorScheme="red"
            size="lg"
            leftIcon={<MdQuiz />}
          >
            Quiz
          </Button>
          <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create Quiz Sub-Quest</ModalHeader>
              <ModalCloseButton />
              <Formik
                enableReinitialize={false}
                initialValues={createFormInputs}
                onSubmit={(values) => {
                  handleCreateSubQuest(values);
                }}
                validationSchema={courseFormValidation}
              >
                {(props) => (
                  <Form>
                    <ModalBody>
                      <Text fontSize="md" mb={5}>
                        You are currently adding a sub-quest to this
                        quest:&nbsp;
                        <Text as="span" fontWeight={550}>
                          {quest.name}
                        </Text>
                      </Text>
                      <Stepper size="lg" index={activeStep}>
                        {steps.map((step, index) => (
                          <Step
                            key={uuidv4()}
                            onClick={() => setActiveStep(index)}
                          >
                            <StepIndicator>
                              <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                              />
                            </StepIndicator>

                            <Box flexShrink="0">
                              <StepTitle>{step.title}</StepTitle>
                              <StepDescription>
                                {step.description}
                              </StepDescription>
                            </Box>

                            <StepSeparator />
                          </Step>
                        ))}
                      </Stepper>

                      {activeStep === 0 ? (
                        <VStack spacing={6} align="stretch">
                          <Flex
                            gap={5}
                            direction={{
                              base: 'column',
                              md: 'row',
                            }}
                          >
                            <Field flex={1} name="name">
                              {({ field, form }) => (
                                <NameInput field={field} form={form} />
                              )}
                            </Field>
                            <Box
                              w={{
                                base: '100%',
                                md: '380px',
                              }}
                            >
                              <Field name="code">
                                {({ field, form }) => (
                                  <CodeInput field={field} form={form} />
                                )}
                              </Field>
                            </Box>
                          </Flex>

                          <Field name="objective">
                            {({ field, form }) => (
                              <ObjectiveInput field={field} form={form} />
                            )}
                          </Field>
                          <Divider />
                          <TagsInput
                            newTag={inputTagsGroups.newTag}
                            tags={tags}
                            isTagError={inputTagsGroups.isTagError}
                            errorMessage={inputTagsGroups.errorMessage}
                            onTagChange={handleTagsGroupsChange}
                            onTagAdd={handleTagAdd}
                            onTagKeyDown={handleTagKeyDown}
                            onTagRemove={handleTagRemove}
                          />

                          <GroupsInput
                            newGroup={inputTagsGroups.newGroup}
                            groups={groups}
                            isGroupError={inputTagsGroups.isGroupError}
                            errorMessage={inputTagsGroups.errorMessage}
                            onGroupChange={handleTagsGroupsChange}
                            onGroupAdd={handleGroupAdd}
                            onGroupKeyDown={handleGroupKeyDown}
                            onGroupRemove={handleGroupRemove}
                          />

                          <PublishInput
                            isPublished={isPublished}
                            handleChange={handlePublishStatusChange}
                          />
                        </VStack>
                      ) : (
                        <Stack>
                          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                            <GridItem colSpan={2}>
                              <Field name="title">
                                {({ field, form }) => (
                                  <FormControl
                                    isInvalid={
                                      form.errors.title && form.touched.title
                                    }
                                  >
                                    <FormLabel>Title</FormLabel>
                                    <Input {...field} />

                                    {form.errors.title && (
                                      <FormErrorMessage>
                                        {form.errors.title}
                                      </FormErrorMessage>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                              <Flex mt={2}>
                                <Box>
                                  <FormControl>
                                    <FormLabel>Shuffle Questions</FormLabel>
                                    <Switch
                                      size="lg"
                                      onChange={() =>
                                        props.setFieldValue(
                                          'shuffleQuestions',
                                          !props.values.shuffleQuestions
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Box>
                                <Box>
                                  <FormControl>
                                    <FormLabel>Show Result</FormLabel>
                                    <Switch
                                      size="lg"
                                      onChange={() =>
                                        props.setFieldValue(
                                          'showResult',
                                          !props.values.showResult
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Box>
                              </Flex>
                              <Flex align="end">
                                <Box>
                                  <FormControl>
                                    <FormLabel>Unlimited Attempts</FormLabel>
                                    <Switch
                                      size="lg"
                                      onChange={() =>
                                        props.setFieldValue(
                                          'unlimitedAttempts',
                                          !props.values.unlimitedAttempts
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Box>
                                <Box mr={4}>
                                  <Field name="numberOfAttempts">
                                    {({ field, form }) => (
                                      <FormControl
                                        isInvalid={
                                          form.errors.numberOfAttempts &&
                                          form.touched.numberOfAttempts
                                        }
                                      >
                                        <FormLabel>No. of Attempts</FormLabel>
                                        <Input
                                          min={0}
                                          max={10000}
                                          {...field}
                                          type="number"
                                        />

                                        {form.errors.numberOfAttempts && (
                                          <FormErrorMessage>
                                            {form.errors.numberOfAttempts}
                                          </FormErrorMessage>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                                <Box mt={2}>
                                  <Flex>
                                    <Text fontWeight="medium">
                                      Time before next attempt
                                    </Text>
                                  </Flex>
                                  <Flex>
                                    <Box w="70px" mr={1}>
                                      <FormControl>
                                        <FormLabel>Days</FormLabel>
                                        <NumberInput
                                          min={0}
                                          max={364}
                                          defaultValue={
                                            timeBeforeNextAttempt.days
                                          }
                                        >
                                          <NumberInputField
                                            value={timeBeforeNextAttempt.days}
                                            onChange={
                                              handleTimeBeforeNextAttemptDaysChange
                                            }
                                          />
                                        </NumberInput>
                                      </FormControl>
                                    </Box>
                                    <Box w="70px" mr={1}>
                                      <FormControl>
                                        <FormLabel>Hours</FormLabel>
                                        <NumberInput
                                          min={0}
                                          max={23}
                                          defaultValue={
                                            timeBeforeNextAttempt.hours
                                          }
                                        >
                                          <NumberInputField
                                            value={timeBeforeNextAttempt.hours}
                                            onChange={
                                              handleTimeBeforeNextAttemptHoursChange
                                            }
                                          />
                                        </NumberInput>
                                      </FormControl>
                                    </Box>
                                    <Box w="70px" mr={1}>
                                      <FormControl>
                                        <FormLabel>Min</FormLabel>
                                        <NumberInput
                                          min={0}
                                          max={59}
                                          defaultValue={
                                            timeBeforeNextAttempt.hours
                                          }
                                        >
                                          <NumberInputField
                                            value={
                                              timeBeforeNextAttempt.minutes
                                            }
                                            onChange={
                                              handleTimeBeforeNextAttemptMinutesChange
                                            }
                                          />
                                        </NumberInput>
                                      </FormControl>
                                    </Box>
                                  </Flex>
                                </Box>
                              </Flex>
                              <Flex mt={2}>
                                <Box>
                                  <FormControl>
                                    <FormLabel>Question Bank</FormLabel>
                                    <Switch
                                      size="lg"
                                      onChange={() =>
                                        props.setFieldValue(
                                          'fetchFromQuestionBank',
                                          !props.values.fetchFromQuestionBank
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Box>
                                <Box>
                                  <Field name="totalMark">
                                    {({ field, form }) => (
                                      <FormControl
                                        isInvalid={
                                          form.errors.totalMark &&
                                          form.touched.totalMark
                                        }
                                      >
                                        <FormLabel>Total Mark</FormLabel>
                                        <NumberInput
                                          min={0}
                                          max={10000}
                                          {...field}
                                          isDisabled
                                        >
                                          <NumberInputField />
                                        </NumberInput>

                                        {form.errors.totalMark && (
                                          <FormErrorMessage>
                                            {form.errors.totalMark}
                                          </FormErrorMessage>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                              </Flex>
                            </GridItem>
                            <GridItem colSpan={2}>
                              <Flex>
                                <Box mr={4}>
                                  <FormControl>
                                    <FormLabel>Score Setting</FormLabel>
                                    <Select>
                                      <option value="Actual Score">
                                        Actual Score
                                      </option>
                                      <option value="Percentage">
                                        Percentage
                                      </option>
                                    </Select>
                                  </FormControl>
                                </Box>
                                <Box>
                                  <Field name="passingScore">
                                    {({ field, form }) => (
                                      <FormControl
                                        isInvalid={
                                          form.errors.passingScore &&
                                          form.touched.passingScore
                                        }
                                      >
                                        <FormLabel>Passing Score</FormLabel>
                                        <Input
                                          min={0}
                                          max={10000}
                                          {...field}
                                          type="number"
                                        />

                                        {form.errors.passingScore && (
                                          <FormErrorMessage>
                                            {form.errors.passingScore}
                                          </FormErrorMessage>
                                        )}
                                      </FormControl>
                                    )}
                                  </Field>
                                </Box>
                              </Flex>
                              <Flex mt={2}>
                                <Box>
                                  <Flex>
                                    <Text fontWeight="medium">
                                      Quiz Duration
                                    </Text>
                                  </Flex>
                                  <Flex>
                                    <Box w="70px" mr={1}>
                                      <FormControl>
                                        <FormLabel>Hours</FormLabel>
                                        <NumberInput
                                          min={0}
                                          max={23}
                                          defaultValue={quizDuration.hours}
                                        >
                                          <NumberInputField
                                            value={quizDuration.hours}
                                            onChange={
                                              handleQuizDurationHoursChange
                                            }
                                          />
                                        </NumberInput>
                                      </FormControl>
                                    </Box>
                                    <Box w="70px" mr={1}>
                                      <FormControl>
                                        <FormLabel>Min</FormLabel>
                                        <NumberInput
                                          min={0}
                                          max={59}
                                          defaultValue={quizDuration.minutes}
                                        >
                                          <NumberInputField
                                            value={quizDuration.minutes}
                                            onChange={
                                              handleQuizDurationMinutesChange
                                            }
                                          />
                                        </NumberInput>
                                      </FormControl>
                                    </Box>
                                    <Box w="70px" mr={1}>
                                      <FormControl>
                                        <FormLabel>Sec</FormLabel>
                                        <NumberInput
                                          min={0}
                                          max={59}
                                          defaultValue={quizDuration.seconds}
                                        >
                                          <NumberInputField
                                            value={quizDuration.seconds}
                                            onChange={
                                              handleQuizDurationSecondsChange
                                            }
                                          />
                                        </NumberInput>
                                      </FormControl>
                                    </Box>
                                  </Flex>
                                </Box>
                              </Flex>
                              <Flex mt={2}>
                                <Box>
                                  <FormControl>
                                    <FormLabel>Enable Notification</FormLabel>
                                    <Switch
                                      size="lg"
                                      onChange={() =>
                                        props.setFieldValue(
                                          'enableNotification',
                                          !props.values.enableNotification
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Box>
                              </Flex>
                              <Flex mt={2} mb={2}>
                                <Box>
                                  <FormControl>
                                    <FormLabel>Notify User</FormLabel>
                                    <Switch
                                      size="lg"
                                      onChange={() =>
                                        props.setFieldValue(
                                          'notifyUser',
                                          !props.values.notifyUser
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Box>
                                <Box>
                                  <FormControl>
                                    <FormLabel>Notify Manager</FormLabel>
                                    <Switch
                                      size="lg"
                                      onChange={() =>
                                        props.setFieldValue(
                                          'notifyManager',
                                          !props.values.notifyManager
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Box>
                              </Flex>
                              <NotificationListsInput
                                newNotificationList={
                                  inputTagsGroups.newNotificationList
                                }
                                notificationList={notificationList}
                                isNotificationListError={
                                  inputTagsGroups.isNotificationListError
                                }
                                errorMessage={inputTagsGroups.errorMessage}
                                onNotificationListChange={
                                  handleTagsGroupsChange
                                }
                                onNotificationListAdd={
                                  handleNotificationListAdd
                                }
                                onNotificationListKeyDown={
                                  handleNotificationListKeyDown
                                }
                                onNotificationListRemove={
                                  handleNotificationListRemove
                                }
                              />
                            </GridItem>
                          </Grid>
                          <Divider />
                          <Box borderWidth="1px" borderRadius="md">
                            <Box w="100%" p={4}>
                              <Flex align="center">
                                <IconButton
                                  variant="plain"
                                  color="teal"
                                  aria-label="Re-order quest"
                                  icon={<DragHandleIcon />}
                                />
                                <Text
                                  fontWeight={500}
                                  mx={2}
                                  fontSize="md"
                                  flex={1}
                                >
                                  Questions
                                </Text>
                                <Button
                                  variant="outline"
                                  colorScheme="blue"
                                  mr={3}
                                  onClick={handleAddQuestion}
                                >
                                  Add Question
                                </Button>
                              </Flex>
                            </Box>
                            <Collapse in>
                              {questions.map((question, index) => (
                                <Box p={4} key={uuidv4()}>
                                  <Box
                                    mb={1}
                                    borderWidth="1px"
                                    borderRadius="md"
                                  >
                                    <Box w="100%">
                                      <Flex align="center">
                                        <IconButton
                                          variant="plain"
                                          color="teal"
                                          aria-label="Re-order quest"
                                          icon={<DragHandleIcon />}
                                        />

                                        <Box w="40%" p={4}>
                                          <Select
                                            value={question.questionTypeId}
                                            onChange={(e) =>
                                              handleQuestionTypeChange(
                                                index,
                                                question,
                                                e.target.value
                                              )
                                            }
                                          >
                                            {questionTypes.map((type) => (
                                              <option
                                                value={type.Id}
                                                key={uuidv4()}
                                              >
                                                {type.Name}
                                              </option>
                                            ))}
                                          </Select>
                                        </Box>
                                        <Spacer />
                                        <Box p={4}>
                                          <IconButton
                                            isRound
                                            size="sm"
                                            icon={<IoMdClose />}
                                            onClick={() =>
                                              handleRemoveQuestion(index)
                                            }
                                          />
                                        </Box>
                                      </Flex>
                                    </Box>
                                    <Divider />
                                    <Collapse in>
                                      <Box p={4}>
                                        {question.questionTypeId === 1 && (
                                          <MultipleChoice
                                            key={uuidv4()}
                                            question={question}
                                            onUpdate={(updatedQuestion) =>
                                              handleUpdateQuestion(
                                                index,
                                                updatedQuestion
                                              )
                                            }
                                          />
                                        )}

                                        {question.questionTypeId === 2 ? (
                                          <TrueOrFalse
                                            key={uuidv4()}
                                            question={question}
                                            onUpdate={(updatedQuestion) =>
                                              handleUpdateQuestion(
                                                index,
                                                updatedQuestion
                                              )
                                            }
                                          />
                                        ) : (
                                          <div>Short Answer</div>
                                        )}
                                      </Box>
                                    </Collapse>
                                  </Box>
                                </Box>
                              ))}
                            </Collapse>
                          </Box>
                        </Stack>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        variant="outline"
                        colorScheme="blue"
                        mr={3}
                        onClick={onClose}
                      >
                        Close
                      </Button>
                      {activeStep === 0 ? (
                        <Button
                          variant="outline"
                          colorScheme="blue"
                          mr={3}
                          onClick={() => setActiveStep(1)}
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          variant="outline"
                          colorScheme="blue"
                          mr={3}
                          onClick={() => setActiveStep(0)}
                        >
                          Back
                        </Button>
                      )}
                      <Button
                        colorScheme="red"
                        px={10}
                        type="submit"
                        isLoading={createQuiz.status === ReducerStatus.LOADING}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
}

CreateAdminQuizSubQuest.defaultProps = {
  values: null,
  setFieldValue: () => {},
};

CreateAdminQuizSubQuest.propTypes = {
  quest: PropTypes.object.isRequired,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
};

export default CreateAdminQuizSubQuest;

import { Outlet } from 'react-router-dom';

import {
  Box,
  useDisclosure,
  Drawer,
  useColorModeValue,
  DrawerContent,
} from '@chakra-ui/react';

import UpdateAdminVideoSubQuestById from 'modules/admin/subQuest/features/updateAdminVideoSubQuestById';
import DeleteAdminSubQuestById from 'modules/admin/subQuest/features/deleteAdminSubQuestById';

import UpdateAdminQuizSubQuestById from 'modules/admin/subQuest/features/updateAdminQuizSubQuestById';
import CreateAdminLearningPlan from 'modules/admin/learningPlan/features/createAdminLearningPlan';
import UpdateAdminLearningPlanById from 'modules/admin/learningPlan/features/updateAdminLearningPlanById';
import DeleteAdminLearningPlanById from 'modules/admin/learningPlan/features/deleteAdminLearningPlanById';
import RemoveAdminLearningPlanCourseById from 'modules/admin/learningPlanCourse/features/removeAdminLearningPlanCourseById';
import CreateAdminGrowthStage from 'modules/admin/growthStage/features/createAdminGrowthStage';
import UpdateAdminGrowthStageById from 'modules/admin/growthStage/features/updateAdminGrowthStage';
import DeleteAdminGrowthStageById from 'modules/admin/growthStage/features/deleteAdminGrowthStageById';

import Companion from 'companion';
import useToastNotif from 'hooks/useToastNotif';
import MobileNav from 'components/common/sidebar/MobileNav';
import SidebarContent from 'components/common/sidebar/SidebarContent';
import Header from 'components/common/header';
import Footer from 'components/common/footer';

function RootLayout() {
  useToastNotif();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Companion />

      {/* Modals */}
      <CreateAdminLearningPlan />
      <UpdateAdminLearningPlanById />
      <DeleteAdminLearningPlanById />
      <RemoveAdminLearningPlanCourseById />

      <CreateAdminGrowthStage />
      <UpdateAdminGrowthStageById />
      <DeleteAdminGrowthStageById />

      <UpdateAdminVideoSubQuestById />
      <DeleteAdminSubQuestById />

      <UpdateAdminQuizSubQuestById />

      {/*  */}
      <Header />
      <Box minH="100vh" bg={useColorModeValue('white', 'gray.900')}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>

        <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
        <Box h="full">
          <Outlet />
          <Footer />
        </Box>
      </Box>
    </>
  );
}

export default RootLayout;

import { createSlice } from '@reduxjs/toolkit';
import { ReducerStatus } from 'utils/constants';
import {
  fetchProgressionByCourseIdAction,
  postProgressionAction,
} from './progression-actions';

const progressionSlice = createSlice({
  name: 'progression',
  initialState: {
    getProgressionStatus: ReducerStatus.IDLE,
    postProgressionStatus: ReducerStatus.IDLE,
    totalSubQuestCount: 0,
    progressions: [],
    courseOutlines: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      // Fetch Progression By CourseId
      .addCase(fetchProgressionByCourseIdAction.pending, (state) => {
        state.getProgressionStatus = ReducerStatus.LOADING;
      })
      .addCase(fetchProgressionByCourseIdAction.fulfilled, (state, action) => {
        state.getProgressionStatus = ReducerStatus.SUCCEEDED;
        const { totalSubQuestCount, progressions, outlines } = action.payload;
        state.totalSubQuestCount = totalSubQuestCount;
        state.progressions = progressions;
        state.courseOutlines = outlines;
      })
      .addCase(fetchProgressionByCourseIdAction.rejected, (state) => {
        state.getProgressionStatus = ReducerStatus.FAILED;
      })
      // Post Progression;
      .addCase(postProgressionAction.pending, (state) => {
        state.postProgressionStatus = ReducerStatus.LOADING;
      })
      .addCase(postProgressionAction.fulfilled, (state, action) => {
        state.postProgressionStatus = ReducerStatus.SUCCEEDED;
        const { id, ...rest } = action.payload;

        const existingItemIndex = state.progressions.findIndex(
          (item) => item.id === id
        );
        const updatedItems = [...state.progressions];

        if (existingItemIndex === -1) updatedItems.push({ id, ...rest });
        else updatedItems[existingItemIndex] = { id, ...rest };

        state.progressions = updatedItems;
      })
      .addCase(postProgressionAction.rejected, (state) => {
        state.postProgressionStatus = ReducerStatus.FAILED;
      });
  },
});

export const progressionActions = progressionSlice.actions;

export const getProgressionPercentage = (state) => {
  if (state.progression.progressions.length === 0) return 0;

  return (
    (state.progression.progressions.filter((p) => p.dateFinished).length /
      state.progression.totalSubQuestCount) *
      100 || 0
  );
};

export default progressionSlice;

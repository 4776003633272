import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getSubQuestsByQuestId = async (questId) => {
  try {
    const response = await axios.get(`/admin/quests/${questId}/sub-quests`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getSubQuestById = createAsyncThunk(
  'adminSubQuest/getSubQuestById',
  async ({ questId, subQuestId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/admin/quests/${questId}/sub-quests/${subQuestId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const createAdminVideoSubQuest = createAsyncThunk(
  'adminSubQuest/createAdminVideoSubQuest',
  async ({ id, formData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `admin/quests/${id}/sub-quests/videos`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            let percentComplete = progressEvent.loaded / progressEvent.total;
            percentComplete = parseInt(percentComplete * 100, 10);
            dispatch({
              type: 'adminSubQuest/setUploadProgress',
              payload: percentComplete,
            });
          },
        }
      );

      dispatch({
        type: 'adminSubQuest/setUploadProgress',
        payload: 0,
      });

      return {
        id,
        subQuest: response.data,
      };
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const createAdminInteractiveVideoSubQuest = createAsyncThunk(
  'adminSubQuest/createAdminInteractiveVideoSubQuest',
  async ({ id, formData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `admin/quests/${id}/sub-quests/interactive-videos`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            let percentComplete = progressEvent.loaded / progressEvent.total;
            percentComplete = parseInt(percentComplete * 100, 10);
            dispatch({
              type: 'adminSubQuest/setUploadProgress',
              payload: percentComplete,
            });
          },
        }
      );

      dispatch({
        type: 'adminSubQuest/setUploadProgress',
        payload: 0,
      });

      return {
        id,
        subQuest: response.data,
      };
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const createAdminQuizSubQuest = createAsyncThunk(
  'adminSubQuest/createAdminQuizSubQuest',
  async ({ questId, quizSubQuest }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `admin/quests/${questId}/sub-quests/quizzes`,
        quizSubQuest,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return {
        id: questId,
        subQuest: response.data,
      };
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateAdminVideoSubQuest = createAsyncThunk(
  'adminSubQuest/updateAdminVideoSubQuest',
  async ({ questId, subQuestId, formData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(
        `admin/quests/${questId}/sub-quests/videos/${subQuestId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            let percentComplete = progressEvent.loaded / progressEvent.total;
            percentComplete = parseInt(percentComplete * 100, 10);
            dispatch({
              type: 'adminSubQuest/setUploadProgress',
              payload: percentComplete,
            });
          },
        }
      );

      dispatch({
        type: 'adminSubQuest/setUploadProgress',
        payload: 0,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateAdminInteractiveVideoSubQuest = createAsyncThunk(
  'adminSubQuest/updateAdminInteractiveVideoSubQuest',
  async ({ questId, subQuestId, formData }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(
        `admin/quests/${questId}/sub-quests/interactive-videos/${subQuestId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            let percentComplete = progressEvent.loaded / progressEvent.total;
            percentComplete = parseInt(percentComplete * 100, 10);
            dispatch({
              type: 'adminSubQuest/setUploadProgress',
              payload: percentComplete,
            });
          },
        }
      );

      dispatch({
        type: 'adminSubQuest/setUploadProgress',
        payload: 0,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateAdminQuizSubQuest = createAsyncThunk(
  'adminSubQuest/updateAdminQuizSubQuest',
  async ({ questId, subQuestId, quizSubQuest }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `admin/quests/${questId}/sub-quests/quizzes/${subQuestId}`,
        quizSubQuest,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const removeAdminSubQuest = createAsyncThunk(
  'adminSubQuest/removeAdminSubQuest',
  async ({ questId, subQuestId }, { rejectWithValue }) => {
    try {
      await axios.delete(`/admin/quests/${questId}/sub-quests/${subQuestId}`);
      return subQuestId;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateAdminSubQuestOrderNo = createAsyncThunk(
  'adminSubQuest/updateAdminSubQuestOrderNo',
  async ({ questId, subQuestId, newOrderNo }, { rejectWithValue }) => {
    try {
      await axios.put(
        `/admin/quests/${questId}/sub-quests/${subQuestId}`,
        newOrderNo,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return true;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const createSubQuestQuizQuestions = createAsyncThunk(
  'adminSubQuest/createSubQuestQuizQuestions',
  async ({ questions }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`admin/questions`, questions, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return {
        questions: response.data,
      };
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

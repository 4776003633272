import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Flex,
  Box,
  Input,
  Select,
  Textarea,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';

import {
  updateCourseAction,
  fetchCourseCategoriesAction,
} from 'store/modules/course/course-actions';
import { courseActions } from 'store/modules/course/course-slice';

import { ReducerStatus } from 'utils/constants';

import useFileInput from 'hooks/useFileInput';
import useEmailsInput from 'hooks/useEmailsInput';
import useTagsInput from 'hooks/useTagsInput';

import ResultModal from 'components/common/modals/ResultModal';
import FormikFormControl from 'components/common/forms/FormikFormControl';
import ImageUploadInput from 'components/common/forms/ImageUploadInput';
import MultiWordInput from 'components/common/forms/MultiWordInput';

function UpdateCourseMd() {
  // Before using this component, you must have a value from modules/course/course state
  const dispatch = useDispatch();

  const categories = useSelector((state) => state.course.categories);
  const updateCourse = useSelector((state) => state.course.updateCourse);
  const course = useSelector((state) => state.course.course);

  const [updateFormInputs, setUpdateFormInputs] = useState({
    name: '',
    description: '',
    code: '',
    categoryId: '',
  });

  const [isPublished, setIsPublished] = useState('false');

  const courseFormValidation = Yup.object({
    name: Yup.string().max(155).required('Required'),
    description: Yup.string().max(1000).required('Required'),
    categoryId: Yup.string().required('Required'),
    code: Yup.string().min(1).max(20).required('Required'),
  });

  const {
    file,
    fileURL,
    fileError,
    fileInputRef,
    setFile,
    handleAttachFile,
    handleRemoveSelectedFile,
    handleFileInputChange,
  } = useFileInput({ maxFileSizeMb: 5, fileType: 'image/' });

  const {
    tags,
    inputTags,
    setTags,
    handleTagsChange,
    handleTagRemove,
    handleTagAdd,
    handleTagKeyDown,
  } = useTagsInput();

  const {
    emails,
    inputEmails,
    setEmails,
    handleEmailsChange,
    handleEmailRemove,
    handleEmailAdd,
    handleEmailKeyDown,
  } = useEmailsInput();

  useEffect(() => {
    const resetForm = () => {
      setUpdateFormInputs({
        name: '',
        description: '',
        code: '',
        categoryId: '',
      });

      setFile({});
      setTags([]);
      setEmails([]);
    };

    if (updateCourse.modal) {
      dispatch(fetchCourseCategoriesAction());
      resetForm();

      setUpdateFormInputs({
        name: course?.name,
        code:
          course?.code !== null && course?.code !== undefined
            ? course?.code
            : '',
        description: course?.description,
        categoryId: course?.category?.id,
      });

      setIsPublished(course?.isPublished.toString());

      const parsedTags = JSON.parse(course?.tags);

      if (
        Array.isArray(parsedTags) &&
        parsedTags.every((tag) => typeof tag === 'string')
      ) {
        setTags(parsedTags);
      }

      const parsedEmails = JSON.parse(course?.ownerGroups);
      if (
        Array.isArray(parsedEmails) &&
        parsedEmails.every((group) => typeof group === 'string')
      ) {
        setEmails(parsedEmails);
      }
    }
  }, [updateCourse.modal, course, setEmails, setTags, setFile, dispatch]);

  const handleToggleUpdateModal = (isOpen) => {
    dispatch(courseActions.toggleUpdateModal(isOpen));
  };

  const resetForm = () => {
    setUpdateFormInputs({
      name: '',
      description: '',
      code: '',
      categoryId: '',
    });

    handleRemoveSelectedFile();
    setTags([]);
    setEmails([]);
  };

  const handleResultButton = () => {
    dispatch(courseActions.resetUpdateStatus());
    if (updateCourse.status === ReducerStatus.SUCCEEDED) {
      resetForm();
      handleToggleUpdateModal(false);
    }
  };

  const handlePublishStatusChange = (e) => {
    setIsPublished(e);
  };

  const handleUpdateCourse = (formikObject) => {
    const formData = new FormData();
    Object.entries(formikObject).forEach(([key, value]) => {
      setUpdateFormInputs((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
      formData.append(key, value);
    });
    tags.forEach((tag) => formData.append('Tags', tag));
    emails.forEach((group) => formData.append('OwnerGroups', group));

    if (file && file.name) {
      formData.append('Thumbnail', file, file.name);
    }

    formData.append('IsPublished', isPublished);

    if (course?.thumbnail && file?.name) {
      formData.append('IsRemoveThumbnail', 'true');
    }

    dispatch(updateCourseAction({ id: course?.id, formData, resetForm }));
  };
  return (
    <Box>
      {updateCourse.status === ReducerStatus.SUCCEEDED ||
      updateCourse.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={updateCourse.modal}
          onSubmit={handleResultButton}
          isError={updateCourse.status === ReducerStatus.FAILED}
          title={
            updateCourse.status === ReducerStatus.SUCCEEDED
              ? 'Course Updated'
              : null
          }
          message={
            updateCourse.status === ReducerStatus.SUCCEEDED
              ? 'The course has been updated successfully.'
              : updateCourse.error?.data?.title || 'Server Error'
          }
          errorObject={updateCourse.error?.data?.errors}
        />
      ) : (
        <Modal
          size="2xl"
          isOpen={updateCourse.modal}
          onClose={() => handleToggleUpdateModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Course</ModalHeader>
            <ModalCloseButton />

            <Formik
              enableReinitialize
              initialValues={updateFormInputs}
              onSubmit={(values) => {
                handleUpdateCourse(values);
              }}
              validationSchema={courseFormValidation}
            >
              {() => (
                <Form>
                  <ModalBody my={3}>
                    <VStack spacing={6} align="stretch">
                      <Flex
                        gap={5}
                        direction={{
                          base: 'column',
                          md: 'row',
                        }}
                      >
                        <Field flex={1} name="name">
                          {({ field, form }) => (
                            <FormikFormControl
                              label="Name"
                              helperText="Enter the name of the course. Make it concise and descriptive."
                              errors={form.errors.name}
                              form={form}
                              touched={form.touched.name}
                            >
                              <Input {...field} />
                            </FormikFormControl>
                          )}
                        </Field>

                        <Box
                          w={{
                            base: '100%',
                            md: '380px',
                          }}
                        >
                          <Field name="code">
                            {({ field, form }) => (
                              <FormikFormControl
                                label="Course Code"
                                helperText=" This code will be used to distinguish the course from others."
                                errors={form.errors.code}
                                touched={form.touched.code}
                              >
                                <Input {...field} />
                              </FormikFormControl>
                            )}
                          </Field>
                        </Box>
                      </Flex>

                      <Field name="description">
                        {({ field, form }) => (
                          <FormikFormControl
                            label="Description"
                            helperText="Provide a detailed description of the course. Mention the key objectives and what learners can expect to gain."
                            errors={form.errors.description}
                            touched={form.touched.description}
                          >
                            <Textarea {...field} />
                          </FormikFormControl>
                        )}
                      </Field>

                      <ImageUploadInput
                        label="Thumbnail"
                        helperText="Attach a visually appealing image that represents the course. File type should be an image, and the size should not exceed 5MB."
                        currentImage={course?.thumbnail}
                        file={file}
                        fileURL={fileURL}
                        fileError={fileError}
                        fileInputRef={fileInputRef}
                        handleRemoveSelectedFile={handleRemoveSelectedFile}
                        handleAttachFile={handleAttachFile}
                        handleFileInputChange={handleFileInputChange}
                      />

                      <Field name="categoryId">
                        {({ field, form }) => (
                          <FormikFormControl
                            label="Category"
                            helperText="Choose the appropriate category for the course. This helps in organizing and searching for courses later."
                            errors={form.errors.categoryId}
                            touched={form.touched.categoryId}
                          >
                            <Select {...field} placeholder="Select category">
                              {categories.length > 0 &&
                                categories.map((category) => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                            </Select>
                          </FormikFormControl>
                        )}
                      </Field>

                      <MultiWordInput
                        label="Tags"
                        helperText="Enter relevant tags and press Enter after each one to add to the list. Tags help in categorizing and searching for courses. Example: 'programming, web development, beginner.'"
                        newWordKeyName="newTag"
                        newWord={inputTags.newTag}
                        words={tags}
                        isWordError={inputTags.isTagError}
                        errorMessage={inputTags.errorMessage}
                        onWordChange={handleTagsChange}
                        onWordAdd={handleTagAdd}
                        onWordKeyDown={handleTagKeyDown}
                        onWordRemove={handleTagRemove}
                      />

                      <MultiWordInput
                        label="Owner Groups"
                        helperText="Specify the distribution lists, groups, or emails that have the authority to modify this course. Press Enter after each entry to add it to the list. This ensures proper access control."
                        newWordKeyName="newEmail"
                        newWord={inputEmails.newEmail}
                        words={emails}
                        isWordError={inputEmails.isEmailError}
                        errorMessage={inputEmails.errorMessage}
                        onWordChange={handleEmailsChange}
                        onWordAdd={handleEmailAdd}
                        onWordKeyDown={handleEmailKeyDown}
                        onWordRemove={handleEmailRemove}
                      />

                      <FormikFormControl
                        label="Published Status"
                        helperText="Choose between 'Draft' if the course is still in progress and not readyfor public access, or 'Published' if the course is ready for learners."
                      >
                        <RadioGroup
                          value={isPublished}
                          onChange={handlePublishStatusChange}
                        >
                          <Stack spacing={5} direction="row">
                            <Radio colorScheme="purple" value="false">
                              Draft
                            </Radio>
                            <Radio colorScheme="green" value="true">
                              Published
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </FormikFormControl>
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      variant="outline"
                      colorScheme="blue"
                      mr={3}
                      onClick={() => handleToggleUpdateModal(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="red"
                      px={10}
                      type="submit"
                      isLoading={updateCourse.status === ReducerStatus.LOADING}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default UpdateCourseMd;

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Tr, Td, SkeletonText } from '@chakra-ui/react';

function TableItemLoader({ numberOfRows, numberOfColumns }) {
  return (
    <>
      {[...Array(numberOfRows)].map(() => (
        <Tr key={uuidv4()}>
          {[...Array(numberOfColumns)].map(() => (
            <Td key={uuidv4()} isNumeric>
              <SkeletonText skeletonHeight="1" />
            </Td>
          ))}
        </Tr>
      ))}
    </>
  );
}

TableItemLoader.propTypes = {
  numberOfRows: PropTypes.number.isRequired,
  numberOfColumns: PropTypes.number.isRequired,
};

export default TableItemLoader;

import { createSlice } from '@reduxjs/toolkit';

import { ReducerStatus } from 'utils/constants';
import {
  fetchAdminLearningPlans,
  fetchAdminLearningPlanById,
  deleteAdminLearningPlanById,
  createAdminLearningPlan,
  updateAdminLearningPlan,
} from './admin-learningplan-action';

const adminLearningPlanSlice = createSlice({
  name: 'adminLearningPlan',
  initialState: {
    learningplans: [],
    learningplan: null,
    metaData: null,
    error: null,
    errors: null,
    status: ReducerStatus.IDLE,
    getLearningPlanStatus: ReducerStatus.IDLE,
    createLearningPlan: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateLearningPlan: {
      modal: false,
      learningPlanId: null,
      status: ReducerStatus.IDLE,
      error: null,
    },
    deleteLearningPlanStatus: ReducerStatus.IDLE,
    deleteRefresher: false,
    deleteModal: false,
  },
  reducers: {
    toggleDeleteModal(state, action) {
      state.deleteModal = action.payload;
    },
    toggleCreateModal(state, action) {
      state.createLearningPlan.modal = action.payload;
    },
    toggleUpdateModal(state, action) {
      state.updateLearningPlan.modal = action.payload;
    },
    resetDeleteStatus(state) {
      state.deleteLearningPlanStatus = ReducerStatus.IDLE;
    },
    resetCreateStatus(state) {
      state.createLearningPlan.status = ReducerStatus.IDLE;
    },
    resetUpdateStatus(state) {
      state.updateLearningPlan.status = ReducerStatus.IDLE;
    },
    resetCreateLearningPlan(state) {
      state.createLearningPlan = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    resetUpdateLearningPlan(state) {
      state.updateLearningPlan = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    resetLearningPlan(state) {
      state.learningplan = null;
    },
    resetDeleteRefresher(state) {
      state.deleteRefresher = !state.deleteRefresher;
    },
  },
  extraReducers(builder) {
    builder
      // Fetch Learning Plans
      .addCase(fetchAdminLearningPlans.pending, (state) => {
        state.status = ReducerStatus.LOADING;
      })
      .addCase(fetchAdminLearningPlans.fulfilled, (state, action) => {
        state.status = ReducerStatus.SUCCEEDED;
        const { metaData, data } = action.payload;
        state.learningplans = data;
        state.metaData = metaData;
      })
      // Fetch Learning Plan By ID
      .addCase(fetchAdminLearningPlanById.pending, (state) => {
        state.getLearningPlanStatus = ReducerStatus.LOADING;
      })
      .addCase(fetchAdminLearningPlanById.fulfilled, (state, action) => {
        state.getLearningPlanStatus = ReducerStatus.SUCCEEDED;
        state.learningplan = action.payload;
      })
      .addCase(fetchAdminLearningPlanById.rejected, (state) => {
        state.getLearningPlanStatus = ReducerStatus.FAILED;
      })
      // Delete Learning Plan By ID
      .addCase(deleteAdminLearningPlanById.pending, (state) => {
        state.deleteLearningPlanStatus = ReducerStatus.LOADING;
      })
      .addCase(deleteAdminLearningPlanById.fulfilled, (state) => {
        state.deleteLearningPlanStatus = ReducerStatus.SUCCEEDED;
      })
      .addCase(deleteAdminLearningPlanById.rejected, (state) => {
        state.deleteLearningPlanStatus = ReducerStatus.FAILED;
      })
      // Create Learning Plan
      .addCase(createAdminLearningPlan.pending, (state) => {
        state.createLearningPlan.status = ReducerStatus.LOADING;
      })
      .addCase(createAdminLearningPlan.fulfilled, (state, action) => {
        state.createLearningPlan.status = ReducerStatus.SUCCEEDED;
        state.learningplans = [...state.learningplans, action.payload];
        state.learningplan = action.payload;
      })
      .addCase(createAdminLearningPlan.rejected, (state, action) => {
        state.createLearningPlan.status = ReducerStatus.FAILED;
        state.createLearningPlan.error = action.payload;
      })
      // Update Learning Plan By ID
      .addCase(updateAdminLearningPlan.pending, (state) => {
        state.updateLearningPlan.status = ReducerStatus.LOADING;
      })
      .addCase(updateAdminLearningPlan.fulfilled, (state, action) => {
        state.updateLearningPlan.status = ReducerStatus.SUCCEEDED;
        state.learningplans = state.learningplans.map((learningplan) =>
          learningplan.id === action.payload.id
            ? { ...learningplan, ...action.payload }
            : learningplan
        );

        state.learningplan = action.payload;
      })
      .addCase(updateAdminLearningPlan.rejected, (state, action) => {
        state.updateLearningPlan.status = ReducerStatus.FAILED;
        state.updateLearningPlan.error = action.payload;
      });
  },
});

export const adminLearningPlanActions = adminLearningPlanSlice.actions;

export default adminLearningPlanSlice;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, HStack, IconButton, Stack, Text } from '@chakra-ui/react';

const getPageNumbers = (currentPage, totalCount, pageSize, currentPages) => {
  if (currentPages.indexOf(currentPage) !== -1) {
    return currentPages;
  }

  const maxNumber = Math.ceil(totalCount / pageSize);
  const firstPagePosition = Math.min(currentPage, maxNumber - 4);

  const result = [];
  for (
    let i = firstPagePosition - 1;
    i < firstPagePosition + 4 && i < maxNumber;
    i += 1
  ) {
    if (i > 0) {
      result.push(i + 1);
    }
  }

  return result;
};

function PagingControl({
  totalCount,
  totalCountDescription,
  pageSize,
  onGoToPage,
  currentPage,
}) {
  const [pages, setPages] = useState(
    getPageNumbers(currentPage, totalCount, pageSize, [])
  );

  useEffect(() => {
    setPages(getPageNumbers(currentPage, totalCount, pageSize, []));
  }, [pageSize, currentPage, totalCount, setPages]);

  return (
    <Stack align="center">
      {pages.length > 1 && (
        <HStack>
          <IconButton
            isDisabled={currentPage - 1 <= 0}
            aria-label="previous"
            onClick={() => onGoToPage(currentPage - 1)}
            icon={<ArrowBackIcon />}
          />
          {pages.map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => onGoToPage(pageNumber)}
              colorScheme={pageNumber === currentPage ? 'red' : undefined}
            >
              {pageNumber}
            </Button>
          ))}
          <IconButton
            aria-label="next"
            isDisabled={Math.max(...pages) + 1 > totalCount / pageSize}
            onClick={() => onGoToPage(currentPage + 1)}
            icon={<ArrowForwardIcon />}
          />
        </HStack>
      )}
      <Text>
        {totalCount} {totalCountDescription}
      </Text>
    </Stack>
  );
}

PagingControl.defaultProps = {
  totalCount: 0,
  totalCountDescription: '0',
  pageSize: 0,
  onGoToPage: () => {},
  currentPage: 1,
};

PagingControl.propTypes = {
  totalCount: PropTypes.number,
  totalCountDescription: PropTypes.string,
  pageSize: PropTypes.number,
  onGoToPage: PropTypes.func,
  currentPage: PropTypes.number,
};

export default PagingControl;

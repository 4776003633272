import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Field, Form, Formik } from 'formik';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Flex,
} from '@chakra-ui/react';

import {
  fetchAdminLearningPlans,
  fetchAdminLearningPlanById,
} from 'modules/admin/learningPlan/store/admin-learningplan-action';
import { adminLearningPlanActions } from 'modules/admin/learningPlan/store/admin-learningplan-slice';
import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';
import debounce from 'lodash.debounce';
import NameInput from './components/NameInput';
import GroupsInput from './components/GroupsInput';
import LearningPlanInput from './components/LearningPlanInput';

import useHandleForm from './hooks/useHandleForm';

import { createAdminGrowthStage } from '../../store/admin-growthstage-action';
import { adminGrowthStageActions } from '../../store/admin-growthstage-slice';

function CreateAdminGrowthStage() {
  const dispatch = useDispatch();

  const {
    groups,
    inputTagsGroups,
    selectedLearningPlansToShow,
    learningPlanIds,
    growthStageFormValidation,
    setGroups,
    handleTagsGroupsChange,
    handleGroupRemove,
    handleGroupAdd,
    handleGroupKeyDown,
    handleLearningPlanIdsAdd,
    setSelectedLearningPlansToShow,
    setLearningPlanIds,
    handleUpdateLearningPlanOrder,
    handleDetachLearningPlan,
  } = useHandleForm();

  const [createFormInputs, setCreateFormInputs] = useState({
    name: '',
    learningPlanId: [],
  });

  const createGrowthStage = useSelector(
    (state) => state.adminGrowthStage.createGrowthStage
  );

  const learningPlans = useSelector(
    (state) => state.adminLearningPlan.learningplans
  );

  const [paginationParams, setPaginationParams] = useState({
    query: '',
    currentPage: 1,
    pageSize: 10,
  });

  const handleInputChange = useMemo(
    () =>
      debounce((inputValue) => {
        setPaginationParams({ ...paginationParams, query: inputValue });
      }, 500),
    [paginationParams]
  );

  useEffect(() => {
    if (createGrowthStage.modal) {
      dispatch(fetchAdminLearningPlans(paginationParams));
    }
  }, [createGrowthStage.modal, paginationParams, dispatch]);

  const handleToggleCreateModal = (isOpen) => {
    if (isOpen === false) {
      setSelectedLearningPlansToShow([]);
      setLearningPlanIds([]);
    }
    dispatch(adminGrowthStageActions.toggleCreateModal(isOpen));
  };

  const handleOpenUpdateLearningPlanModal = (learningPlanId) => {
    dispatch(fetchAdminLearningPlanById(learningPlanId));
    dispatch(adminLearningPlanActions.toggleUpdateModal(true));
  };

  const handleCreateGrowthStage = (formikObject) => {
    if (learningPlanIds.length === 0) {
      formikObject.setFieldError(
        'learningPlanId',
        'Please select at least 1 Learning Plan'
      );
    } else {
      const growthStageData = {
        ...formikObject,
        OwnerGroups: groups,
        LearningPlanIds: learningPlanIds,
      };
      dispatch(createAdminGrowthStage(growthStageData));
    }
  };

  const handleResultButton = () => {
    dispatch(adminGrowthStageActions.resetCreateStatus());
    if (createGrowthStage.status === ReducerStatus.SUCCEEDED) {
      setCreateFormInputs({
        name: '',
      });
      handleToggleCreateModal(false);
      setGroups([]);
      setSelectedLearningPlansToShow([]);
      setLearningPlanIds([]);
      dispatch(adminGrowthStageActions.resetTableRefresher());
    }
  };

  return (
    <Box>
      {createGrowthStage.status === ReducerStatus.SUCCEEDED ||
      createGrowthStage.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={createGrowthStage.modal}
          onSubmit={handleResultButton}
          isError={createGrowthStage.status === ReducerStatus.FAILED}
          title={
            createGrowthStage.status === ReducerStatus.SUCCEEDED
              ? 'Growth Stage Created'
              : null
          }
          message={
            createGrowthStage.status === ReducerStatus.SUCCEEDED
              ? 'The growth stage has been created successfully.'
              : createGrowthStage.error?.data?.title || 'Server Error'
          }
          errorObject={createGrowthStage.error?.data?.errors}
        />
      ) : (
        <Modal
          size="2xl"
          isOpen={createGrowthStage.modal}
          onClose={() => handleToggleCreateModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create Growth Stage</ModalHeader>
            <ModalCloseButton />
            <Formik
              enableReinitialize={false}
              initialValues={createFormInputs}
              onSubmit={(values, formikHelpers) => {
                handleCreateGrowthStage({
                  ...values,
                  ...formikHelpers,
                });
              }}
              validationSchema={growthStageFormValidation}
            >
              {() => (
                <Form>
                  <ModalBody my={3}>
                    <VStack spacing={6} align="stretch">
                      <Flex>
                        <Field flex={1} name="name">
                          {({ field, form }) => (
                            <NameInput field={field} form={form} />
                          )}
                        </Field>
                      </Flex>

                      <GroupsInput
                        newGroup={inputTagsGroups.newGroup}
                        groups={groups}
                        isGroupError={inputTagsGroups.isGroupError}
                        errorMessage={inputTagsGroups.errorMessage}
                        onGroupChange={handleTagsGroupsChange}
                        onGroupAdd={handleGroupAdd}
                        onGroupKeyDown={handleGroupKeyDown}
                        onGroupRemove={handleGroupRemove}
                      />

                      <Field flex={1} name="learningPlanId">
                        {({ field, form }) => (
                          <LearningPlanInput
                            field={field}
                            form={form}
                            learningPlans={learningPlans}
                            selectedLearningPlans={selectedLearningPlansToShow}
                            handleInputChange={handleInputChange}
                            handleLearningPlanIdsAdd={handleLearningPlanIdsAdd}
                            handleUpdateLearningPlanOrder={
                              handleUpdateLearningPlanOrder
                            }
                            onEdit={handleOpenUpdateLearningPlanModal}
                            onDelete={handleDetachLearningPlan}
                          />
                        )}
                      </Field>
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      variant="outline"
                      colorScheme="blue"
                      mr={3}
                      onClick={() => handleToggleCreateModal(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="red"
                      px={10}
                      type="submit"
                      isLoading={
                        createGrowthStage.status === ReducerStatus.LOADING
                      }
                    >
                      Create
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default CreateAdminGrowthStage;

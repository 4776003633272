import PropTypes from 'prop-types';

import {
  useColorModeValue,
  Text,
  Box,
  IconButton,
  Icon,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Button,
} from '@chakra-ui/react';

import {
  DragHandleIcon,
  CheckCircleIcon,
  EditIcon,
  DeleteIcon,
} from '@chakra-ui/icons';

import { MdQuiz, MdVideoLibrary, MdVideoSettings } from 'react-icons/md';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { HiDotsVertical } from 'react-icons/hi';
import { RiDraftFill, RiAiGenerate } from 'react-icons/ri';

import { ReducerStatus } from 'utils/constants';

function SubQuest({
  id,
  isEditable,
  subQuest,
  status,
  editSubQuest,
  removeSubQuest,
  generateQuiz,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const iconColor = useColorModeValue('teal', 'white');

  return (
    <div ref={setNodeRef} style={style}>
      <Box mb={6} borderWidth="1px" borderRadius="md">
        <Box w="100%" p={4} bg={useColorModeValue('#f7f7f7', 'gray.800')}>
          <Flex align="center">
            <IconButton
              {...attributes}
              {...listeners}
              variant="plain"
              size="lg"
              color="teal"
              aria-label="Re-order sub-quest"
              icon={<DragHandleIcon />}
            />
            {subQuest.type.id === 1 && (
              <Icon as={MdVideoLibrary} boxSize={10} mx={4} color={iconColor} />
            )}

            {subQuest.type.id === 4 && (
              <Icon as={MdQuiz} boxSize={10} mx={4} color={iconColor} />
            )}

            {subQuest.type.id === 10 && (
              <Icon
                as={MdVideoSettings}
                boxSize={10}
                mx={4}
                color={iconColor}
              />
            )}

            <Box flex={1}>
              <Text fontWeight={500} fontSize="md" mb={1}>
                {subQuest.name}
              </Text>
              <Text fontSize="sm"> {subQuest.objective}</Text>
            </Box>
            <Box mr={3}>
              {subQuest.type.id === 1 &&
                subQuest.subQuestVideo.transcript != null && (
                  <Tooltip hasArrow label="Generate Quiz">
                    <Button onClick={generateQuiz} variant="ghost">
                      <RiAiGenerate />
                    </Button>
                  </Tooltip>
                )}
            </Box>

            {subQuest.isPublished ? (
              <CheckCircleIcon color="green" />
            ) : (
              <RiDraftFill color="purple" />
            )}

            {isEditable && (
              <Menu>
                <MenuButton
                  isLoading={status === ReducerStatus.LOADING}
                  as={IconButton}
                  aria-label="Options"
                  icon={<HiDotsVertical />}
                  variant="text"
                />
                <MenuList>
                  {subQuest.type.id === 1 &&
                    subQuest.subQuestVideo.transcript != null && (
                      <MenuItem icon={<RiAiGenerate />} onClick={generateQuiz}>
                        Generate Quiz
                      </MenuItem>
                    )}
                  <MenuItem icon={<EditIcon />} onClick={editSubQuest}>
                    Edit
                  </MenuItem>
                  <MenuItem icon={<DeleteIcon />} onClick={removeSubQuest}>
                    Remove
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Box>
      </Box>
    </div>
  );
}

SubQuest.defaultProps = {
  id: 1,
};

SubQuest.propTypes = {
  id: PropTypes.number,
  isEditable: PropTypes.bool.isRequired,
  subQuest: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  editSubQuest: PropTypes.func.isRequired,
  removeSubQuest: PropTypes.func.isRequired,
  generateQuiz: PropTypes.func.isRequired,
};

export default SubQuest;

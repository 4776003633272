import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';

function CodeInput({ field, form }) {
  return (
    <FormControl isInvalid={form.errors.code && form.touched.code}>
      <FormLabel>Code</FormLabel>
      <Input {...field} />

      {form.errors.code ? (
        <FormErrorMessage>{form.errors.code}</FormErrorMessage>
      ) : (
        <FormHelperText>
          This code will be used to distinguish the sub-quest from others.
        </FormHelperText>
      )}
    </FormControl>
  );
}

CodeInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default CodeInput;

import { createSlice } from '@reduxjs/toolkit';
import { ReducerStatus } from 'utils/constants';
import {
  fetchAdminLearningPlanCourses,
  fetchAdminLearningPlanCourseById,
  addAdminLearningPlanCourses,
  removeAdminLearningPlanCourseById,
  updateAdminLearningPlanCourseOrderNo,
} from './admin-learningplan-course-action';

const adminLearningPlanCourseSlice = createSlice({
  name: 'adminLearningPlanCourse',
  initialState: {
    learningPlanCourses: [],
    course: null,
    getCoursesStatus: ReducerStatus.IDLE,
    getCourse: {
      status: ReducerStatus.IDLE,
      error: null,
    },
    addCourse: {
      status: ReducerStatus.IDLE,
      isClicked: false,
      error: null,
    },
    removeCourse: {
      learningPlanId: null,
      courseId: null,
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateCourseOrderNo: {
      status: ReducerStatus.IDLE,
      error: null,
    },
    showLearningPlanCourses: false,
  },
  reducers: {
    setLearningPlanCourses(state, action) {
      state.learningPlanCourses = action.payload;
    },
    setCourseIdForRemoval(state, action) {
      const { learningPlanId, courseId } = action.payload;
      state.removeCourse.learningPlanId = learningPlanId;
      state.removeCourse.courseId = courseId;
    },
    toggleRemoveModal(state, action) {
      state.removeCourse.modal = action.payload;
    },
    resetAddCourseStatus(state) {
      state.addCourse.status = ReducerStatus.IDLE;
    },
    resetRemoveStatus(state) {
      state.removeCourse.status = ReducerStatus.IDLE;
    },
    toggleAddCourseButton(state) {
      state.addCourse.isClicked = !state.addCourse.isClicked;
    },
    resetAddCourseButton(state) {
      state.addCourse.isClicked = false;
    },
    toggleShowLearningPlanCourses(state) {
      state.showLearningPlanCourses = !state.showLearningPlanCourses;
    },
    resetShowLearningPlanCourses(state) {
      state.showLearningPlanCourses = false;
    },
  },
  extraReducers(builder) {
    builder
      // Fetch Learning Plan Courses
      .addCase(fetchAdminLearningPlanCourses.pending, (state) => {
        state.getCoursesStatus = ReducerStatus.LOADING;
      })
      .addCase(fetchAdminLearningPlanCourses.fulfilled, (state, action) => {
        state.getCoursesStatus = ReducerStatus.SUCCEEDED;
        state.learningPlanCourses = action.payload.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
      })
      .addCase(fetchAdminLearningPlanCourses.rejected, (state) => {
        state.getCoursesStatus = ReducerStatus.FAILED;
      })
      // Fetch Learning Plan Course By ID
      .addCase(fetchAdminLearningPlanCourseById.pending, (state) => {
        state.getCourse.status = ReducerStatus.LOADING;
      })
      .addCase(fetchAdminLearningPlanCourseById.fulfilled, (state, action) => {
        state.getCourse.status = ReducerStatus.SUCCEEDED;
        state.course = action.payload;
      })
      .addCase(fetchAdminLearningPlanCourseById.rejected, (state, action) => {
        state.getCourse.status = ReducerStatus.FAILED;
        state.getCourse.error = action.payload;
      })
      // Add a Course to a Learning Plan
      .addCase(addAdminLearningPlanCourses.pending, (state) => {
        state.addCourse.status = ReducerStatus.LOADING;
      })
      .addCase(addAdminLearningPlanCourses.fulfilled, (state, action) => {
        state.addCourse.status = ReducerStatus.SUCCEEDED;
        state.learningPlanCourses = [
          ...state.learningPlanCourses,
          {
            ...action.payload,
            id: state.learningPlanCourses.length + 1,
          },
        ];
      })
      .addCase(addAdminLearningPlanCourses.rejected, (state, action) => {
        state.addCourse.status = ReducerStatus.FAILED;
        state.addCourse.error = action.payload;
      })
      // Update the Course Order in a Learning Plan
      .addCase(updateAdminLearningPlanCourseOrderNo.pending, (state) => {
        state.updateCourseOrderNo.status = ReducerStatus.LOADING;
      })
      .addCase(
        updateAdminLearningPlanCourseOrderNo.fulfilled,
        (state, action) => {
          state.updateCourseOrderNo.status = ReducerStatus.SUCCEEDED;

          const index = state.learningPlanCourses.findIndex(
            (learningPlanCourse) =>
              learningPlanCourse.course.id === action.payload.id
          );

          if (index !== -1) {
            const updatedLearningPlanCourses = [...state.learningPlanCourses];
            updatedLearningPlanCourses[index].course = action.payload;
            state.learningPlanCourses = updatedLearningPlanCourses;
          }
        }
      )
      .addCase(
        updateAdminLearningPlanCourseOrderNo.rejected,
        (state, action) => {
          state.updateCourseOrderNo.status = ReducerStatus.FAILED;
          state.updateCourseOrderNo.error = action.payload;
        }
      )
      // Remove a Course from a Learning Plan
      .addCase(removeAdminLearningPlanCourseById.pending, (state) => {
        state.removeCourse.status = ReducerStatus.LOADING;
      })
      .addCase(removeAdminLearningPlanCourseById.fulfilled, (state) => {
        state.removeCourse.status = ReducerStatus.SUCCEEDED;
        state.learningPlanCourses = state.learningPlanCourses.filter(
          (learningPlanCourse) =>
            learningPlanCourse.course.id !== state.removeCourse.courseId
        );
      })
      .addCase(removeAdminLearningPlanCourseById.rejected, (state, action) => {
        state.removeCourse.status = ReducerStatus.FAILED;
        state.removeCourse.error = action.payload;
      });
  },
});

export const adminLearningPlanCourseActions =
  adminLearningPlanCourseSlice.actions;

export default adminLearningPlanCourseSlice;

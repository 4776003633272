import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Box,
  Flex,
  Kbd,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types';

function TagsInput({
  newTag,
  tags,
  isTagError,
  errorMessage,
  onTagChange,
  onTagAdd,
  onTagKeyDown,
  onTagRemove,
}) {
  return (
    <FormControl isInvalid={isTagError}>
      <FormLabel>Tags</FormLabel>
      <Flex gap={2}>
        <Input
          type="text"
          value={newTag}
          onChange={(e) => onTagChange('newTag', e.target.value)}
          onKeyDown={onTagKeyDown}
        />
        <Button
          colorScheme="teal"
          variant="outline"
          size="md"
          onClick={onTagAdd}
        >
          <Kbd>enter</Kbd>
        </Button>
      </Flex>
      <Box my={2}>
        {tags.map((tag) => (
          <Tag
            key={uuidv4()}
            size="md"
            mr={2}
            mb={1}
            borderRadius="full"
            variant="solid"
            colorScheme="teal"
          >
            <TagLabel>{tag}</TagLabel>
            <TagCloseButton onClick={() => onTagRemove(tag)} />
          </Tag>
        ))}
      </Box>
      {isTagError ? (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      ) : (
        <FormHelperText>
          Enter relevant tags and press Enter after each one to add to the list.
          Tags help in categorizing and searching for courses. Example:
          &rdquo;programming, web development, beginner.&ldquo;
        </FormHelperText>
      )}
    </FormControl>
  );
}

TagsInput.propTypes = {
  newTag: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  isTagError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onTagChange: PropTypes.func.isRequired,
  onTagAdd: PropTypes.func.isRequired,
  onTagKeyDown: PropTypes.func.isRequired,
  onTagRemove: PropTypes.func.isRequired,
};

export default TagsInput;

import PropTypes from 'prop-types';

import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';

function NameInput({ field, form }) {
  return (
    <FormControl isInvalid={form.errors.name && form.touched.name}>
      <FormLabel>Name</FormLabel>
      <Input {...field} />
      {form.errors.name ? (
        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
      ) : (
        <FormHelperText>
          Enter the name of the sub-quest. Make it concise and descriptive.
        </FormHelperText>
      )}
    </FormControl>
  );
}

NameInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default NameInput;

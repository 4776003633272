import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Box,
  Flex,
  Kbd,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';

import PropTypes from 'prop-types';

function MultiWordInput({
  label,
  helperText,
  newWordKeyName,
  newWord,
  words,
  isWordError,
  errorMessage,
  onWordChange,
  onWordAdd,
  onWordKeyDown,
  onWordRemove,
}) {
  return (
    <FormControl isInvalid={isWordError}>
      <FormLabel>{label}</FormLabel>
      <Flex gap={2}>
        <Input
          type="text"
          value={newWord}
          onChange={(e) => onWordChange(newWordKeyName, e.target.value)}
          onKeyDown={onWordKeyDown}
        />
        <Button
          colorScheme="teal"
          variant="outline"
          size="md"
          onClick={onWordAdd}
        >
          <Kbd>enter</Kbd>
        </Button>
      </Flex>
      <Box my={2}>
        {words &&
          words.map((word) => (
            <Tag
              key={`${word}`}
              size="md"
              mr={2}
              mb={1}
              borderRadius="full"
              variant="solid"
              colorScheme="teal"
            >
              <TagLabel>{word}</TagLabel>
              <TagCloseButton onClick={() => onWordRemove(word)} />
            </Tag>
          ))}
      </Box>
      {isWordError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}

      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

MultiWordInput.propTypes = {
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  newWordKeyName: PropTypes.string.isRequired,
  newWord: PropTypes.string.isRequired,
  words: PropTypes.array.isRequired,
  isWordError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onWordChange: PropTypes.func.isRequired,
  onWordAdd: PropTypes.func.isRequired,
  onWordKeyDown: PropTypes.func.isRequired,
  onWordRemove: PropTypes.func.isRequired,
};

export default MultiWordInput;

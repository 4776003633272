import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Flex,
  Box,
  Input,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';

import { FaPlus } from 'react-icons/fa';

import { createAddCourseQuestAction } from 'store/modules/quest/quest-actions';
import { questActions } from 'store/modules/quest/quest-slice';

import { ReducerStatus } from 'utils/constants';

import useEmailsInput from 'hooks/useEmailsInput';

import ResultModal from 'components/common/modals/ResultModal';
import FormikFormControl from 'components/common/forms/FormikFormControl';
import MultiWordInput from 'components/common/forms/MultiWordInput';

function CreateQuestMd({ id }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const createAddQuest = useSelector((state) => state.quest.createAddQuest);
  const course = useSelector((state) => state.course.course);

  const [createFormInputs, setCreateFormInputs] = useState({
    name: '',
    description: '',
  });

  const questFormValidation = Yup.object({
    name: Yup.string().max(155).required('Required'),
    description: Yup.string().max(1000).required('Required'),
  });

  const {
    emails,
    inputEmails,
    setEmails,
    handleEmailsChange,
    handleEmailRemove,
    handleEmailAdd,
    handleEmailKeyDown,
  } = useEmailsInput();

  useEffect(() => {
    if (course && isOpen) {
      const parsedGroups = JSON.parse(course?.ownerGroups);
      if (
        Array.isArray(parsedGroups) &&
        parsedGroups.every((group) => typeof group === 'string')
      ) {
        setEmails(parsedGroups);
      }
    }
  }, [course, isOpen, setEmails]);

  const handleCreateQuest = (formikObject) => {
    Object.entries(formikObject).forEach(([key, value]) => {
      setCreateFormInputs((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
    });

    dispatch(
      createAddCourseQuestAction({
        courseId: id,
        name: formikObject.name,
        description: formikObject.description,
        ownerGroups: emails,
      })
    );
  };

  const handleResultButton = () => {
    dispatch(questActions.resetCreateStatus());
    if (createAddQuest.status === ReducerStatus.SUCCEEDED) {
      setCreateFormInputs({
        name: '',
        description: '',
      });
      setEmails([]);
      onClose();
    }
  };

  return (
    <Box>
      {createAddQuest.status === ReducerStatus.SUCCEEDED ||
      createAddQuest.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={isOpen}
          onSubmit={handleResultButton}
          isError={createAddQuest.status === ReducerStatus.FAILED}
          title={
            createAddQuest.status === ReducerStatus.SUCCEEDED
              ? 'Quest Created'
              : null
          }
          message={
            createAddQuest.status === ReducerStatus.SUCCEEDED
              ? 'The quest has been created successfully.'
              : createAddQuest.error?.data?.title || 'Server Error'
          }
          errorObject={createAddQuest.error?.data?.errors}
        />
      ) : (
        <>
          <Button
            onClick={onOpen}
            leftIcon={<FaPlus />}
            colorScheme="teal"
            variant="solid"
          >
            Add A New Quest
          </Button>
          <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create Quest</ModalHeader>
              <ModalCloseButton />
              <Formik
                enableReinitialize={false}
                initialValues={createFormInputs}
                onSubmit={(values) => {
                  handleCreateQuest(values);
                }}
                validationSchema={questFormValidation}
              >
                {() => (
                  <Form>
                    <ModalBody my={3}>
                      <VStack spacing={6} align="stretch">
                        <Flex
                          gap={5}
                          direction={{
                            base: 'column',
                            md: 'row',
                          }}
                        >
                          <Field flex={1} name="name">
                            {({ field, form }) => (
                              <FormikFormControl
                                label="Name"
                                helperText="Enter the name of the quest. Make it concise and descriptive."
                                errors={form.errors.name}
                                form={form}
                                touched={form.touched.name}
                              >
                                <Input {...field} />
                              </FormikFormControl>
                            )}
                          </Field>
                        </Flex>

                        <Field name="description">
                          {({ field, form }) => (
                            <FormikFormControl
                              label="Description"
                              helperText="Provide a detailed description of the quest. Mention the key objectives and what learners can expect to gain."
                              errors={form.errors.description}
                              touched={form.touched.description}
                            >
                              <Textarea {...field} />
                            </FormikFormControl>
                          )}
                        </Field>

                        <MultiWordInput
                          label="Owner Groups"
                          helperText="Specify the distribution lists, groups, or emails that have the authority to modify this course. Press Enter after each entry to add it to the list. This ensures proper access control."
                          newWordKeyName="newEmail"
                          newWord={inputEmails.newEmail}
                          words={emails}
                          isWordError={inputEmails.isEmailError}
                          errorMessage={inputEmails.errorMessage}
                          onWordChange={handleEmailsChange}
                          onWordAdd={handleEmailAdd}
                          onWordKeyDown={handleEmailKeyDown}
                          onWordRemove={handleEmailRemove}
                        />
                      </VStack>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        variant="outline"
                        colorScheme="blue"
                        mr={3}
                        onClick={() => onClose}
                      >
                        Close
                      </Button>
                      <Button
                        colorScheme="red"
                        px={10}
                        type="submit"
                        isLoading={
                          createAddQuest.status === ReducerStatus.LOADING
                        }
                      >
                        Create
                      </Button>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
}

CreateQuestMd.propTypes = {
  id: PropTypes.number.isRequired,
};

export default CreateQuestMd;

import {
  Flex,
  Center,
  Stack,
  useColorModeValue,
  Button,
  useColorMode,
} from '@chakra-ui/react';

import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import HeaderSearch from './HeaderSearch';
import HeaderAccount from './HeaderAccount';
import HeaderLogo from './HeaderLogo';

function Header() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Flex
      bg={useColorModeValue('white', 'gray.800')}
      color={useColorModeValue('gray.600', 'white')}
      h="55px"
      py={{ base: 2 }}
      px={{ base: 6 }}
      boxShadow="lg"
      borderBottom={1}
      borderStyle="solid"
      borderColor={useColorModeValue('gray.200', 'gray.900')}
      align="center"
    >
      <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
        <HeaderLogo />
      </Flex>

      <Flex hideBelow="md" flex={{ base: 2 }} justify={{ base: 'center' }}>
        <Center flex="1">
          <HeaderSearch />
        </Center>
      </Flex>

      <Stack flex={{ base: 1 }} justify="flex-end" direction="row">
        <Button variant="link" onClick={toggleColorMode}>
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </Button>
        <HeaderAccount />
      </Stack>
    </Flex>
  );
}

export default Header;

import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';

function PublishInput({ isPublished, handleChange }) {
  return (
    <FormControl>
      <FormLabel>Published Status</FormLabel>
      <RadioGroup value={isPublished} onChange={handleChange}>
        <Stack spacing={5} direction="row">
          <Radio colorScheme="purple" value="false">
            Draft
          </Radio>
          <Radio colorScheme="green" value="true">
            Published
          </Radio>
        </Stack>
      </RadioGroup>
      <FormHelperText>
        Choose between &ldquo;Draft&ldquo; if the growth stage is still in
        progress and not ready for public access, or &ldquo;Published&ldquo; if
        the growth stage is ready for learners.
      </FormHelperText>
    </FormControl>
  );
}

PublishInput.propTypes = {
  isPublished: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PublishInput;

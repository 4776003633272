import { createSlice } from '@reduxjs/toolkit';

import { ReducerStatus } from 'utils/constants';
import {
  fetchCourseQuestsAction,
  fetchQuestByIdAction,
  createAddCourseQuestAction,
  updateQuestByIdAction,
  deleteQuestByIdAction,
  removeCourseQuestByIdAction,
  updateCourseQuestOrderNoAction,
} from './quest-actions';

const questSlice = createSlice({
  name: 'quest',
  initialState: {
    courseQuests: [],
    quest: null,
    getQuestsStatus: ReducerStatus.IDLE,
    editQuestMode: false,
    getQuest: {
      status: ReducerStatus.IDLE,
      error: null,
    },
    createAddQuest: {
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateQuest: {
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    deleteQuest: {
      questId: null,
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    removeQuest: {
      courseId: null,
      questId: null,
      modal: false,
      status: ReducerStatus.IDLE,
      error: null,
    },
    updateQuestOrderNo: {
      status: ReducerStatus.IDLE,
      error: null,
    },
  },
  reducers: {
    resetCreateStatus(state) {
      state.createAddQuest.status = ReducerStatus.IDLE;
    },
    setQuestEditMode(state, action) {
      state.editQuestMode = action.payload;
    },
    setQuestIdForDeletion(state, action) {
      state.deleteQuest.questId = action.payload;
    },
    setQuestIdForRemoval(state, action) {
      const { courseId, questId } = action.payload;
      state.removeQuest.courseId = courseId;
      state.removeQuest.questId = questId;
    },
    toggleUpdateModal(state, action) {
      state.updateQuest.modal = action.payload;
    },
    toggleDeleteModal(state, action) {
      state.deleteQuest.modal = action.payload;
    },
    toggleRemoveModal(state, action) {
      state.removeQuest.modal = action.payload;
    },
    resetUpdateStatus(state) {
      state.updateQuest.status = ReducerStatus.IDLE;
      state.editQuestMode = false;
    },
    resetDeleteStatus(state) {
      state.deleteQuest.status = ReducerStatus.IDLE;
    },
    resetRemoveStatus(state) {
      state.removeQuest.status = ReducerStatus.IDLE;
    },
    resetUpdateQuest(state) {
      state.updateQuest = {
        modal: false,
        status: ReducerStatus.IDLE,
        error: null,
      };
    },
    setCourseQuests(state, action) {
      state.courseQuests = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // Fetch Course Quests
      .addCase(fetchCourseQuestsAction.pending, (state) => {
        state.getQuestsStatus = ReducerStatus.LOADING;
      })
      .addCase(fetchCourseQuestsAction.fulfilled, (state, action) => {
        state.getQuestsStatus = ReducerStatus.SUCCEEDED;
        state.courseQuests = action.payload.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
      })
      .addCase(fetchCourseQuestsAction.rejected, (state) => {
        state.getQuestsStatus = ReducerStatus.FAILED;
      })
      // Fetch Quest By Id
      .addCase(fetchQuestByIdAction.pending, (state) => {
        state.getQuest.status = ReducerStatus.LOADING;
      })
      .addCase(fetchQuestByIdAction.fulfilled, (state, action) => {
        state.getQuest.status = ReducerStatus.SUCCEEDED;

        // if fetch for editing
        if (state.editQuestMode) state.updateQuest.modal = true;

        state.quest = action.payload;
      })
      .addCase(fetchQuestByIdAction.rejected, (state, action) => {
        state.getQuest.status = ReducerStatus.FAILED;
        state.getQuest.error = action.payload;
      })
      // Create Add Course Quest
      .addCase(createAddCourseQuestAction.pending, (state) => {
        state.createAddQuest.status = ReducerStatus.LOADING;
      })
      .addCase(createAddCourseQuestAction.fulfilled, (state, action) => {
        state.createAddQuest.status = ReducerStatus.SUCCEEDED;
        state.courseQuests = [
          ...state.courseQuests,
          { ...action.payload, id: state.courseQuests.length + 1 },
        ];
      })
      .addCase(createAddCourseQuestAction.rejected, (state, action) => {
        state.createAddQuest.status = ReducerStatus.FAILED;
        state.createAddQuest.error = action.payload;
      })
      // Update Quest
      .addCase(updateQuestByIdAction.pending, (state) => {
        state.updateQuest.status = ReducerStatus.LOADING;
      })
      .addCase(updateQuestByIdAction.fulfilled, (state, action) => {
        state.updateQuest.status = ReducerStatus.SUCCEEDED;

        const index = state.courseQuests.findIndex(
          (courseQuest) => courseQuest.quest.id === action.payload.id
        );

        if (index !== -1) {
          const updatedCourseQuests = [...state.courseQuests];
          updatedCourseQuests[index].quest = action.payload;
          state.courseQuests = updatedCourseQuests;
        }

        state.quest = action.payload;
      })
      .addCase(updateQuestByIdAction.rejected, (state, action) => {
        state.updateQuest.status = ReducerStatus.FAILED;
        state.updateQuest.error = action.payload;
      })
      // Delete Quest By ID
      .addCase(deleteQuestByIdAction.pending, (state) => {
        state.deleteQuest.status = ReducerStatus.LOADING;
      })
      .addCase(deleteQuestByIdAction.fulfilled, (state, action) => {
        state.deleteQuest.status = ReducerStatus.SUCCEEDED;
        state.courseQuests = state.courseQuests.filter(
          (courseQuest) => courseQuest.quest.id !== action.payload
        );
      })
      .addCase(deleteQuestByIdAction.rejected, (state, action) => {
        state.deleteQuest.status = ReducerStatus.FAILED;
        state.deleteQuest.error = action.payload;
      })
      // Remove Quest By ID
      .addCase(removeCourseQuestByIdAction.pending, (state) => {
        state.removeQuest.status = ReducerStatus.LOADING;
      })
      .addCase(removeCourseQuestByIdAction.fulfilled, (state, action) => {
        state.removeQuest.status = ReducerStatus.SUCCEEDED;
        state.courseQuests = state.courseQuests.filter(
          (courseQuest) => courseQuest.quest.id !== action.payload
        );
      })
      .addCase(removeCourseQuestByIdAction.rejected, (state, action) => {
        state.removeQuest.status = ReducerStatus.FAILED;
        state.removeQuest.error = action.payload;
      })
      // Update CourseQuest Order No;
      .addCase(updateCourseQuestOrderNoAction.pending, (state) => {
        state.updateQuestOrderNo.status = ReducerStatus.LOADING;
      })
      .addCase(updateCourseQuestOrderNoAction.fulfilled, (state) => {
        state.updateQuestOrderNo.status = ReducerStatus.SUCCEEDED;
      })
      .addCase(updateCourseQuestOrderNoAction.rejected, (state, action) => {
        state.updateQuestOrderNo.status = ReducerStatus.FAILED;
        state.updateQuestOrderNo.error = action.payload;
      });
  },
});

export const questActions = questSlice.actions;

export default questSlice;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Input,
  Textarea,
} from '@chakra-ui/react';

import { createCategoryAction } from 'store/modules/category/category-actions';
import { categoryActions } from 'store/modules/category/category-slice';

import { ReducerStatus } from 'utils/constants';

import ResultModal from 'components/common/modals/ResultModal';
import FormikFormControl from 'components/common/forms/FormikFormControl';

function CreateCategoryMd() {
  const dispatch = useDispatch();

  const createCategory = useSelector((state) => state.category.createCategory);

  const [createFormInputs, setCreateFormInputs] = useState({
    name: '',
    description: '',
  });

  const categoryFormValidation = Yup.object().shape({
    name: Yup.string().max(155).required('Required'),
    description: Yup.string().max(1000).required('Required'),
  });

  const handleToggleCreateModal = (isOpen) => {
    dispatch(categoryActions.toggleCreateModal(isOpen));
  };

  const handleResultButton = () => {
    dispatch(categoryActions.resetCreateStatus());
    if (createCategory.status === ReducerStatus.SUCCEEDED) {
      setCreateFormInputs({
        name: '',
        description: '',
      });
      handleToggleCreateModal(false);
    }

    if (createCategory.status !== ReducerStatus.LOADING) {
      dispatch(categoryActions.resetCreateCategory());
    }
  };

  const handleCreateCategory = (formikObject) => {
    dispatch(createCategoryAction(formikObject));
  };

  return (
    <Box>
      {createCategory.status === ReducerStatus.SUCCEEDED ||
      createCategory.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={createCategory.modal}
          onSubmit={handleResultButton}
          isError={createCategory.status === ReducerStatus.FAILED}
          title={
            createCategory.status === ReducerStatus.SUCCEEDED
              ? 'Category Created'
              : null
          }
          message={
            createCategory.status === ReducerStatus.SUCCEEDED
              ? 'The category has been created successfully.'
              : createCategory.error?.data?.title || 'Server Error'
          }
          errorObject={createCategory.error?.data?.errors}
        />
      ) : (
        <Modal
          size="2xl"
          isOpen={createCategory.modal}
          onClose={() => handleToggleCreateModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create Category</ModalHeader>
            <ModalCloseButton />

            <Formik
              enableReinitialize={false}
              initialValues={createFormInputs}
              onSubmit={(values) => {
                handleCreateCategory(values);
              }}
              validationSchema={categoryFormValidation}
            >
              {() => (
                <Form>
                  <ModalBody my={3}>
                    <VStack spacing={6} align="stretch">
                      <Field flex={1} name="name">
                        {({ field, form }) => (
                          <FormikFormControl
                            label="Name"
                            helperText="Enter the name of the category. Make it concise and descriptive."
                            errors={form.errors.name}
                            form={form}
                            touched={form.touched.name}
                          >
                            <Input {...field} />
                          </FormikFormControl>
                        )}
                      </Field>

                      <Field name="description">
                        {({ field, form }) => (
                          <FormikFormControl
                            label="Description"
                            helperText="Provide a detailed description of the category. Mention the key objectives and what users can expect."
                            errors={form.errors.description}
                            touched={form.touched.description}
                          >
                            <Textarea {...field} />
                          </FormikFormControl>
                        )}
                      </Field>
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      variant="outline"
                      colorScheme="blue"
                      mr={3}
                      onClick={() => handleToggleCreateModal(false)}
                    >
                      Close
                    </Button>
                    <Button
                      colorScheme="red"
                      px={10}
                      type="submit"
                      isLoading={
                        createCategory.status === ReducerStatus.LOADING
                      }
                    >
                      Create
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default CreateCategoryMd;

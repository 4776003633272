import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  IconButton,
  Flex,
  Input,
  Textarea,
  Tooltip,
  FormControl,
  FormErrorMessage,
  Button,
  Spacer,
} from '@chakra-ui/react';

import { DeleteIcon } from '@chakra-ui/icons';

import { DndContext, closestCorners } from '@dnd-kit/core';

import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';

import { companionActions } from 'companion/store/companion-slice';
import axios from 'axios';
import { fetchCourseQuestsAction } from 'store/modules/quest/quest-actions';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import ResponseModal from 'components/common/modals/ResponseModal';
import QuestDraftItem from 'components/quest/QuestDraftItem';

function CourseQuestsDraft() {
  const dispatch = useDispatch();
  const [drafts, setDrafts] = useState([]);
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [error, setError] = useState(null);

  const course = useSelector((state) => state.course.course);
  const outlines = useSelector((state) => state.companion.outlines);

  const validationSchema = Yup.object().shape({
    itemList: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().max(155).required('Required'),
        description: Yup.string().max(1000).required('Required'),
      })
    ),
  });

  useEffect(() => {
    if (course) {
      const parsedEmails = JSON.parse(course.ownerGroups);
      if (
        Array.isArray(parsedEmails) &&
        parsedEmails.every((group) => typeof group === 'string')
      ) {
        setEmails(parsedEmails);
      }
    }
  }, [course]);

  useEffect(() => {
    setDrafts(outlines);
  }, [outlines]);

  const handleDeleteItem = (index) =>
    setDrafts((prevItemList) => [
      ...prevItemList.slice(0, index),
      ...prevItemList.slice(index + 1),
    ]);

  const handleCancelDraft = () => {
    dispatch(companionActions.setOutlines([]));
  };

  const handleCreateQuests = async () => {
    setIsLoading(true);

    try {
      for (let i = 0; i < drafts.length; i += 1) {
        const draft = drafts[i];

        /* eslint-disable no-await-in-loop */
        const createCourseResponse = await axios.post(
          '/admin/quests',
          {
            name: draft.title,
            description: draft.description,
            ownerGroups: emails,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const quest = createCourseResponse.data;

        await axios.post(`/admin/courses/${course.id}/quests`, quest.id, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        /* eslint-enable no-await-in-loop */
      }

      setDrafts([]);
      dispatch(fetchCourseQuestsAction(course.id));
    } catch (questDraftError) {
      setIsSuccess(false);

      setError({
        status: questDraftError.response.status,
        data: questDraftError.response.data,
      });
    }

    setConfirmModal(false);
    setResponseModal(true);
    setIsLoading(false);
  };

  const handleOnClickResponseModal = () => {
    if (!isSuccess) setConfirmModal(true);
    setResponseModal(false);
  };

  const getDraftPos = (id) => drafts.findIndex((draft) => draft.id === id);

  const handleOnChangeOrderNo = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    setDrafts((currentDrafts) => {
      const originalPos = getDraftPos(active.id);
      const newPos = getDraftPos(over.id);

      return arrayMove(currentDrafts, originalPos, newPos);
    });
  };

  return (
    <Box my={4}>
      <ConfirmationModal
        isVisible={confirmModal}
        isLoading={isLoading}
        description="Are you certain you wish to proceed with creating the drafts? Once submitted, it cannot be undone."
        onSubmit={() => handleCreateQuests()}
        onCancel={() => setConfirmModal(false)}
      />

      <ResponseModal
        isVisible={responseModal}
        isSuccess={isSuccess}
        errorObject={error}
        onSubmit={() => handleOnClickResponseModal()}
      />

      <DndContext
        onDragEnd={handleOnChangeOrderNo}
        collisionDetection={closestCorners}
      >
        <SortableContext items={drafts} strategy={verticalListSortingStrategy}>
          {drafts.length > 0 && (
            <Box>
              <Formik
                enableReinitialize
                initialValues={{
                  itemList: drafts,
                }}
                validationSchema={validationSchema}
                onSubmit={({ itemList }) => {
                  setDrafts(itemList);
                  setConfirmModal(true);
                }}
              >
                <Form>
                  <FieldArray name="itemList">
                    {() => (
                      <Box>
                        {drafts?.map((draft, index) => (
                          <QuestDraftItem
                            id={draft.id}
                            key={draft.id}
                            titleInputComp={
                              <Field name={`itemList.${index}.title`}>
                                {({ field, form }) => (
                                  <FormControl
                                    isInvalid={
                                      form.errors.itemList?.[index]?.title &&
                                      form.touched.itemList?.[index]?.title
                                    }
                                  >
                                    <Input {...field} placeholder="Title" />
                                    <FormErrorMessage>
                                      {form.errors.itemList?.[index]?.title}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                            }
                            descriptionInputComp={
                              <Field name={`itemList.${index}.description`}>
                                {({ field, form }) => (
                                  <FormControl
                                    isInvalid={
                                      form.errors.itemList?.[index]
                                        ?.description &&
                                      form.touched.itemList?.[index]
                                        ?.description
                                    }
                                  >
                                    <Textarea
                                      {...field}
                                      placeholder="Description"
                                    />
                                    <FormErrorMessage>
                                      {
                                        form.errors.itemList?.[index]
                                          ?.description
                                      }
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                            }
                            deleteBtnComp={
                              <Tooltip label="Remove item">
                                <IconButton
                                  onClick={() => handleDeleteItem(index)}
                                  variant="plain"
                                  color="teal"
                                  aria-label="Remove Quest"
                                  icon={<DeleteIcon />}
                                />
                              </Tooltip>
                            }
                          />
                        ))}
                      </Box>
                    )}
                  </FieldArray>

                  <Flex my={5}>
                    <Spacer />
                    <Button
                      onClick={() => handleCancelDraft()}
                      variant="outline"
                      colorScheme="blue"
                      mr={3}
                    >
                      Close
                    </Button>
                    <Button colorScheme="red" px={10} type="submit">
                      Create
                    </Button>
                  </Flex>
                </Form>
              </Formik>
            </Box>
          )}
        </SortableContext>
      </DndContext>
    </Box>
  );
}

export default CourseQuestsDraft;

import { Box, Flex, Progress, Text, Spacer } from '@chakra-ui/react';

import PropTypes from 'prop-types';

function ProgressDisplay({ percentage, subQuestCount, isLoading }) {
  return (
    <Box>
      <Progress
        mb={2}
        size="sm"
        colorScheme={percentage === 100 ? 'green' : 'blue'}
        value={percentage}
        isIndeterminate={isLoading}
        isAnimated
        hasStripe={percentage !== 100}
      />

      <Flex mt={5} mb={3}>
        {percentage > 0 ? (
          <Text as="sup" flex={1}>
            {Math.round(percentage)}% Complete
          </Text>
        ) : (
          <Spacer />
        )}

        {subQuestCount > 0 && <Text as="sup">{subQuestCount} Activities</Text>}
      </Flex>
    </Box>
  );
}

ProgressDisplay.propTypes = {
  percentage: PropTypes.number.isRequired,
  subQuestCount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ProgressDisplay;

import PropTypes from 'prop-types';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

function ConfirmationModal({
  isVisible,
  isLoading,
  title,
  description,
  onSubmit,
  onCancel,
}) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isVisible}
      onClose={() => onCancel()}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title || 'Confirmation Required'}</ModalHeader>
        <ModalCloseButton disabled={isLoading} />
        <ModalBody>
          {description ||
            'Are you sure you want to proceed with this action? Once submitted, it cannot be undone.'}
        </ModalBody>

        <ModalFooter>
          <Button
            variant="outline"
            colorScheme="grey"
            mr={3}
            isDisabled={isLoading}
            onClick={() => onCancel()}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            isLoading={isLoading}
            onClick={() => onSubmit()}
          >
            Proceed
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  title: null,
  description: null,
};

ConfirmationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationModal;

import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Box,
  Flex,
  Kbd,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';

import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types';

function GroupsInput({
  newGroup,
  groups,
  isGroupError,
  errorMessage,
  onGroupChange,
  onGroupAdd,
  onGroupKeyDown,
  onGroupRemove,
}) {
  return (
    <FormControl isInvalid={isGroupError}>
      <FormLabel optionalIndicator>Owner Groups</FormLabel>
      <Flex gap={2}>
        <Input
          type="text"
          value={newGroup}
          onChange={(e) => onGroupChange('newGroup', e.target.value)}
          onKeyDown={onGroupKeyDown}
        />
        <Button
          colorScheme="teal"
          variant="outline"
          size="md"
          onClick={onGroupAdd}
        >
          <Kbd>enter</Kbd>
        </Button>
      </Flex>
      <Box my={2}>
        {groups.map((group) => (
          <Tag
            key={uuidv4()}
            size="md"
            mr={2}
            mb={1}
            borderRadius="full"
            variant="solid"
            colorScheme="teal"
          >
            <TagLabel>{group}</TagLabel>
            <TagCloseButton onClick={() => onGroupRemove(group)} />
          </Tag>
        ))}
      </Box>
      {isGroupError ? (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      ) : (
        <FormHelperText>
          Specify the distribution lists or groups that have the authority to
          modify this video sub-quest. Press Enter after each entry to add it to
          the list. This ensures proper access control.
        </FormHelperText>
      )}
    </FormControl>
  );
}

// Define PropTypes
GroupsInput.propTypes = {
  newGroup: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  isGroupError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onGroupChange: PropTypes.func.isRequired,
  onGroupAdd: PropTypes.func.isRequired,
  onGroupKeyDown: PropTypes.func.isRequired,
  onGroupRemove: PropTypes.func.isRequired,
};

export default GroupsInput;

import { useState } from 'react';
import * as Yup from 'yup';

const useHandleForm = () => {
  const [tags, setTags] = useState([]);
  const [groups, setGroups] = useState([]);

  const [inputTagsGroups, setInputTagsGroups] = useState({
    newTag: '',
    newGroup: '',
    isTagError: false,
    isGroupError: false,
    errorMessage: '',
  });

  const courseFormValidation = Yup.object({
    name: Yup.string().max(155).required('Required'),
    videoName: Yup.string().max(155).required('Required'),
    objective: Yup.string().max(1000).required('Required'),
    description: Yup.string().max(1000).required('Required'),
    code: Yup.string().min(1).max(20).required('Required'),
  });

  const handleTagsGroupsChange = (identifier, value) => {
    setInputTagsGroups((prevValues) => ({
      ...prevValues,
      [identifier]: value,
    }));
  };

  const handleTagRemove = (tagToRemove) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  };

  const handleGroupRemove = (groupToRemove) => {
    setGroups((prevGroups) =>
      prevGroups.filter((group) => group !== groupToRemove)
    );
  };

  const handleGroupAdd = () => {
    const allowedDomains = ['trendmicro.com', 'dl.trendmicro.com'];
    const domainRegex = /@([a-zA-Z0-9.-]+)$/;
    const match = inputTagsGroups.newGroup.match(domainRegex);

    if (match && match[1] && allowedDomains.includes(match[1])) {
      setGroups((prevGroups) => [...prevGroups, inputTagsGroups.newGroup]);
      handleTagsGroupsChange('isGroupError', false);
      handleTagsGroupsChange('newGroup', '');
    } else {
      handleTagsGroupsChange('isGroupError', true);
      handleTagsGroupsChange(
        'errorMessage',
        'Email is not from an allowed domain. It must be one of the following: trendmicro.com, dl.trendmicro.com.'
      );
    }
  };

  const handleTagAdd = () => {
    const trimmedTag = inputTagsGroups.newTag.trim().toUpperCase();
    if (
      trimmedTag === '' ||
      trimmedTag.length > 20 ||
      tags.includes(trimmedTag)
    ) {
      handleTagsGroupsChange('isTagError', true);
      handleTagsGroupsChange(
        'errorMessage',
        'Tag should not be null and must not exceed 20 characters.'
      );
    } else {
      handleTagsGroupsChange('isTagError', false);
      setTags((prevTags) => [...prevTags, trimmedTag]);
      handleTagsGroupsChange('newTag', '');
    }
  };

  const handleTagKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleTagAdd();
    } else {
      handleTagsGroupsChange('isTagError', false);
    }
  };

  const handleGroupKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleGroupAdd();
    } else {
      handleTagsGroupsChange('isGroupError', false);
    }
  };

  return {
    tags,
    groups,
    inputTagsGroups,
    courseFormValidation,
    setTags,
    setGroups,
    handleTagsGroupsChange,
    handleTagRemove,
    handleGroupRemove,
    handleGroupAdd,
    handleTagAdd,
    handleTagKeyDown,
    handleGroupKeyDown,
  };
};

export default useHandleForm;

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { deleteCourseByIdAction } from 'store/modules/course/course-actions';
import { courseActions } from 'store/modules/course/course-slice';

import { ReducerStatus } from 'utils/constants';

import ResultModal from 'components/common/modals/ResultModal';

function DeleteCourseMd() {
  // Before using this component, you must have a value from modules/course/course state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const courseId = useSelector((state) => state.course.course?.id);
  const deleteStatus = useSelector((state) => state.course.deleteCourseStatus);
  const deleteModal = useSelector((state) => state.course.deleteModal);

  const handleToggleDeleteModal = (isOpen) => {
    dispatch(courseActions.toggleDeleteModal(isOpen));
  };

  const handleDeleteCourse = () => {
    dispatch(deleteCourseByIdAction(courseId));
  };

  const handleResultButton = () => {
    dispatch(courseActions.resetDeleteStatus());
    if (deleteStatus === ReducerStatus.SUCCEEDED) {
      handleToggleDeleteModal(false);
      navigate('/admin/courses');
    }
  };

  return (
    <Box>
      {deleteStatus === ReducerStatus.SUCCEEDED ||
      deleteStatus === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={deleteModal}
          onSubmit={handleResultButton}
          isError={deleteStatus === ReducerStatus.FAILED}
          title={
            deleteStatus === ReducerStatus.SUCCEEDED
              ? 'Deleted'
              : 'Server Error'
          }
          message={
            deleteStatus === ReducerStatus.SUCCEEDED
              ? 'The selected course has been deleted successfully. If you believe this was done in error, please contact the administrator.'
              : null
          }
        />
      ) : (
        <Modal
          isOpen={deleteModal}
          onClose={() => handleToggleDeleteModal(false)}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation Required</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you certain you want to delete this course? Please note that
              all related course materials will also be deleted.
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => handleToggleDeleteModal(false)}
              >
                Close
              </Button>
              <Button
                variant="outline"
                colorScheme="red"
                isLoading={deleteStatus === ReducerStatus.LOADING}
                onClick={() => handleDeleteCourse()}
              >
                Proceed
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default DeleteCourseMd;

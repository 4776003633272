import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';

function RequireAuth({ allowedRoles }) {
  const location = useLocation();
  const { accounts } = useMsal();
  const { idTokenClaims } = accounts[0];
  const { roles } = idTokenClaims;

  if (roles?.find((role) => allowedRoles?.includes(role))) {
    return <Outlet />;
  }
  return <Navigate to="/" state={{ from: location }} replace />;
}

RequireAuth.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RequireAuth;

import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';

function FormikFormControl({ label, helperText, errors, touched, children }) {
  return (
    <FormControl isInvalid={errors && touched}>
      <FormLabel>{label}</FormLabel>
      {children}
      {errors && <FormErrorMessage>{errors}</FormErrorMessage>}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

FormikFormControl.defaultProps = {
  errors: null,
  touched: false,
};

FormikFormControl.propTypes = {
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  errors: PropTypes.string,
  touched: PropTypes.bool,
};

export default FormikFormControl;

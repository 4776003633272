import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useInViewport } from 'react-in-viewport';

import { Box, Text, ScaleFade, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';
import CourseImage from './CourseImage';
import ProgressDisplay from './ProgressDisplay';

function CourseItem({ id, image, name, category, description }) {
  const [progressLoading, setProgressLoading] = useState(true);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [subQuestCount, setSubQuestCount] = useState(0);

  const ref = useRef(null);

  const { inViewport } = useInViewport(
    ref,
    { rootMargin: '100%' },
    { disconnectOnLeave: false },
    {}
  );

  useEffect(() => {
    const getProgress = async () => {
      const response = await axios.get(`/courses/${id}/progression`);
      const { totalSubQuestCount, progressions } = response.data;

      setProgressPercentage(
        (progressions.filter((p) => p.dateFinished).length /
          totalSubQuestCount) *
          100 || 0
      );

      setSubQuestCount(totalSubQuestCount);

      setProgressLoading(false);
    };

    getProgress();
  }, [id]);

  return (
    <Box w="100%">
      <ScaleFade
        initialScale={0.9}
        in={inViewport}
        whileHover={{ scale: 1.01 }}
      >
        <LinkBox
          ref={ref}
          borderWidth="1px"
          borderRadius="sm"
          overflow="hidden"
          boxShadow="md"
          h="100%"
          position="relative"
        >
          <Box w="100%" h="190px">
            <CourseImage name={name} image={image} category={category} />
          </Box>
          <Box p={4} h="100%">
            <ProgressDisplay
              percentage={progressPercentage}
              subQuestCount={subQuestCount}
              isLoading={progressLoading}
            />

            <Text fontSize="md" fontWeight="medium" colorScheme="gray">
              <LinkOverlay
                as={ReactRouterLink}
                color="blue.500"
                to={`/courses/${id}`}
              >
                {name}
              </LinkOverlay>
              {progressPercentage === 100 && (
                <CheckCircleIcon ml={2} color="green" boxSize={4} />
              )}
            </Text>

            <Text fontSize="sm">
              {description.length > 45
                ? `${description.substring(0, 45)}...`
                : description}
            </Text>
          </Box>
        </LinkBox>
      </ScaleFade>
    </Box>
  );
}

CourseItem.defaultProps = {
  image: null,
};

CourseItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default CourseItem;

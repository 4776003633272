import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Flex,
  Button,
  Text,
  Center,
} from '@chakra-ui/react';

import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons';

function VideoFileInput({
  video,
  videoURL,
  videoError,
  videoInputRef,
  handleRemoveSelectedVideo,
  handleAttachVideo,
  handleVideoInputChange,
}) {
  return (
    <FormControl isInvalid={videoError !== ''}>
      <FormLabel optionalIndicator>Video Input</FormLabel>

      {video?.name && (
        <Box my={4}>
          <Center>
            <video controls id="video-tag">
              <track kind="captions" />
              <source id="video-source" src={videoURL} />
              Your browser does not support the video tag.
            </video>
          </Center>

          <Flex gap={2} my={2} justify="center" align="center">
            <Text flex={1}>Selected Video: {video?.name}</Text>
            <Button
              type="button"
              colorScheme="teal"
              variant="ghost"
              onClick={handleRemoveSelectedVideo}
            >
              <CloseIcon />
            </Button>
          </Flex>
        </Box>
      )}
      <Box
        as="button"
        type="button"
        borderRadius="md"
        bg="teal"
        w="100%"
        px={4}
        h={10}
        color="white"
        onClick={handleAttachVideo}
      >
        <input
          type="file"
          hidden
          accept="video/mp4"
          ref={videoInputRef}
          onChange={handleVideoInputChange}
        />
        <AttachmentIcon mx={2} />
        Attach video
      </Box>
      {videoError !== '' ? (
        <FormErrorMessage>{videoError}</FormErrorMessage>
      ) : (
        <FormHelperText>
          {videoError}
          Attach a video that will be presented in this sub-quest. File type
          should be a video, and the size should not exceed 500MB.
        </FormHelperText>
      )}
    </FormControl>
  );
}

VideoFileInput.defaultProps = {
  video: null,
  videoURL: null,
};

VideoFileInput.propTypes = {
  video: PropTypes.object,
  videoURL: PropTypes.string,
  videoError: PropTypes.string.isRequired,
  videoInputRef: PropTypes.object.isRequired,
  handleRemoveSelectedVideo: PropTypes.func.isRequired,
  handleAttachVideo: PropTypes.func.isRequired,
  handleVideoInputChange: PropTypes.func.isRequired,
};

export default VideoFileInput;

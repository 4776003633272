import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box,
  Divider,
  Textarea,
  Input,
  VStack,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Progress,
} from '@chakra-ui/react';

import { MdVideoSettings } from 'react-icons/md';
import SubQuestForm from 'components/subQuest/SubQuestForm';
import FormikFormControl from 'components/common/forms/FormikFormControl';

import useFileInput from 'hooks/useFileInput';
import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';

import { createAdminInteractiveVideoSubQuest } from 'modules/admin/subQuest/store/admin-subquest-action';
import { adminSubQuestActions } from 'modules/admin/subQuest/store/admin-subquest-slice';
import { arrayOfString } from 'utils/jsonParser';

function CreateInteractiveVideoMd({ quest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const [triggerSubQuestForm, setTriggerSubQuestForm] = useState(0);
  const [isSubQuestFormValid, setIsSubQuestFormValid] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [subQuestFormInputs, setSubQuestFormInputs] = useState({
    name: '',
    objective: '',
    code: '',
    isPublished: '',
    tags: [],
    groups: [],
  });

  const [formInputs, setFormInputs] = useState({
    transcript: '',
    indexName: 'index_lms.html',
  });

  const createInteractiveVideo = useSelector(
    (state) => state.adminSubQuest.createInteractiveVideo
  );

  const uploadProgress = useSelector(
    (state) => state.adminSubQuest.uploadProgress
  );

  const formikForm = useRef();

  const {
    file,
    fileError,
    fileInputRef,
    setFileError,
    handleAttachFile,
    handleRemoveSelectedFile,
    handleFileInputChange,
  } = useFileInput({ maxFileSizeMb: 850, fileType: 'application/' });

  useEffect(() => {
    if (file.name) setFileError('');
  }, [file, setFileError]);

  useEffect(() => {
    if (quest.ownerGroups) {
      setSubQuestFormInputs((prev) => ({
        ...prev,
        groups: arrayOfString(quest.ownerGroups),
      }));
    }
  }, [quest.ownerGroups]);

  const formValidation = Yup.object({
    transcript: Yup.string().min(3).required('Required'),
    indexName: Yup.string().min(1).max(20).required('Required'),
  });

  const handleTriggerSubmit = () => {
    setTriggerSubQuestForm((trigger) => trigger + 1);
    if (!file.name) setFileError('Interactive Video is required');
    setIsFormSubmit(true);
  };

  const handleSubmitForm = (values) => {
    if (isSubQuestFormValid === false || !file.name) return;

    setFormInputs({ ...values });

    const formData = new FormData();

    Object.entries(subQuestFormInputs).forEach(([key, value]) => {
      if (key !== 'tags' && key !== 'groups') formData.append(key, value);
    });

    if (subQuestFormInputs.tags.length > 0)
      subQuestFormInputs.tags.forEach((tag) => formData.append('Tags', tag));
    if (subQuestFormInputs.groups.length > 0)
      subQuestFormInputs.groups.forEach((group) =>
        formData.append('OwnerGroups', group)
      );

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append('ZipFile', file, file.name);

    dispatch(createAdminInteractiveVideoSubQuest({ id: quest.id, formData }));
  };

  useEffect(() => {
    if (isFormSubmit && isSubQuestFormValid) {
      formikForm?.current?.submitForm();
      setIsFormSubmit(false);
    }
  }, [isFormSubmit, isSubQuestFormValid, setIsFormSubmit]);

  const handleResult = () => {
    if (createInteractiveVideo.status === ReducerStatus.SUCCEEDED) {
      setFormInputs({
        transcript: '',
        indexName: 'index_lms.html',
      });

      setSubQuestFormInputs({
        name: '',
        objective: '',
        code: '',
        isPublished: '',
        tags: [],
        emails: [],
      });

      setTriggerSubQuestForm(-1);
      handleRemoveSelectedFile();
      onClose();
    }
    dispatch(adminSubQuestActions.resetCreateInteractiveVideo());
  };

  return (
    <Box>
      {createInteractiveVideo.status === ReducerStatus.SUCCEEDED ||
      createInteractiveVideo.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={isOpen}
          onSubmit={handleResult}
          isError={createInteractiveVideo.status === ReducerStatus.FAILED}
          title={
            createInteractiveVideo.status === ReducerStatus.SUCCEEDED
              ? 'Interactive Video Sub-Quest Created'
              : null
          }
          message={
            createInteractiveVideo.status === ReducerStatus.SUCCEEDED
              ? 'The interactive video sub-quest has been created successfully. Please allow a few minutes for the system to unzip the attached files.'
              : createInteractiveVideo.error?.data?.title || 'Server Error'
          }
          errorObject={createInteractiveVideo.error?.data?.errors}
        />
      ) : (
        <>
          <Button
            onClick={onOpen}
            leftIcon={<MdVideoSettings />}
            w="100%"
            colorScheme="red"
            size="lg"
          >
            Interactive Video
          </Button>
          <Modal
            size="2xl"
            closeOnOverlayClick={
              createInteractiveVideo.status !== ReducerStatus.LOADING
            }
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create Interactive Video Sub-Quest</ModalHeader>
              <ModalCloseButton
                disabled={
                  createInteractiveVideo.status === ReducerStatus.LOADING
                }
              />
              <ModalBody my={3}>
                <SubQuestForm
                  questName={quest.name}
                  groups={quest.ownerGroups}
                  subQuestFormInputs={subQuestFormInputs}
                  triggerSubQuestForm={triggerSubQuestForm}
                  setSubQuestFormInputs={setSubQuestFormInputs}
                  setIsSubQuestFormValid={setIsSubQuestFormValid}
                />
              </ModalBody>
              <Formik
                enableReinitialize
                innerRef={formikForm}
                initialValues={formInputs}
                onSubmit={(values) => {
                  handleSubmitForm(values);
                }}
                validationSchema={formValidation}
              >
                {() => (
                  <Box>
                    <Form>
                      <ModalBody>
                        <Divider mb={4} />

                        <VStack spacing={6} align="stretch">
                          <FormControl isInvalid={fileError !== ''}>
                            {file?.name && (
                              <Box my={4}>
                                <Flex
                                  gap={2}
                                  my={2}
                                  justify="center"
                                  align="center"
                                >
                                  <Text flex={1}>
                                    Selected File: {file?.name}
                                  </Text>
                                  <Button
                                    type="button"
                                    colorScheme="teal"
                                    variant="ghost"
                                    onClick={handleRemoveSelectedFile}
                                  >
                                    <CloseIcon />
                                  </Button>
                                </Flex>
                              </Box>
                            )}

                            <Text fontSize="sm" as="b">
                              Ensure that the attached zip file, when opened,
                              contains all the content directly and not a folder
                              containing the content.
                            </Text>

                            <Box
                              as="button"
                              type="button"
                              borderRadius="md"
                              bg="teal"
                              w="100%"
                              mt={5}
                              px={4}
                              h={10}
                              color="white"
                              onClick={handleAttachFile}
                            >
                              <input
                                type="file"
                                hidden
                                accept="application/zip"
                                ref={fileInputRef}
                                onChange={handleFileInputChange}
                              />
                              <AttachmentIcon mx={2} />
                              Attach Interactive Video File
                            </Box>
                            {fileError !== '' && (
                              <FormErrorMessage>{fileError}</FormErrorMessage>
                            )}
                            <FormHelperText>
                              Please attach the interactive video file in a
                              compressed state (.zip).
                            </FormHelperText>
                          </FormControl>

                          <Field name="indexName">
                            {({ field, form }) => (
                              <FormikFormControl
                                label="Index Name"
                                helperText="Please specify the root path .html of the zipped file for the interactive content. Typically, the root path is index_lms.html."
                                errors={form.errors.indexName}
                                touched={form.touched.indexName}
                              >
                                <Input {...field} />
                              </FormikFormControl>
                            )}
                          </Field>

                          <Field name="transcript">
                            {({ field, form }) => (
                              <FormikFormControl
                                label="Transcript"
                                helperText="Please provide the transcript of the video. The transcript will be utilized to train the AI Companion, enabling it to respond to trainee questions that are relevant to the content covered in the video."
                                errors={form.errors.transcript}
                                touched={form.touched.transcript}
                              >
                                <Textarea {...field} />
                              </FormikFormControl>
                            )}
                          </Field>

                          {createInteractiveVideo.status ===
                            ReducerStatus.LOADING && (
                            <Progress
                              hasStripe
                              isAnimated
                              value={uploadProgress}
                            />
                          )}
                        </VStack>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          variant="outline"
                          colorScheme="blue"
                          mr={3}
                          onClick={onClose}
                          isLoading={
                            createInteractiveVideo.status ===
                            ReducerStatus.LOADING
                          }
                        >
                          Close
                        </Button>
                        <Button
                          isLoading={
                            createInteractiveVideo.status ===
                            ReducerStatus.LOADING
                          }
                          onClick={handleTriggerSubmit}
                          colorScheme="red"
                          px={10}
                        >
                          Submit
                        </Button>
                      </ModalFooter>
                    </Form>
                  </Box>
                )}
              </Formik>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
}

CreateInteractiveVideoMd.propTypes = {
  quest: PropTypes.object.isRequired,
};

export default CreateInteractiveVideoMd;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player';

import { Box } from '@chakra-ui/react';

import { companionActions } from 'companion/store/companion-slice';
import { CompanionMode } from 'utils/constants';
import { postProgressionAction } from 'store/modules/progression/progression-actions';

function VideoSubQuest({ course, quest, subQuest, code }) {
  const dispatch = useDispatch();

  const baseFileUrl = process.env.REACT_APP_FILE_URL;

  const [isCompleted, setIsCompleted] = useState(false);
  const { subQuestVideo } = subQuest;

  useEffect(() => {
    if (isCompleted) {
      dispatch(
        postProgressionAction({
          courseId: course.id,
          questId: quest.id,
          subQuestId: subQuest.id,
          code,
        })
      );
    }
  }, [isCompleted, course, quest, subQuest, code, dispatch]);

  useEffect(() => {
    if (course && quest && subQuest) {
      setIsCompleted(false);
      const { name, objective } = subQuest;
      let videoContext = subQuestVideo.transcript;
      if (
        subQuestVideo.transcript === null ||
        subQuestVideo.transcript === ''
      ) {
        videoContext = `Course: ${course.name} - ${course.description} | `;
        videoContext += `Quest: ${quest.name} - ${quest.description} | `;
        videoContext += `Video: ${name} - ${objective}`;
      }

      dispatch(companionActions.setContext({ videoContext }));
      dispatch(companionActions.setMode(CompanionMode.VIDEO_COMPANION));
      dispatch(
        companionActions.setGreetings(
          `I'm your AI Companion Bot, ready to assist you. I noticed that you're watching a video titled '${name}'. As your virtual trainer, feel free to ask me any questions you may have. I'm here to provide helpful information and guidance. So go ahead and ask away!`
        )
      );
    }
  }, [subQuest, course, quest, subQuestVideo, dispatch]);

  return (
    <Box borderRadius="md">
      <ReactPlayer
        width="100%"
        height="100%"
        controls
        playing={false}
        url={`${baseFileUrl}${subQuestVideo.video}`}
        onProgress={({ playedSeconds, loadedSeconds }) => {
          if (playedSeconds === loadedSeconds) setIsCompleted(true);
        }}
      />
    </Box>
  );
}

VideoSubQuest.propTypes = {
  course: PropTypes.object.isRequired,
  quest: PropTypes.object.isRequired,
  subQuest: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
};

export default VideoSubQuest;

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import {
  Text,
  Box,
  Textarea,
  Button,
  Input,
  Stack,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
} from '@chakra-ui/react';

import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  Underline,
} from 'ckeditor5';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import { IoMdClose } from 'react-icons/io';

/* eslint-disable import/no-unresolved */
import 'ckeditor5/ckeditor5.css';
/* eslint-enable import/no-unresolved */
function MultipleChoice({ question, onUpdate }) {
  const setQuestionAnswers = (ans) => {
    if (ans != null) {
      onUpdate({
        ...question,
        multipleChoiceAnswers: question.multipleChoiceChoices.filter((item) =>
          ans.includes(item.option)
        ),
      });
    }
  };

  const handleAnswerChange = (event) => {
    const { value, checked } = event.target;
    let ans = [...question.multipleChoiceAnswers.map((a) => a.option)];

    if (checked) {
      if (!ans.includes(value)) {
        ans.push(value);
      }
    } else {
      ans = ans.filter((item) => item !== value);
    }
    setQuestionAnswers(ans);
  };

  const sortChoices = (choices) => {
    return choices.sort((a, b) => a.option.localeCompare(b.option));
  };

  const reassignOptions = (choices) => {
    return choices.map((choice, index) => {
      const newOption = String.fromCharCode(65 + index); // 65 is the char code for 'A'
      return { option: newOption, value: newOption };
    });
  };

  const handleChoiceChange = (option, newValue) => {
    onUpdate({
      ...question,
      multipleChoiceChoices: question.multipleChoiceChoices.map((c) =>
        c.option === option ? { ...c, value: newValue } : c
      ),
      multipleChoiceAnswers: question.multipleChoiceAnswers.map((c) =>
        c.option === option ? { ...c, value: newValue } : c
      ),
    });
  };

  const handleAddChoice = () => {
    const currentOptions = question.multipleChoiceChoices.map(
      (choice) => choice.option
    );
    let nextOptionCharCode = 65; // ASCII code for 'A'

    while (currentOptions.includes(String.fromCharCode(nextOptionCharCode))) {
      nextOptionCharCode += 1;
    }

    const nextOption = String.fromCharCode(nextOptionCharCode);

    onUpdate({
      ...question,
      multipleChoiceChoices: [
        ...question.multipleChoiceChoices,
        { option: nextOption, value: nextOption },
      ].sort((a, b) => a.option.localeCompare(b.option)), // Sort options alphabetically
    });
  };

  const handleRemoveChoice = (optionToRemove) => {
    onUpdate({
      ...question,
      multipleChoiceChoices: reassignOptions(
        sortChoices(
          question.multipleChoiceChoices.filter(
            (choice) => choice.option !== optionToRemove
          )
        )
      ),
      multipleChoiceAnswers: reassignOptions(
        sortChoices(
          question.multipleChoiceAnswers.filter(
            (choice) => choice.option !== optionToRemove
          )
        )
      ),
    });
  };

  const handleExplanationChange = (newValue) => {
    onUpdate({
      ...question,
      explanation: newValue,
    });
  };

  const handleQuestionChange = (event, editor) => {
    const data = editor.getData();
    onUpdate({
      ...question,
      questionHtml: data,
    });
  };

  const handleMarkChange = (newValue) => {
    let mark = 0;
    if (newValue !== '' && !Number.isNaN(newValue)) {
      mark = parseFloat(newValue);
    }
    onUpdate({
      ...question,
      mark,
    });
  };

  return (
    <Box>
      <FormLabel>Question</FormLabel>
      <CKEditor
        editor={ClassicEditor}
        config={{
          plugins: [Essentials, Bold, Italic, Paragraph, Underline],
          toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline'],
        }}
        data={question.questionHtml}
        onChange={handleQuestionChange}
      />
      <Box w="10%" mt={4}>
        <FormControl>
          <FormLabel>Mark</FormLabel>
          <Input
            onChange={(e) => handleMarkChange(e.target.value)}
            value={question.mark}
          />
        </FormControl>
      </Box>
      <Box mt={4}>
        {question.multipleChoiceChoices.map((choice) => (
          <Stack
            key={uuidv4()}
            direction={['column', 'row']}
            spacing="24px"
            align="center"
            m={2}
          >
            <Box>
              <Text fontSize="lg">{choice.option}</Text>
            </Box>
            <Box w="50%">
              <Input
                value={choice.value}
                onChange={(e) =>
                  handleChoiceChange(choice.option, e.target.value)
                }
              />
            </Box>
            <Box>
              <Checkbox
                size="lg"
                value={choice.option}
                isChecked={question.multipleChoiceAnswers
                  .map((a) => a.option)
                  .includes(choice.option)}
                onChange={handleAnswerChange}
              />
            </Box>
            <Box>
              <IconButton
                isRound
                size="sm"
                icon={<IoMdClose />}
                onClick={() => handleRemoveChoice(choice.option)}
              />
            </Box>
          </Stack>
        ))}

        <Box mt={4}>
          <Button
            colorScheme="blue"
            size="sm"
            variant="outline"
            onClick={() => handleAddChoice()}
          >
            Add Choice
          </Button>
        </Box>
      </Box>
      <Box mt={4}>
        <Text fontSize="lg">Explanation</Text>
        <Textarea
          value={question.explanation}
          onChange={(e) => handleExplanationChange(e.target.value)}
        />
      </Box>
    </Box>
  );
}

MultipleChoice.propTypes = {
  question: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default MultipleChoice;

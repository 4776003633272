import { useState, useRef } from 'react';

const useVideoFileInput = () => {
  const [video, setVideo] = useState(null);
  const [videoURL, setVideoURL] = useState('');
  const [videoError, setVideoError] = useState('');
  const videoInputRef = useRef(null);

  const setErrorMessage = (error) => {
    setVideoError(error);

    setVideo(null);
  };

  const handleAttachVideo = () => {
    if (videoInputRef.current) {
      videoInputRef.current.click();
    }
  };

  const handleRemoveSelectedVideo = () => {
    setVideo(null);
  };

  const handleVideoInputChange = (event) => {
    const selectedVideo = event.target.files[0];

    if (selectedVideo) {
      const videoSizeInMB = selectedVideo.size / (1024 * 1024);
      const maxVideoSize = 500;

      if (videoSizeInMB > maxVideoSize) {
        setErrorMessage(
          'Video size exceeds 500 MB limit. Please select a smaller video.'
        );
      } else if (!selectedVideo.type.startsWith('video/mp4')) {
        setErrorMessage('Please select an video (mp4) video.');
      } else {
        const reader = new FileReader();

        reader.onload = (e) => {
          setVideo(selectedVideo);
          setVideoURL(e.target.result);
        };

        reader.readAsDataURL(selectedVideo);
        setVideoError('');
        event.target.value = null;
      }
    }
  };

  return {
    video,
    videoURL,
    videoError,
    videoInputRef,
    setVideoError,
    handleAttachVideo,
    handleRemoveSelectedVideo,
    handleVideoInputChange,
  };
};

export default useVideoFileInput;

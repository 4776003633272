import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchCourseQuestsAction = createAsyncThunk(
  'quests/fetchQuests',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/courses/${id}/quests`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const fetchQuestByIdAction = createAsyncThunk(
  'quests/fetchQuestById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/quests/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const createAddCourseQuestAction = createAsyncThunk(
  'quests/createAddQuest',
  async ({ name, description, ownerGroups, courseId }, { rejectWithValue }) => {
    try {
      const createCourseResponse = await axios.post(
        '/admin/quests',
        {
          name,
          description,
          ownerGroups,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const quest = createCourseResponse.data;

      const addCourseQuestResponse = await axios.post(
        `/admin/courses/${courseId}/quests`,
        quest.id,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return addCourseQuestResponse.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateQuestByIdAction = createAsyncThunk(
  'quests/updateQuest',
  async (
    { id, name, description, ownerGroups, isPublished },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.put(
        `/admin/quests/${id}`,
        {
          name,
          description,
          ownerGroups,
          isPublished,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const deleteQuestByIdAction = createAsyncThunk(
  'quests/deleteQuestById',
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/admin/quests/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const removeCourseQuestByIdAction = createAsyncThunk(
  'quests/removeCourseQuestById',
  async ({ courseId, questId }, { rejectWithValue }) => {
    try {
      await axios.delete(`/admin/courses/${courseId}/quests/${questId}`);
      return questId;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateCourseQuestOrderNoAction = createAsyncThunk(
  'quests/removeCourseQuestOrderNo',
  async ({ courseId, questId, newOrderNo }, { rejectWithValue }) => {
    try {
      await axios.put(
        `/admin/courses/${courseId}/quests/${questId}`,
        newOrderNo,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return true;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

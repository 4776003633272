import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    contactModalIsVisible: false,
  },
  reducers: {
    toggleContactModal(state) {
      state.contactModalIsVisible = !state.contactModalIsVisible;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;

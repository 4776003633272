import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Center,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { NavLink, useLocation } from 'react-router-dom';

function NavItem({ icon, to, base, children, ...rest }) {
  const { pathname } = useLocation();

  const isActive = pathname.includes(base);

  return (
    <NavLink to={to}>
      <Box style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          direction="column"
          p="4"
          mb="1"
          bg={isActive ? '#ad3648' : ''}
          cursor="pointer"
          _hover={{
            bg: '#ad3648',
          }}
          {...rest}
        >
          {icon && (
            <Icon
              fontSize="2xl"
              color="white"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          <Center>
            <Text
              mt={2}
              fontSize="8px"
              noOfLines={2}
              color={useColorModeValue('white', 'white')}
            >
              {children}
            </Text>
          </Center>
        </Flex>
      </Box>
    </NavLink>
  );
}

NavItem.propTypes = {
  icon: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  base: PropTypes.string.isRequired,
};

export default NavItem;

import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import useLearningPlanOrderChange from '../hooks/useLearningPlanOrderChange';
import LearningPlanSelect from './LearningPlanSelect';
import SelectedLearningPlansList from './SelectedLearningPlanList';

function LearningPlanInput({
  field,
  form,
  learningPlans,
  selectedLearningPlans,
  handleInputChange,
  handleLearningPlanIdsAdd,
  handleUpdateLearningPlanOrder,
  onEdit,
  onDelete,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  const { handleOnDragEnd } = useLearningPlanOrderChange(
    selectedLearningPlans,
    handleUpdateLearningPlanOrder
  );

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
  };

  const handleAddOption = () => {
    if (selectedOption) {
      const selectedLearningPlansToAdd = {
        id: selectedOption.value,
        name: selectedOption.label,
      };
      handleLearningPlanIdsAdd(selectedLearningPlansToAdd);
      setSelectedOption(null);
    }
  };

  return (
    <>
      <FormControl isInvalid={form.errors.learningPlanId}>
        <FormLabel>Learning Plan</FormLabel>
        <HStack>
          <Box flex={1}>
            <LearningPlanSelect
              field={field}
              selectedOption={selectedOption}
              learningPlans={learningPlans}
              selectedLearningPlans={selectedLearningPlans}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
            />
            {form.errors.learningPlanId ? (
              <FormErrorMessage>{form.errors.learningPlanId}</FormErrorMessage>
            ) : (
              <FormHelperText>
                Choose learning plans to attach to the growth stage.
              </FormHelperText>
            )}
          </Box>
          <Flex alignSelf="flex-start">
            <IconButton
              aria-label="Add learning plan"
              icon={<AddIcon />}
              fontWeight="extrabold"
              colorScheme="green"
              variant="solid"
              onClick={handleAddOption}
              width="70px"
            />
          </Flex>
        </HStack>
      </FormControl>
      <SelectedLearningPlansList
        selectedLearningPlans={selectedLearningPlans}
        handleOnDragEnd={handleOnDragEnd}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </>
  );
}

LearningPlanInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  learningPlans: PropTypes.array.isRequired,
  selectedLearningPlans: PropTypes.array.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleLearningPlanIdsAdd: PropTypes.func.isRequired,
  handleUpdateLearningPlanOrder: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default LearningPlanInput;

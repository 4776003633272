import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';

function ObjectiveInput({ field, form }) {
  return (
    <FormControl
      isInvalid={form.errors.description && form.touched.description}
    >
      <FormLabel>Objective</FormLabel>
      <Textarea {...field} />

      {form.errors.description ? (
        <FormErrorMessage>{form.errors.description}</FormErrorMessage>
      ) : (
        <FormHelperText>
          Provide a detailed objective of the sub-quest. Mention the key
          objectives and what learners can expect to gain.
        </FormHelperText>
      )}
    </FormControl>
  );
}

ObjectiveInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default ObjectiveInput;

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';

import { Link as ReactRouterLink } from 'react-router-dom';

function Breadcrumbs({ links, ...rest }) {
  return (
    <Breadcrumb fontWeight="medium" fontSize="xs" {...rest}>
      {links?.map((link, index) => (
        <BreadcrumbItem
          key={uuidv4()}
          isCurrentPage={links.length === index + 1}
        >
          <BreadcrumbLink as={ReactRouterLink} to={link.path}>
            <Text noOfLines={1}>{link.name}</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(Object).isRequired,
};

export default Breadcrumbs;

import { useNavigate } from 'react-router-dom';
import { useState, useMemo } from 'react';
import {
  useColorModeValue,
  Divider,
  Input,
  Image,
  Box,
  Text,
  HStack,
  Progress,
  Link as ChakraLink,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import axios from 'axios';
import OutsideClickHandler from '../OutsideClickHandler ';

function HeaderSearch() {
  const navigate = useNavigate();

  const [isFocus, setIsFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const [courses, setCourses] = useState([]);

  const searchCourses = (searchValue) => {
    axios
      .get(`/courses?currentPage=1&pageSize=5&query=${searchValue}`)
      .then((response) => {
        const { data } = response.data;
        setCourses(data);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const debounceFn = useMemo(() => debounce(searchCourses, 2000), []);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.trim()) {
      setIsLoading(true);
      debounceFn(search);
    }
  };

  const handleFocusChange = (focusState) => {
    setIsFocus(focusState);
  };

  const handleCourseLink = (id) => {
    setIsFocus(false);
    navigate(`/courses/${id}`);
  };

  const resultBg = useColorModeValue('white', 'gray.800');
  const baseFileUrl = process.env.REACT_APP_FILE_URL;

  return (
    <Box w="100%">
      <OutsideClickHandler onOutsideClick={() => handleFocusChange(false)}>
        <Box pos="relative">
          <Input
            onFocus={() => handleFocusChange(true)}
            value={search}
            onChange={handleSearchChange}
            borderColor={useColorModeValue('red.800', 'white')}
            focusBorderColor={useColorModeValue('red.900', 'white')}
            borderRadius="25"
            placeholder="Search"
            size="sm"
          />
          {isFocus && (
            <Box
              pos="absolute"
              borderRadius="md"
              boxShadow="dark-lg"
              w="100%"
              bg={resultBg}
              top="130%"
              p={4}
              zIndex={2}
            >
              <Box>
                {courses.length > 0 ? (
                  <Box>
                    {courses.map((course) => (
                      <Box key={course.id}>
                        <HStack mb={4} gap={3}>
                          <Image
                            borderRadius="md"
                            boxSize="50px"
                            objectFit="cover"
                            fallbackSrc={require('assets/default-course.jpg')}
                            src={`${baseFileUrl}${course.thumbnail}`}
                            alt={course.name}
                          />
                          <Box>
                            <Text
                              fontSize="md"
                              fontWeight="medium"
                              colorScheme="gray"
                            >
                              <ChakraLink
                                onClick={() => handleCourseLink(course.id)}
                                color="blue.500"
                              >
                                {course.name}
                              </ChakraLink>
                            </Text>
                            <Text fontSize="sm">
                              {course.description.length > 50
                                ? `${course.description.substring(0, 50)}...`
                                : course.description}
                            </Text>
                          </Box>
                        </HStack>

                        <Divider mb={4} />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box>{!isLoading && <Text>No results found</Text>}</Box>
                )}

                {isLoading && <Progress mb={4} size="xs" isIndeterminate />}
              </Box>
            </Box>
          )}
        </Box>
      </OutsideClickHandler>
    </Box>
  );
}

export default HeaderSearch;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchCoursesAction = createAsyncThunk(
  'courses/fetchCoursesAction',
  async (params) => {
    const { query, currentPage, pageSize } = params;
    let apiRoute = `/admin/courses?CurrentPage=${currentPage}&PageSize=${pageSize}`;
    if (query) apiRoute += `&Query=${query}`;

    try {
      const response = await axios.get(apiRoute);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const fetchCourseByIdAction = createAsyncThunk(
  'courses/fetchCourseByIdAction',
  async (id) => {
    const response = await axios.get(`/admin/courses/${id}`);
    return response.data;
  }
);

export const deleteCourseByIdAction = createAsyncThunk(
  'courses/deleteCourseByIdAction',
  async (id) => {
    const response = await axios.delete(`/admin/courses/${id}`);
    return response.data;
  }
);

export const fetchCourseCategoriesAction = createAsyncThunk(
  'courses/fetchCategoriesAction',
  async () => {
    const response = await axios.get(`/admin/courses/categories`);
    return response.data;
  }
);

export const createCourseAction = createAsyncThunk(
  'courses/createCourse',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/admin/courses', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

export const updateCourseAction = createAsyncThunk(
  'courses/updateCourseAction',
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/admin/courses/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }
);

import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';

function TranscriptInput({ field, form }) {
  return (
    <FormControl
      isInvalid={form.errors.description && form.touched.description}
    >
      <FormLabel>Transcript</FormLabel>
      <Textarea {...field} />

      {form.errors.description ? (
        <FormErrorMessage>{form.errors.description}</FormErrorMessage>
      ) : (
        <FormHelperText>
          Please provide the transcript of the video. The transcript will be
          utilized to train the AI Companion, enabling it to respond to trainee
          questions that are relevant to the content covered in the video.
        </FormHelperText>
      )}
    </FormControl>
  );
}

TranscriptInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default TranscriptInput;

import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Box,
  Flex,
  Kbd,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';

import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types';

function NotificationListsInput({
  newNotificationList,
  notificationList,
  isNotificationListError,
  errorMessage,
  onNotificationListChange,
  onNotificationListAdd,
  onNotificationListKeyDown,
  onNotificationListRemove,
}) {
  return (
    <FormControl isInvalid={isNotificationListError}>
      <FormLabel optionalIndicator>Owner NotificationLists</FormLabel>
      <Flex gap={2}>
        <Input
          type="text"
          value={newNotificationList}
          onChange={(e) =>
            onNotificationListChange('newNotificationList', e.target.value)
          }
          onKeyDown={onNotificationListKeyDown}
        />
        <Button
          colorScheme="teal"
          variant="outline"
          size="md"
          onClick={onNotificationListAdd}
        >
          <Kbd>enter</Kbd>
        </Button>
      </Flex>
      <Box my={2}>
        {notificationList.map((group) => (
          <Tag
            key={uuidv4()}
            size="md"
            mr={2}
            mb={1}
            borderRadius="full"
            variant="solid"
            colorScheme="teal"
          >
            <TagLabel>{group}</TagLabel>
            <TagCloseButton onClick={() => onNotificationListRemove(group)} />
          </Tag>
        ))}
      </Box>
      {isNotificationListError ? (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      ) : (
        <FormHelperText>
          Specify the distribution lists or notificationList that have the
          authority to modify this video sub-quest. Press Enter after each entry
          to add it to the list. This ensures proper access control.
        </FormHelperText>
      )}
    </FormControl>
  );
}

// Define PropTypes
NotificationListsInput.propTypes = {
  newNotificationList: PropTypes.string.isRequired,
  notificationList: PropTypes.array.isRequired,
  isNotificationListError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onNotificationListChange: PropTypes.func.isRequired,
  onNotificationListAdd: PropTypes.func.isRequired,
  onNotificationListKeyDown: PropTypes.func.isRequired,
  onNotificationListRemove: PropTypes.func.isRequired,
};

export default NotificationListsInput;

import PropTypes from 'prop-types';
import { AsyncSelect } from 'chakra-react-select';

function LearningPlanSelect({
  field,
  selectedOption,
  learningPlans,
  selectedLearningPlans,
  handleInputChange,
  handleSelectChange,
}) {
  const selectOptions = learningPlans?.map((plan) => ({
    label: plan.name,
    value: plan.id,
  }));

  const filteredOptions = selectOptions.filter(
    (option) =>
      !selectedLearningPlans.some((selected) => selected.id === option.value)
  );

  const filterLearningPlans = (inputValue) => {
    const filteredPlans = learningPlans.filter(
      (plan) =>
        !selectedLearningPlans.some((selected) => selected.id === plan.id)
    );

    if (inputValue) {
      return filteredPlans
        .filter((plan) =>
          plan.name.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((plan) => ({
          label: plan.name,
          value: plan.id,
        }));
    }

    return filteredPlans.map((plan) => ({
      label: plan.name,
      value: plan.id,
    }));
  };

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterLearningPlans(inputValue));
      }, 1000);
    });
  };

  return (
    <AsyncSelect
      {...field}
      value={selectedOption}
      placeholder="Select a learning plan"
      loadOptions={promiseOptions}
      defaultOptions={filteredOptions}
      onInputChange={handleInputChange}
      onChange={handleSelectChange}
      isClearable
    />
  );
}

LearningPlanSelect.propTypes = {
  field: PropTypes.object.isRequired,
  selectedOption: PropTypes.object.isRequired,
  learningPlans: PropTypes.array.isRequired,
  selectedLearningPlans: PropTypes.array.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
};

export default LearningPlanSelect;

import PropTypes from 'prop-types';

import {
  Text,
  Box,
  Textarea,
  Input,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';

import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  Underline,
} from 'ckeditor5';
import { CKEditor } from '@ckeditor/ckeditor5-react';

/* eslint-disable import/no-unresolved */
import 'ckeditor5/ckeditor5.css';
/* eslint-enable import/no-unresolved */

function TrueOrFalse({ question, onUpdate }) {
  const handleAnswerChange = (newValue) => {
    onUpdate({
      ...question,
      trueOrFalseAnswer: newValue === 'true',
    });
  };

  const handleExplanationChange = (newValue) => {
    onUpdate({
      ...question,
      explanation: newValue,
    });
  };

  const handleQuestionChange = (event, editor) => {
    const data = editor.getData();
    onUpdate({
      ...question,
      questionHtml: data,
    });
  };

  const handleMarkChange = (newValue) => {
    let mark = 0;
    if (newValue !== '' && !Number.isNaN(newValue)) {
      mark = parseFloat(newValue);
    }
    onUpdate({
      ...question,
      mark,
    });
  };

  return (
    <Box>
      <FormLabel>Question</FormLabel>
      <CKEditor
        editor={ClassicEditor}
        config={{
          plugins: [Essentials, Bold, Italic, Paragraph, Underline],
          toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline'],
        }}
        data={question.questionHtml}
        onChange={handleQuestionChange}
      />
      <Box>
        <RadioGroup
          value={question.trueOrFalseAnswer ? 'true' : 'false'}
          onChange={handleAnswerChange}
          mt={5}
          mb={5}
        >
          <Stack>
            <Radio value="true">True</Radio>
            <Radio value="false">False</Radio>
          </Stack>
        </RadioGroup>
      </Box>
      <Box w="10%" mt={4}>
        <FormControl>
          <FormLabel>Mark</FormLabel>
          <Input
            onChange={(e) => handleMarkChange(e.target.value)}
            value={question.mark}
          />
        </FormControl>
      </Box>
      <Box mt={4}>
        <Text fontSize="lg">Explanation</Text>
        <Textarea
          value={question.explanation}
          onChange={(e) => handleExplanationChange(e.target.value)}
        />
      </Box>
    </Box>
  );
}

TrueOrFalse.propTypes = {
  question: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default TrueOrFalse;

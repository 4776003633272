import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useParams,
  useNavigate,
  Link as ReactRouterLink,
} from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';

import {
  Box,
  Flex,
  Skeleton,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  LinkBox,
} from '@chakra-ui/react';

import Breadcrumbs from 'components/common/Breadcrumbs';
import CourseHeader from 'components/course/CourseHeader';
import QuestItem from 'components/quest/QuestItem';

import { fetchProgressionByCourseIdAction } from 'store/modules/progression/progression-actions';
import { MdSettings } from 'react-icons/md';
import { ViewIcon } from '@chakra-ui/icons';

function CoursePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accounts } = useMsal();

  const [isCourseLoading, setIsCourseLoading] = useState(false);
  const [isQuestLoading, setIsQuestLoading] = useState(true);

  const [course, setCourse] = useState(null);
  const [quests, setQuests] = useState([]);

  const { idTokenClaims } = accounts[0];
  const { roles } = idTokenClaims;

  useEffect(() => {
    const fetchCourseById = async () => {
      setIsCourseLoading(true);
      try {
        const response = await axios.get(`/courses/${id}`);
        setCourse(response.data);
      } catch {
        navigate('/404');
      }
      setIsCourseLoading(false);
    };
    fetchCourseById();
  }, [id, navigate]);

  useEffect(() => {
    if (course) {
      const fetchCourseQuests = async () => {
        setIsQuestLoading(true);
        try {
          const response = await axios.get(`/courses/${course.id}/quests`);
          setQuests(response.data);
        } catch {
          setQuests([]);
        }
        setIsQuestLoading(false);
      };

      dispatch(fetchProgressionByCourseIdAction(course.id));

      fetchCourseQuests();
    }
  }, [course, dispatch]);

  return (
    <Flex minH="100vh" ml={{ base: 0, md: 20 }}>
      <Box flex="1" ml={2} p="5">
        <Flex direction={{ base: 'column', lg: 'row' }}>
          <Box flex="1">
            <Breadcrumbs
              mb={4}
              links={[
                { name: 'Home', path: '/' },
                { name: course?.name, path: `/courses/${id}` },
              ]}
            />
            <Flex direction={{ base: 'column', sm: 'row' }}>
              <CourseHeader
                isLoading={isCourseLoading}
                course={course}
                flex="1"
              />
              {roles?.find((role) => role === 'Trainer') && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    size={{ base: 'sm', md: 'sm' }}
                    colorScheme="teal"
                    aria-label="Options"
                    icon={<MdSettings />}
                    variant="outline"
                  />
                  <MenuList>
                    <LinkBox cursor="pointer">
                      <MenuItem
                        as={ReactRouterLink}
                        to={`/admin/courses/${id}`}
                        icon={<ViewIcon />}
                      >
                        Admin&lsquo;s View
                      </MenuItem>
                    </LinkBox>
                  </MenuList>
                </Menu>
              )}
            </Flex>

            <Box mt={7}>
              {isQuestLoading ? (
                <Stack>
                  <Skeleton mb={6} height="72px" borderRadius="md" />
                  <Skeleton mb={6} height="72px" borderRadius="md" />
                  <Skeleton mb={6} height="72px" borderRadius="md" />
                  <Skeleton mb={6} height="72px" borderRadius="md" />
                </Stack>
              ) : (
                <Box>
                  {quests.map((quest) => (
                    <QuestItem
                      key={quest.id}
                      courseId={parseInt(id, 10)}
                      quest={quest}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>

          {/* <Activities /> */}
        </Flex>
      </Box>
    </Flex>
  );
}

export default CoursePage;

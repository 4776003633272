import { Image } from '@chakra-ui/react';

function HeaderLogo() {
  return (
    <Image
      w="250px"
      src={require('assets/skillquest-logo-beta.png')}
      objectFit="cover"
      alt="Skill Quest Logo"
    />
  );
}

export default HeaderLogo;

import PropTypes from 'prop-types';
import { Text, Flex, Select } from '@chakra-ui/react';

function RowsPerPage({ pageSize, onChange }) {
  return (
    <Flex justify="end" align="center">
      <Text mx={2} fontSize="xs">
        Rows per page:
      </Text>
      <Select
        size="sm"
        mx={2}
        width="70px"
        value={pageSize}
        onChange={onChange}
      >
        <option>5</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
      </Select>
    </Flex>
  );
}

RowsPerPage.propTypes = {
  pageSize: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RowsPerPage;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';

import {
  Box,
  Flex,
  VStack,
  Divider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Progress,
} from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';

import ResultModal from 'components/common/modals/ResultModal';
import { adminSubQuestActions } from '../../store/admin-subquest-slice';
import { updateAdminVideoSubQuest } from '../../store/admin-subquest-action';

import NameInput from './components/NameInput';
import CodeInput from './components/CodeInput';
import ObjectiveInput from './components/ObjectiveInput';
import VideoFileInput from './components/VideoFileInput';
import VideoNameInput from './components/VideoNameInput';
import DescriptionInput from './components/DescriptionInput';

import useVideoFileInput from './hooks/useVideoFileInput';
import useHandleForm from './hooks/useHandleForm';
import TagsInput from './components/TagsInput';
import GroupsInput from './components/GroupsInput';
import PublishInput from './components/PublishInput';
import TranscriptInput from './components/TranscriptInput';

function UpdateAdminVideoSubQuestById() {
  const dispatch = useDispatch();

  const {
    video,
    videoURL,
    videoError,
    videoInputRef,
    handleAttachVideo,
    handleRemoveSelectedVideo,
    handleVideoInputChange,
  } = useVideoFileInput();

  const {
    tags,
    groups,
    inputTagsGroups,
    courseFormValidation,
    setTags,
    setGroups,
    handleTagsGroupsChange,
    handleTagRemove,
    handleGroupRemove,
    handleGroupAdd,
    handleTagAdd,
    handleTagKeyDown,
    handleGroupKeyDown,
  } = useHandleForm();

  const [updateFormInputs, setUpdateFormInputs] = useState({
    name: '',
    videoName: '',
    description: '',
    transcript: '',
    objective: '',
    code: '',
  });

  const [isPublished, setIsPublished] = useState('false');

  const updateVideo = useSelector((state) => state.adminSubQuest.updateVideo);
  const uploadProgress = useSelector(
    (state) => state.adminSubQuest.uploadProgress
  );
  const questId = useSelector((state) => state.adminSubQuest.questId);
  const subQuest = useSelector((state) => state.adminSubQuest.subQuest);

  useEffect(() => {
    const resetForm = () => {
      setUpdateFormInputs({
        name: '',
        videoName: '',
        description: '',
        transcript: '',
        objective: '',
        code: '',
      });
      setGroups([]);
      setTags([]);
    };

    if (updateVideo.modal) {
      resetForm();

      setUpdateFormInputs({
        name: subQuest?.name,
        videoName: subQuest?.subQuestVideo?.name,
        description: subQuest?.subQuestVideo?.description,
        transcript: subQuest?.subQuestVideo?.transcript,
        objective: subQuest?.objective,
        code: subQuest?.code,
      });

      setIsPublished(subQuest?.isPublished.toString());

      const parsedGroups = JSON.parse(subQuest?.ownerGroups);
      if (
        Array.isArray(parsedGroups) &&
        parsedGroups.every((group) => typeof group === 'string')
      ) {
        setGroups(parsedGroups);
      }

      const parsedTags = JSON.parse(subQuest?.tags);
      if (
        Array.isArray(parsedTags) &&
        parsedTags.every((group) => typeof group === 'string')
      ) {
        setTags(parsedTags);
      }
    }
  }, [updateVideo.modal, subQuest, setGroups, setTags, dispatch]);

  const handlePublishStatusChange = (e) => {
    setIsPublished(e);
  };

  const handleToggleUpdateModal = (isOpen) => {
    dispatch(adminSubQuestActions.toggleVideoUpdateModal(isOpen));
  };

  const resetForm = () => {
    setUpdateFormInputs({
      name: '',
      videoName: '',
      description: '',
      transcript: '',
      objective: '',
      code: '',
    });

    handleRemoveSelectedVideo();

    setTags([]);
    setGroups([]);
  };

  const handleUpdateCourse = (formikObject) => {
    const formData = new FormData();
    Object.entries(formikObject).forEach(([key, value]) => {
      setUpdateFormInputs((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
      formData.append(key, value);
    });

    if (tags.length > 0) tags.forEach((tag) => formData.append('Tags', tag));
    if (groups.length > 0)
      groups.forEach((group) => formData.append('OwnerGroups', group));

    formData.append('IsPublished', isPublished);

    if (video && video.name) {
      formData.append('Video', video, video.name);
    }

    if (video?.name) formData.append('IsRemoveVideo', 'true');

    dispatch(
      updateAdminVideoSubQuest({
        subQuestId: subQuest?.id,
        questId,
        formData,
      })
    );
  };

  const handleResultButton = () => {
    dispatch(adminSubQuestActions.resetVideoUpdateStatus());
    if (updateVideo.status === ReducerStatus.SUCCEEDED) {
      resetForm();
      handleToggleUpdateModal(false);
    }
  };

  return (
    <Box>
      {updateVideo.status === ReducerStatus.SUCCEEDED ||
      updateVideo.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={updateVideo.modal}
          onSubmit={handleResultButton}
          isError={updateVideo.status === ReducerStatus.FAILED}
          title={
            updateVideo.status === ReducerStatus.SUCCEEDED
              ? 'Sub-Quest Updated'
              : null
          }
          message={
            updateVideo.status === ReducerStatus.SUCCEEDED
              ? 'The sub-quest has been updated successfully.'
              : updateVideo.error?.data?.title || 'Server Error'
          }
          errorObject={updateVideo.error?.data?.errors}
        />
      ) : (
        <Modal
          size="2xl"
          isOpen={updateVideo.modal}
          onClose={() => handleToggleUpdateModal(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Video Sub-Quest</ModalHeader>
            <ModalCloseButton />
            <Formik
              enableReinitialize
              initialValues={updateFormInputs}
              onSubmit={(values) => {
                handleUpdateCourse(values);
              }}
              validationSchema={courseFormValidation}
            >
              {() => (
                <Form>
                  <ModalBody my={3}>
                    <VStack spacing={6} align="stretch">
                      <Flex
                        gap={5}
                        direction={{
                          base: 'column',
                          md: 'row',
                        }}
                      >
                        <Field flex={1} name="name">
                          {({ field, form }) => (
                            <NameInput field={field} form={form} />
                          )}
                        </Field>

                        <Box
                          w={{
                            base: '100%',
                            md: '380px',
                          }}
                        >
                          <Field name="code">
                            {({ field, form }) => (
                              <CodeInput field={field} form={form} />
                            )}
                          </Field>
                        </Box>
                      </Flex>

                      <Field name="objective">
                        {({ field, form }) => (
                          <ObjectiveInput field={field} form={form} />
                        )}
                      </Field>

                      <Divider />

                      <VideoFileInput
                        currentVideo={subQuest?.subQuestVideo?.video}
                        video={video}
                        videoURL={videoURL}
                        videoError={videoError}
                        videoInputRef={videoInputRef}
                        handleRemoveSelectedVideo={handleRemoveSelectedVideo}
                        handleAttachVideo={handleAttachVideo}
                        handleVideoInputChange={handleVideoInputChange}
                      />

                      <Field name="videoName">
                        {({ field, form }) => (
                          <VideoNameInput field={field} form={form} />
                        )}
                      </Field>

                      <Field name="description">
                        {({ field, form }) => (
                          <DescriptionInput field={field} form={form} />
                        )}
                      </Field>

                      <Field name="transcript">
                        {({ field, form }) => (
                          <TranscriptInput field={field} form={form} />
                        )}
                      </Field>

                      <Divider />

                      <TagsInput
                        newTag={inputTagsGroups.newTag}
                        tags={tags}
                        isTagError={inputTagsGroups.isTagError}
                        errorMessage={inputTagsGroups.errorMessage}
                        onTagChange={handleTagsGroupsChange}
                        onTagAdd={handleTagAdd}
                        onTagKeyDown={handleTagKeyDown}
                        onTagRemove={handleTagRemove}
                      />

                      <GroupsInput
                        newGroup={inputTagsGroups.newGroup}
                        groups={groups}
                        isGroupError={inputTagsGroups.isGroupError}
                        errorMessage={inputTagsGroups.errorMessage}
                        onGroupChange={handleTagsGroupsChange}
                        onGroupAdd={handleGroupAdd}
                        onGroupKeyDown={handleGroupKeyDown}
                        onGroupRemove={handleGroupRemove}
                      />

                      <PublishInput
                        isPublished={isPublished}
                        handleChange={handlePublishStatusChange}
                      />

                      {updateVideo.status === ReducerStatus.LOADING && (
                        <Progress hasStripe isAnimated value={uploadProgress} />
                      )}
                    </VStack>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      variant="outline"
                      colorScheme="blue"
                      mr={3}
                      onClick={() => handleToggleUpdateModal(false)}
                    >
                      Close
                    </Button>
                    <Button
                      isLoading={updateVideo.status === ReducerStatus.LOADING}
                      colorScheme="red"
                      px={10}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default UpdateAdminVideoSubQuestById;

import PropTypes from 'prop-types';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { useColorModeValue, Box, IconButton, Flex } from '@chakra-ui/react';
import { DragHandleIcon } from '@chakra-ui/icons';

function QuestDraftItem({
  id,
  titleInputComp,
  descriptionInputComp,
  deleteBtnComp,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Box mb={6} borderWidth="1px" borderRadius="md">
        <Box w="100%" p={4} bg={useColorModeValue('#f7f7f7', 'gray.800')}>
          <Flex align="center">
            <IconButton
              {...attributes}
              {...listeners}
              variant="plain"
              color="teal"
              aria-label="Re-order quest"
              icon={<DragHandleIcon />}
            />

            {titleInputComp}

            {deleteBtnComp}
          </Flex>
        </Box>

        <Box p={4}>{descriptionInputComp}</Box>
      </Box>
    </div>
  );
}

QuestDraftItem.propTypes = {
  id: PropTypes.number.isRequired,
  titleInputComp: PropTypes.element.isRequired,
  descriptionInputComp: PropTypes.element.isRequired,
  deleteBtnComp: PropTypes.element.isRequired,
};

export default QuestDraftItem;

export const arrayOfString = (jsonString) => {
  try {
    const parsedArrayOfString = JSON.parse(jsonString);
    if (
      Array.isArray(parsedArrayOfString) &&
      parsedArrayOfString.every((string) => typeof string === 'string')
    ) {
      return parsedArrayOfString;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export default arrayOfString;

import {
  Text,
  Avatar,
  HStack,
  VStack,
  Menu,
  MenuButton,
  useColorModeValue,
} from '@chakra-ui/react';

import { useMsal } from '@azure/msal-react';

function HeaderAccount() {
  const { accounts } = useMsal();
  const { idTokenClaims, name } = accounts[0];
  const { roles } = idTokenClaims;

  return (
    <Menu>
      <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
        <HStack>
          <Avatar
            size="sm"
            name={name.replace(/\s*\([^)]*\)\s*$/, '').trim()}
            colorScheme="teal"
          />
          <VStack
            display={{ base: 'none', lg: 'flex' }}
            alignItems="flex-start"
            spacing="1px"
            ml="2"
          >
            <Text fontSize="xs">{name}</Text>
            <Text
              fontSize="xs"
              color={useColorModeValue('gray.600', '#d92e48')}
            >
              {roles != null && roles.length > 0
                ? `${roles[roles.length - 1]}`
                : 's'}
            </Text>
          </VStack>
          {/* <Box display={{ base: "none", md: "flex" }}>
        <FiChevronDown />
      </Box> */}
        </HStack>
      </MenuButton>
      {/* <MenuList
        bg={useColorModeValue("white", "gray.900")}
        borderColor={useColorModeValue("gray.200", "gray.700")}
      >
        <MenuItem>Profile</MenuItem>
        <MenuItem onClick={toggleContactMdHandler}>Contact Us</MenuItem>
      </MenuList> */}
    </Menu>
  );
}

export default HeaderAccount;

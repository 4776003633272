import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import {
  Box,
  Center,
  Flex,
  Text,
  Tag,
  TagLeftIcon,
  TagLabel,
  VStack,
  SkeletonText,
  Skeleton,
  Spacer,
} from '@chakra-ui/react';

import { FaCheckCircle, FaPen } from 'react-icons/fa';
import { CheckCircleIcon } from '@chakra-ui/icons';

import { getProgressionPercentage } from 'store/modules/progression/progression-slice';

import { ReducerStatus } from 'utils/constants';

import CourseImage from './CourseImage';
import ProgressDisplay from './ProgressDisplay';

function CourseHeader({ course, isLoading, isAdminMode, ...rest }) {
  const getProgressionStatus = useSelector(
    (state) => state.progression.getProgressionStatus
  );

  const subQuestCount = useSelector(
    (state) => state.progression.totalSubQuestCount
  );

  const progressionPercentage = useSelector(getProgressionPercentage);

  return (
    <Flex direction={{ base: 'column', sm: 'row' }} {...rest}>
      <Skeleton
        h={{ base: '100%', sm: '200px' }}
        w={{ base: '100%', sm: '300px' }}
        isLoaded={!isLoading}
      >
        <Box
          h={{ base: '100%', sm: '200px' }}
          w={{ base: '100%', sm: '300px' }}
        >
          <CourseImage
            borderRadius="10"
            name={course?.name}
            image={course?.thumbnail}
            category={course?.category.name}
          />
        </Box>
      </Skeleton>

      <Box px={{ base: 0, sm: 5 }} mt={{ base: 5, sm: 0 }} flex="1">
        <VStack spacing={1} align="start">
          <SkeletonText noOfLines={2} width="100%" isLoaded={!isLoading}>
            <Flex>
              <Text flex={1} fontWeight={500} fontSize="xl">
                {course?.name}

                {progressionPercentage === 100 && (
                  <CheckCircleIcon ml={2} color="green" boxSize={4} />
                )}
              </Text>
            </Flex>

            <Text mt={1} fontSize="xs" fontWeight={550}>
              {course?.owner?.name}
            </Text>
          </SkeletonText>

          <SkeletonText noOfLines={4} isLoaded={!isLoading}>
            <Text fontSize="lg">{course?.description}</Text>
          </SkeletonText>

          <Box>
            <SkeletonText noOfLines={2} isLoaded={!isLoading}>
              <Box>
                {course?.tags &&
                  Array.isArray(JSON.parse(course?.tags)) &&
                  JSON.parse(course.tags).map((tag) => (
                    <Tag key={uuidv4()} m={2} color="teal" size="sm">
                      <Center>{tag.toUpperCase()}</Center>
                    </Tag>
                  ))}
              </Box>

              {isAdminMode && (
                <Tag
                  size="md"
                  mt={2}
                  colorScheme={course?.isPublished ? 'green' : 'purple'}
                  variant="solid"
                >
                  <TagLeftIcon
                    as={course?.isPublished ? FaCheckCircle : FaPen}
                  />
                  <TagLabel>
                    {course?.isPublished ? 'Published' : 'Draft'}
                  </TagLabel>
                </Tag>
              )}
            </SkeletonText>
          </Box>
          <Spacer />
          <Skeleton height="10px" width="100%" isLoaded={!isLoading}>
            {!isAdminMode && (
              <ProgressDisplay
                percentage={progressionPercentage}
                subQuestCount={subQuestCount}
                isLoading={getProgressionStatus === ReducerStatus.LOADING}
              />
            )}
          </Skeleton>
        </VStack>
      </Box>
    </Flex>
  );
}

CourseHeader.defaultProps = {
  course: null,
  isAdminMode: false,
};

CourseHeader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  course: PropTypes.object,
  isAdminMode: PropTypes.bool,
};

export default CourseHeader;
